import React, { useEffect, useRef, useState } from 'react'

const OtpInput = ({ length = 4, getFullOtp, onKeyDown, resetOtp, setResetOtp }) => {
  const [otp, setOtp] = useState(new Array(length).fill(''))
  const inputRefs = useRef([])

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus()
    }
  }, [])

  const handleChange = (index, e) => {
    const value = e.target.value

    if (isNaN(value)) return

    const newOtp = [...otp]
    newOtp[index] = value.substring(value.length - 1)

    setOtp(newOtp)

    const combinedOtp = newOtp.join('')
    if (combinedOtp.length === length) getFullOtp(combinedOtp)

    if (value && index < length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus()
    }
  }

  // reset OTP inputs when reset state changes
  useEffect(() => {
    if (resetOtp) {
      setOtp(new Array(length).fill(''))
      if (inputRefs.current[0]) {
        inputRefs.current[0].focus()
      }

      setResetOtp(false)
    }
  }, [resetOtp, length])

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1)

    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf('')].focus()
    }
  }

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0 && inputRefs.current[index - 1]) {
      inputRefs.current[index - 1].focus()
    }

    if (index === length - 1 && e.key === 'Enter' && onKeyDown) {
      onKeyDown(e) // Call onKeyDown if it's defined
    }
  }

  return (
    <>
      {otp.map((value, index) => (
        <input
          placeholder="0"
          key={index}
          type="tel"
          ref={(input) => (inputRefs.current[index] = input)}
          value={value}
          onChange={(e) => handleChange(index, e)}
          onClick={() => handleClick(index)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          className="field"
          autoComplete="off"
        />
      ))}
    </>
  )
}

export default OtpInput
