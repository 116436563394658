import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import ReactGA from 'react-ga4'
import Slider from 'react-slick'
import { Button } from '@mui/material'
import useGet from 'hooks/useGet'
import { deleteWithAuthentication, getSelectCall, postLoginCall } from 'api/axios'
import { isLoggedIn } from 'utils/validateToken'
import { AddCookie, getValueFromCookie } from 'utils/cookies'
import { formatIndianRupees, getOrCreateDeviceId, getUserId } from 'helper'
import { search_types } from 'constants/searchTypes'
import { useTheme } from 'context/newThemeContext'
import { ToastContext } from 'context/toastContext'
import { CartContext } from 'context/cartContext'
import { SearchContext } from 'context/searchContext'
import { toast_actions, toast_types } from 'components/shared/toast/utils/toast'
import ModalComponent from 'components/common/Modal'
import CustomizationRenderer from 'components/application/product-list/product-details/CustomizationRenderer'
import LoginModal from 'views/LoginModal'
import PlaceOrderModal from 'views/OrderModal'
import NoDataFound from 'views/EmptyData'
import ProductImage from 'assets/images/no_image_found.png'
import WishlistIcon from 'assets/svg/WishlistIcon'
import WishlistFilledIcon from 'assets/svg/WishlistFilledIcon'
import PreviousArrow from 'assets/svg/PreviousArrow'
import NextArrow from 'assets/svg/NextArrow'
import {
  TitleText,
  ProductsContainer,
  ProductCard,
  ProductDetailContainer,
  ProductDetailWrapper,
  ProductName,
  ProductPrice,
  ProductsWrapper,
  TopProductsWrapper,
  ButtonWrapper,
  TopSellingOptionWrapper,
  TopSellingWishlistWrapper,
  TopSellingImageSection,
  IconButton,
} from 'styles/home'

const TopSellingProducts = () => {
  const [orderModal, setOrderModal] = useState(false)
  const [buyNow, setBuyNow] = useState(false)
  const [loginModal, setLoginModal] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [customization_state, setCustomizationState] = useState({})
  const [deviceId, setDeviceId] = useState(null)
  const [topSellingData, setTopSellingData] = useState()
  const [loading, setLoading] = useState({})
  const [buttonDisable, setButtonDisable] = useState({})
  // const locationData = useLocation()
  const history = useHistory()
  const { theme } = useTheme()
  const dispatch = useContext(ToastContext)
  const { fetchCartItems, cartItems } = useContext(CartContext)
  const userId = getUserId()

  const { refetch: fetchWishlist } = useGet('wishlist', deviceId && `/clientApis/v2/wishlist/${userId}/${deviceId}`)

  const { setSearchData, setLocationData } = useContext(SearchContext)
  const useQuery = () => {
    // const { search } = locationData
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  const [search, setSearch] = useState({
    type: search_types.PRODUCT,
    value: '',
  })
  const [searchedLocation, setSearchedLocation] = useState({
    name: '',
    lat: '',
    lng: '',
  })

  // const [currentSlide, setCurrentSlide] = useState(0)
  // const [slidesToShow, setSlidesToShow] = useState(8)

  // const updateSlidesToShow = () => {
  //   const width = window.innerWidth
  //   if (width < 450) {
  //     setSlidesToShow(1)
  //   } else if (width < 1050) {
  //     setSlidesToShow(3)
  //   } else if (width < 1450) {
  //     setSlidesToShow(5)
  //   } else if (width < 1500) {
  //     setSlidesToShow(6)
  //   } else if (width < 1600) {
  //     setSlidesToShow(7)
  //   } else {
  //     setSlidesToShow(8)
  //   }
  // }

  // useEffect(() => {
  //   updateSlidesToShow()
  //   window.addEventListener('resize', updateSlidesToShow)
  //   return () => window.removeEventListener('resize', updateSlidesToShow)
  // }, [])

  // const handleSlideChange = (current) => {
  //   setCurrentSlide(current)
  // }

  var productSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    initialSlide: 0,
    // afterChange: handleSlideChange,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1450,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          infinite: false,
          autoplay: false,
          arrows: false,
        },
      },
    ],
    nextArrow: (
      <IconButton>
        <NextArrow />
      </IconButton>
    ),
    prevArrow: (
      <IconButton>
        <PreviousArrow />
      </IconButton>
    ),
  }

  useEffect(() => {
    if (topSellingData && topSellingData.length > 0) {
      const buttonStates = topSellingData.reduce((acc, product) => {
        const inCart = cartItems.some((item) => item.item.id === product.id)
        acc[product.id] = inCart
        return acc
      }, {})

      setButtonDisable(buttonStates)
    }
  }, [cartItems, topSellingData])

  const handleBuyNow = (product) => {
    ReactGA.event({
      category: 'Top Selling',
      action: 'Click',
      label: 'Buy Now',
    })

    setSelectedProduct(product)
    if (!isLoggedIn()) {
      setBuyNow(true)
      setLoginModal(true)
      return
    } else {
      setOrderModal(true)
    }
  }

  const query = useQuery()

  useEffect(() => {
    if (deviceId) fetchWishlist()
  }, [deviceId])

  function getLastEnteredValues() {
    const searchProductName = query.get('s')
    let search_context = getValueFromCookie('search_context')
    if (search_context) {
      search_context = Object.assign({}, JSON.parse(search_context))
      setSearch(() => ({
        type: search_context.search.type,
        value: query.size > 0 && searchProductName ? searchProductName : '',
      }))
      setSearchedLocation(search_context.location)
      setSearchData(() => ({
        type: search_context.search.type,
        value: query.size > 0 && searchProductName ? searchProductName : '',
      }))
      setLocationData(() => search_context.location)
    }
  }

  useEffect(() => {
    getLastEnteredValues()
  }, [])

  let selectedCustomizationIds = []

  const getCustomization_ = (groupId) => {
    let group = customization_state[groupId]
    if (!group) return

    group.selected.map((s) => selectedCustomizationIds.push(s.id))
    group?.childs?.map((child) => {
      getCustomization_(child)
    })
  }

  const getCustomizations = (product) => {
    if (!product?.customisation_items?.length) return null
    const customizations = []

    const firstGroupId = customization_state['firstGroup']?.id

    if (!firstGroupId) return
    getCustomization_(firstGroupId)

    for (const cId of selectedCustomizationIds) {
      let c = product?.customisation_items.find((item) => item.local_id === cId)
      if (c) {
        c = {
          ...c,
          quantity: {
            count: 1,
          },
        }
        customizations.push(c)
      }
    }

    return customizations
  }

  const handleAddToCart = async (product) => {
    const userId = getUserId()

    if (buttonDisable[product.id]) return
    ReactGA.event({
      category: 'Top Selling',
      action: 'Click',
      label: 'Add to Cart',
    })
    setLoading((prevState) => ({ ...prevState, [product.id]: true }))

    let searchDataUpdate = Object.assign({}, JSON.parse(JSON.stringify(search)))
    const search_context = {
      search: searchDataUpdate,
      location: searchedLocation,
    }
    AddCookie('search_context', JSON.stringify(search_context))
    const deviceId = await getOrCreateDeviceId()

    const url = `/clientApis/v2/cart/${userId}/${deviceId}`
    // const url = `/clientApis/v2/cart/${userId ? userId : customCartId}`
    // const subtotal = product?.item_details?.price?.value

    // Pass getCustomizations(product) directly to the payload
    const payload = {
      customisations: getCustomizations(product),
      hasCustomisations: !!getCustomizations(product),
      customisationState: customization_state,
      local_id: product?.local_id,
      id: product?.id,
      provider: {
        id: product?.provider_details?.id,
      },
      quantity: {
        count: 1,
      },
    }

    try {
      const data = await postLoginCall(url, payload)
      if (data) {
        fetchCartItems()
        localStorage.setItem('cartItems', JSON.stringify(data))
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.success,
            message: 'Item added to cart successfully.',
          },
        })
      }

      setLoading((prevState) => ({ ...prevState, [product.id]: false }))
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message:
            'Unfortunately, We’re experiencing some technical issues while adding items to your cart. Please bear with us & get back to us sometime.',
        },
      })
    }
  }

  const handleWishlist = async (product) => {
    const userId = getUserId()
    const { item_details } = product
    if (!isLoggedIn()) {
      setLoginModal(true)
      return
    }

    // fetchWishlist()
    const deviceId = await getOrCreateDeviceId()

    const url = `/clientApis/v2/wishlist/${userId}/${deviceId}`
    // const subtotal = product?.item_details?.price?.value

    // Pass getCustomizations(product) directly to the payload
    const payload = {
      local_id: product?.local_id,
      id: product?.id,
      provider: {
        id: product?.provider_details?.id,
      },
      quantity: {
        count: 1,
      },
    }

    setTopSellingData((prevItems) =>
      prevItems.map((item) => (item.item_details.id === item_details?.id ? { ...item, wishlistAdded: true } : item)),
    )

    try {
      const res = await postLoginCall(url, payload)
      if (res.status !== 'error') {
        localStorage.setItem('wishlistItems', JSON.stringify(res))
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.success,
            message: 'Item added to wishlist successfully.',
          },
        })
      } else {
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message: 'It looks like the item already exists in the Wishlist. Please check',
          },
        })
      }
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: 'Sorry, you cannot add this item to your Wishlist due to technical glitch. Please try again.',
        },
      })
    }
  }

  const getTopSellingProducts = async () => {
    const userId = getUserId()
    try {
      const data = await getSelectCall(`/clientApis/v2/top_selling_order/${userId}?deviceId=${deviceId}`)
      setTopSellingData(data)
      return data
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: 'Sorry, we hit a snag while fetching the top selling products. Please try again.',
        },
      })
    }
  }

  useEffect(() => {
    if (deviceId) {
      getTopSellingProducts()
      getTopSellingProducts()
    }
  }, [deviceId])

  const execute = async () => {
    const deviceId = await getOrCreateDeviceId()
    setDeviceId(deviceId)
  }

  useEffect(() => {
    execute()
  }, [])

  const handleRemoveFromTopSellingWishlist = async (item_details, e) => {
    const userId = getUserId()
    e.stopPropagation()

    if (!isLoggedIn()) {
      setLoginModal(true)
      return
    }

    try {
      const deviceId = await getOrCreateDeviceId()
      const url = `/clientApis/v2/item/wishlist/${userId}/${deviceId}/${item_details?.id}`
      await deleteWithAuthentication(url)
      setTopSellingData((prevItems) =>
        prevItems.map((item) => (item.item_details.id === item_details?.id ? { ...item, wishlistAdded: false } : item)),
      )
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.success,
          message: 'Item removed from your Wishlist',
        },
      })
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: 'Sorry, we hit a snag while fetching the top selling products. Please try again.',
        },
      })
    }
  }

  return (
    <>
      <TopProductsWrapper>
        <TitleText theme={theme}>Top Selling Products</TitleText>
      </TopProductsWrapper>

      <ProductsContainer>
        {topSellingData && topSellingData.length > 0 ? (
          <Slider {...productSettings}>
            {topSellingData.map((item, index) => {
              const { item_details } = item

              return (
                <ProductsWrapper key={index}>
                  <ProductCard>
                    <TopSellingOptionWrapper>
                      <TopSellingWishlistWrapper>
                        {item.wishlistAdded ? (
                          <WishlistFilledIcon
                            onClick={async (e) => {
                              handleRemoveFromTopSellingWishlist(item_details, e)
                            }}
                          />
                        ) : (
                          <WishlistIcon
                            onClick={() => {
                              handleWishlist(item)
                            }}
                          />
                        )}
                      </TopSellingWishlistWrapper>
                    </TopSellingOptionWrapper>
                    <TopSellingImageSection>
                      <img
                        src={item_details?.descriptor?.symbol ? item_details?.descriptor?.symbol : ProductImage}
                        alt={`topselling-img-${item?.id}`}
                        onClick={() => {
                          history.push(`products?productId=${item?.id}`)
                        }}
                        onError={(e) => {
                          e.target.src =
                            'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                        }}
                      />
                    </TopSellingImageSection>
                  </ProductCard>
                  <ProductDetailContainer>
                    <ProductDetailWrapper>
                      <ProductName>{item_details?.descriptor?.name}</ProductName>
                      <ProductPrice>
                        {' '}
                        ₹{formatIndianRupees(parseFloat(item_details?.price?.value).toFixed(2))}
                      </ProductPrice>
                    </ProductDetailWrapper>
                  </ProductDetailContainer>
                  <ButtonWrapper>
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        handleBuyNow(item)
                      }}
                      disabled={item_details?.quantity?.available?.count === '0'}
                    >
                      Buy Now
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        handleAddToCart(item)
                        setButtonDisable((prev) => ({ ...prev, [item?.id]: true }))
                      }}
                      disabled={
                        item_details?.quantity?.available?.count === '0' || buttonDisable[item?.id] || loading[item?.id]
                      }
                    >
                      {buttonDisable[item?.id] ? 'In Cart' : 'Add to Cart'}
                    </Button>
                  </ButtonWrapper>
                </ProductsWrapper>
              )
            })}
          </Slider>
        ) : (
          <NoDataFound />
        )}
      </ProductsContainer>

      <CustomizationRenderer customization_state={customization_state} setCustomizationState={setCustomizationState} />

      {loginModal && (
        <ModalComponent open={loginModal} onClose={() => setLoginModal(false)}>
          <LoginModal onClose={() => setLoginModal(false)} buyNow={buyNow} setOrderModal={setOrderModal} />
        </ModalComponent>
      )}
      {orderModal && (
        <ModalComponent
          open={orderModal}
          onClose={() => setOrderModal(false)}
          title="Get Ready To Shop !"
          titleBg={true}
        >
          <PlaceOrderModal onClose={() => setOrderModal(false)} product={selectedProduct} />
        </ModalComponent>
      )}
    </>
  )
}

export default TopSellingProducts
