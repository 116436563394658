import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import NavBar from './navbar/navbar'
import Footer from './footer/footer'
import { makeStyles } from '@mui/styles'
// import palette from "../../utils/Theme/palette";
import { SearchContextProvider } from '../../context/searchContext'
import { BoxWrapper } from '../../styles/navbar'

const useStyles = makeStyles({
  allLayoutContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  mainContainer: {
    flexGrow: 1,
    height: '100%',
    minHeight: '266px',
  },
})

const AppLayout = ({ children, isCheckout = false }) => {
  const classes = useStyles()

  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <Box className={classes.allLayoutContainer}>
      <SearchContextProvider>
        <NavBar isCheckout={isCheckout} />
      </SearchContextProvider>
      <BoxWrapper>
        <Box component="main" className={classes.mainContainer}>
          {children}
        </Box>
      </BoxWrapper>
      <Footer />
    </Box>
  )
}

export default AppLayout
