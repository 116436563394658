import React from 'react'
import FooterApi from 'utils/FooterApi'
import BreadcrumbComponent from 'components/Breadcrumbs'
import { MainWrapper, AboutUsWrapper, ContentWrapper } from 'styles/FooterLinks/AboutUsPage'

const AboutUsPage = () => {
  const { data } = FooterApi('aboutus')

  return (
    <MainWrapper>
      <AboutUsWrapper>
        <BreadcrumbComponent title="About Us" />
        <ContentWrapper>
          <div dangerouslySetInnerHTML={{ __html: data?.aboutus }} />
        </ContentWrapper>
      </AboutUsWrapper>
    </MainWrapper>
  )
}

export default AboutUsPage
