import React, { useState } from 'react'
// import { ReactComponent as CallIcon } from '../../../assets/images/call.svg'
// import { ReactComponent as WhatsappIcon } from '../../../assets/images/whatsapp.svg'
import { categoryList } from '../../../constants/categories'
import { Link, useHistory, useLocation } from 'react-router-dom'
import {
  FooterContainer,
  FooterWrapper,
  FooterDiv,
  FooterHeading,
  FooterHeading1,
  // HeadingSection,
  CopyRightSection,
  CopyRight,
} from 'styles/footer'
import { useTheme } from '../../../context/newThemeContext'
import ModalComponent from 'components/common/Modal'
import TrackOrderModal from 'views/TrackOrderModal'

const Footer = () => {
  const history = useHistory()
  const locationData = useLocation()
  const [trackOrderModal, setTrackOrderModal] = useState(false)

  const useQuery = () => {
    const { search } = locationData
    return React.useMemo(() => new URLSearchParams(search), [search])
  }
  let query = useQuery()

  const updateQueryParams = (catName) => {
    const params = new URLSearchParams(locationData.search)
    params.set('c', catName)

    if (locationData.search === '' && query.get('c') === null) {
      history.push(`/products?${params.toString()}`)
    } else {
      history.replace({ pathname: locationData.pathname, search: params.toString() })
    }
  }
  const { theme } = useTheme()

  return (
    <FooterContainer theme={theme}>
      <FooterWrapper>
        <FooterDiv>
          <FooterHeading>Need Help</FooterHeading>
          <FooterHeading1>
            <Link to="/contact-us">Contact Us</Link>
          </FooterHeading1>
          <FooterHeading1 onClick={() => setTrackOrderModal(true)}>Track Order</FooterHeading1>
          <FooterHeading1>
            <Link to="/returns-refund">Returns & Refunds</Link>
          </FooterHeading1>
          <FooterHeading1>
            <Link to="/faq">FAQ&apos;s</Link>
          </FooterHeading1>
        </FooterDiv>
        <FooterDiv>
          <FooterHeading>Categories</FooterHeading>
          {categoryList?.map((item, index) => (
            <li
              key={`category-${index}`}
              onClick={() => updateQueryParams(item.routeName)}
              style={{ listStyle: 'none', cursor: 'pointer' }}
            >
              <FooterHeading1>{item.shortName}</FooterHeading1>
            </li>
          ))}
        </FooterDiv>
        <FooterDiv>
          <FooterHeading>More Info</FooterHeading>
          <FooterHeading1>
            <Link to="/about-us">About Us</Link>
          </FooterHeading1>
          <FooterHeading1>
            <Link to="/terms-conditions">Terms & Condition</Link>
          </FooterHeading1>
          <FooterHeading1>
            <Link to="/shipping-policy">Shipping Policy</Link>
          </FooterHeading1>
          <FooterHeading1>
            <Link to="/cancellation-policy">Cancellation Policy</Link>
          </FooterHeading1>
        </FooterDiv>
        {/* <FooterDiv>
          <FooterHeading>Contact Us</FooterHeading>
          <FooterHeading1>
            <WhatsappIcon />
            <HeadingSection>
              <FooterHeading1>Whats App</FooterHeading1>
              <FooterHeading1>+1 202-918-2132</FooterHeading1>
            </HeadingSection>
          </FooterHeading1>
          <FooterHeading1>
            <CallIcon />
            <HeadingSection>
              <FooterHeading1>Call Us</FooterHeading1>
              <FooterHeading1>+1 202-918-2132</FooterHeading1>
            </HeadingSection>
          </FooterHeading1>
        </FooterDiv> */}
      </FooterWrapper>
      <CopyRightSection>
        <CopyRight> © 2024 Bharatham. All rights reserved.</CopyRight>
      </CopyRightSection>
      {trackOrderModal && (
        <ModalComponent open={trackOrderModal} onClose={() => setTrackOrderModal(false)} title="Track Order">
          <TrackOrderModal onClose={() => setTrackOrderModal(false)} />
        </ModalComponent>
      )}
    </FooterContainer>
  )
}

export default Footer
