import React, { useContext, useEffect, useState } from 'react'
import useStyles, { MobileWrapper, WebWrapper, Container } from './style'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import MuiLink from '@mui/material/Link'
import Box from '@mui/material/Box'
import { useParams, Link } from 'react-router-dom'
import OrderSummary from './orderSummary'
import TrackingMap from './trackingMap'
import CustomerDetails from './customerDetails'
import { getOrderDetailsRequest } from '../../../api/orders.api'
import useCancellablePromise from '../../../api/cancelRequest'
import Loading from '../../shared/loading/loading'
import { ToastContext } from '../../../context/toastContext'
import { toast_actions, toast_types } from '../../shared/toast/utils/toast'

const OrderDetails = () => {
  const classes = useStyles()
  // const history = useHistory()
  const { orderId } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [orderDetails, setOrderDetails] = useState(null)
  const [trakingDetails, setTrakingDetails] = useState(null)
  const dispatch = useContext(ToastContext)

  // HOOKS
  const { cancellablePromise } = useCancellablePromise()

  useEffect(() => {
    if (orderId) {
      getOrderDetails()
    }
  }, [orderId])

  const getOrderDetails = async () => {
    if (isLoading) {
      setIsLoading(true)
    }

    try {
      const data = await cancellablePromise(getOrderDetailsRequest(orderId))
      setOrderDetails(data[0])
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err?.response?.data?.error?.message,
        },
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbWrapper}>
        <MuiLink component={Link} underline="hover" color="inherit" to="/">
          <Typography>Home</Typography>
        </MuiLink>
        <MuiLink component={Link} underline="hover" color="inherit" to="/profile">
          Order History
        </MuiLink>
        {orderId && <Typography color="#115469">Order Details</Typography>}
      </Breadcrumbs>
      <Grid container spacing={5} className={classes.orderDetailsContainer}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
        {isLoading ? (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.loaderContainer}>
            <Loading />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} sm={12} md={7} lg={7} xl={7} className={classes.detailsContainer}>
              <TrackingMap orderDetails={orderDetails} trakingDetails={trakingDetails} />
              <Box component={'div'} className={classes.divider} />
              <WebWrapper>
                <CustomerDetails orderDetails={orderDetails} />
              </WebWrapper>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5} xl={5} className={classes.detailsContainer}>
              <OrderSummary
                orderDetails={orderDetails}
                onUpdateOrder={() => {
                  getOrderDetails(false)
                }}
                onUpdateTrakingDetails={(data) => {
                  setTrakingDetails(data)
                }}
              />
              <MobileWrapper>
                <CustomerDetails orderDetails={orderDetails} />
              </MobileWrapper>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  )
}

export default OrderDetails
