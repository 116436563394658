import BreadcrumbComponent from 'components/Breadcrumbs'
import React from 'react'
import { MainWrapper, AboutUsWrapper } from 'styles/FooterLinks/AboutUsPage'
import FooterApi from 'utils/FooterApi'
import FaqSection from 'views/FrequentlyAskedQuestions'

const FAQs = () => {
  const { data } = FooterApi('faq')
  return (
    <MainWrapper>
      <AboutUsWrapper>
        <BreadcrumbComponent title={'FAQ’s'} />
        <FaqSection faqData={data?.faq} />
      </AboutUsWrapper>
    </MainWrapper>
  )
}

export default FAQs
