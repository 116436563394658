import { makeStyles } from '@mui/styles'
import { styled } from 'styled-components'
import palette from '../../../utils/Theme/palette'

const moreImageContainer = (height, width, borderColor) => ({
  height: height,
  width: width,
  border: '1px solid',
  borderColor: borderColor,
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
})

const useStyles = makeStyles({
  headingContainer: {
    height: 70,
    width: '100%',
    padding: '0 20px',
    display: 'flex',
    alignItems: 'center',
  },
  heading: {
    textTransform: 'none !important',
    fontSize: '18px !important',
    fontFamily: 'poppins',
  },
  tableHead: {
    fontWeight: '400 !important',
    fontSize: '12px !important',
    minWidth: '50px',
  },
  moreImages: {
    ...moreImageContainer(110, 110, 'lightgrey'),
    marginRight: '14px',
    borderRadius: 10,
    padding: 4,
    backgroundColor: '#ffffff',
  },
  greyContainer: {
    ...moreImageContainer('100%', '100%', '#e7e7e7'),
    backgroundColor: '#e7e7e7',
    borderRadius: 8,
    position: 'relative',
  },
  moreImage: {
    height: 80,
    objectFit: 'contain',
  },
  logoContainer: {
    height: 25,
    marginRight: 4,
  },
  logo: {
    height: '100%',
    width: '100%',
  },
  qtyContainer: {
    maxWidth: 64,
    minWidth: 64,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  qtyArrowUp: {
    position: 'absolute',
    top: -2,
    right: 2,
    color: '#000',
    fontSize: '18px !important',
    cursor: 'pointer',
  },
  qtyArrowDown: {
    position: 'absolute',
    bottom: -2,
    right: 2,
    color: '#000',
    fontSize: '18px !important',
    cursor: 'pointer',
  },
  summaryCard: {
    // marginTop: 20,
    width: '100%',
    maxWidth: '500px',
    minWidth: '400px',
    padding: '16px 24px',
    display: 'flex',
    flexDirection: 'column',
  },
  summaryTypo: {
    fontWeight: '400 !important',
    marginBottom: '20px',
  },
  summaryLabel: {
    fontSize: '14px !important',
    fontWeight: '800 !important',
  },
  emptyCartScreen: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#F9F9F9',
    height: '80vh',
  },
  infoBox: {
    borderRadius: '8px',
    background: 'rgba(249, 197, 28, 0.17)',
    padding: '8px 16px',
    width: 'max-content',
    marginTop: '20px',
  },
  infoText: {
    fontFamily: 'Inter !important',
    fontSize: 14,
    fontWeight: '500 !important',
    color: '#C89A04',
  },
  loadingContainer: {
    height: '70vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  updateBtn: {
    position: 'absolute !important',
    right: 12,
    bottom: 7,
  },
  square: {
    backgroundColor: '#ffffff',
    border: '1px solid #008001',
    width: 14,
    height: 15,
    marginRight: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circle: {
    backgroundColor: '#008001',
    borderRadius: '50%',
    height: '9px',
    width: '9px',
  },
  tagContainer: {
    position: 'absolute',
    top: 4,
    left: 4,
  },
  '@media (max-width: 600px)': {
    heading: {
      fontSize: '24px !important',
    },
    headingContainer: {
      height: 70,
      padding: '0 16px',
    },
    moreImages: {
      marginRight: '14px',
      marginBottom: '14px',
    },
    moreImage: {
      height: 80,
      width: 80,
      objectFit: 'contain',
    },
  },
})

export default useStyles

export const CartContainer = styled.div`
  display: flex;
  gap: 20px;

  button {
    background-color: ${palette.primary.main} !important;
  }

  @media screen and (max-width: 1050px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`
export const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 8;
  .loader {
    --r1: 154%;
    --r2: 68.5%;
    width: 60px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: radial-gradient(var(--r1) var(--r2) at top, #0000 79.5%, #115469 80%),
      radial-gradient(var(--r1) var(--r2) at bottom, #115469 79.5%, #0000 80%),
      radial-gradient(var(--r1) var(--r2) at top, #0000 79.5%, #115469 80%), #ccc;
    background-size: 50.5% 220%;
    background-position:
      -100% 0%,
      0% 0%,
      100% 0%;
    background-repeat: no-repeat;
    animation: l9 2s infinite linear;
  }
  @keyframes l9 {
    33% {
      background-position:
        0% 33%,
        100% 33%,
        200% 33%;
    }
    66% {
      background-position:
        -100% 66%,
        0% 66%,
        100% 66%;
    }
    100% {
      background-position:
        0% 100%,
        100% 100%,
        200% 100%;
    }
  }
`
export const BuyNowLoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 8;
  .loader {
    --r1: 154%;
    --r2: 68.5%;
    width: 60px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: radial-gradient(var(--r1) var(--r2) at top, #0000 79.5%, #115469 80%),
      radial-gradient(var(--r1) var(--r2) at bottom, #115469 79.5%, #0000 80%),
      radial-gradient(var(--r1) var(--r2) at top, #0000 79.5%, #115469 80%), #ccc;
    background-size: 50.5% 220%;
    background-position:
      -100% 0%,
      0% 0%,
      100% 0%;
    background-repeat: no-repeat;
    animation: l9 2s infinite linear;
  }
  @keyframes l9 {
    33% {
      background-position:
        0% 33%,
        100% 33%,
        200% 33%;
    }
    66% {
      background-position:
        -100% 66%,
        0% 66%,
        100% 66%;
    }
    100% {
      background-position:
        0% 100%,
        100% 100%,
        200% 100%;
    }
  }
`
