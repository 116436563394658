import React, { useContext, useEffect, useState } from 'react'
import useStyles, { BrandItemContainer, StoreCardContainer, NoDataWrapper } from './style'
import Grid from '@mui/material/Grid'
import { getAllOutletsFromCategoryAndLocationRequest } from '../../../api/brand.api'
import useCancellablePromise from '../../../api/cancelRequest'
import { ToastContext } from '../../../context/toastContext'
import { toast_actions, toast_types } from '../../shared/toast/utils/toast'
import { useLocation } from 'react-router-dom'
import { categoryList } from '../../../constants/categories'
import SingleBrand from './singleBrand'
import { ReactComponent as PreviousIcon } from '../../../assets/images/previous.svg'
import { ReactComponent as NextIcon } from '../../../assets/images/next.svg'
import { SearchContext } from '../../../context/searchContext'
import { LocationContext } from '../../../context/locationContext'
import { useTheme } from '../../../context/newThemeContext'
import Slider from 'react-slick'
import { TitleText } from '../../../styles/home'
import Spinner from 'components/Loader'
import { HeadingWrapper, ViewAllText } from 'components/product/subCategories/style'
import { useHistory } from 'react-router-dom'
import NoDataFound from 'views/EmptyData'

const Brands = () => {
  const classes = useStyles()
  const { locationData: deliveryAddressLocation } = useContext(SearchContext)
  const [brands, setBrands] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()

  const dispatch = useContext(ToastContext)
  const { latitude, longitude } = useContext(LocationContext)

  // HOOKS
  const { cancellablePromise } = useCancellablePromise()

  const locationData = useLocation()
  const useQuery = () => {
    const { search } = locationData
    return React.useMemo(() => new URLSearchParams(search), [search])
  }
  let query = useQuery()

  useEffect(() => {
    const categoryName = query.get('c')

    if (categoryName) {
      getAllBrands(categoryName)
    }
  }, [locationData, deliveryAddressLocation])

  const getAllBrands = async (categoryName) => {
    // let sc = JSON.parse(getValueFromCookie('search_context') || {})
    const findCategory = categoryList.find((item) => item.routeName === categoryName)
    if (findCategory) {
      setIsLoading(true)
      try {
        const reqParams = {
          domain: findCategory.domain,
          lat: latitude,
          lng: longitude,
        }
        const data = await cancellablePromise(getAllOutletsFromCategoryAndLocationRequest(reqParams))
        setBrands(data.data)
      } catch (err) {
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message: err?.response?.data?.error?.message,
          },
        })
      } finally {
        setIsLoading(false)
      }
    }
  }
  const { theme } = useTheme()

  const rows = brands.length > 7 ? 2 : 1

  var settings = {
    dots: false,
    infinite: true,
    // autoplay: true,
    arrows: false,
    speed: 500,
    rows: rows,
    slidesToShow: 8,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextIcon />,
    prevArrow: <PreviousIcon />,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1450,
        settings: {
          rows: brands.length > 7 ? 2 : 1,
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  }

  const handleAllProviders = () => {
    const categoryName = query.get('c')
    const params = new URLSearchParams({})
    if (categoryName) {
      params.set('c', categoryName)
    }

    history.push({
      pathname: '/providers',
      // search: categoryName ? `?domain=${categoryName.toString()}` : '',
      search: params.toString(),
    })
  }

  return (
    <BrandItemContainer theme={theme}>
      <Grid container spacing={3} className={classes.brandContainer}>
        <HeadingWrapper>
          <TitleText>Stores near you</TitleText>
          <ViewAllText onClick={handleAllProviders}>View All </ViewAllText>
        </HeadingWrapper>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={3}>
            {isLoading ? (
              <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                <Spinner />
              </Grid>
            ) : (
              <>
                {brands?.length > 0 ? (
                  <StoreCardContainer theme={theme}>
                    <Slider {...settings}>
                      {brands?.map((item, ind) => (
                        <div key={`sub-cat-item-${ind}`}>
                          <SingleBrand data={item} />
                        </div>
                      ))}
                    </Slider>
                  </StoreCardContainer>
                ) : (
                  <NoDataWrapper className="empty-state">
                    <NoDataFound />
                  </NoDataWrapper>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </BrandItemContainer>
  )
}

export default Brands
