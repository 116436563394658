import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import useStyles from './style'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Radio from '../../../common/Radio'
import { AddCookie } from '../../../../utils/cookies'
import { postCheckoutCall } from '../../../../api/axios'
import useCancellablePromise from '../../../../api/cancelRequest'
import { restoreToDefault } from '../../../../constants/restoreDefaultAddress'
import { address_types, address_tags } from '../../../../constants/address-types'
import { ToastContext } from '../../../../context/toastContext'
import { toast_actions, toast_types } from '../../../shared/toast/utils/toast'
import { AddressContainer, GridWrapper, NameWrapper, Note } from '../../../checkout/style'
import MapPicker from '../../../map'
import {
  checkCity,
  checkDoor,
  checkEmail,
  checkLandMark,
  checkName,
  checkPhoneNumber,
  checkPinCode,
  checkState,
  checkStreetName,
  checkTag,
} from '../../../../utils/helper'

const AddressForm = (props) => {
  const classes = useStyles()
  const {
    action_type,
    address_type,
    selectedAddress = restoreToDefault(),
    onClose,
    onAddAddress,
    onUpdateAddress,
    fetchDeliveryAddress,
    fromCheckout = false,
    isMapRequired = true,
    IsBillingAddressSame = false,
    IsBillingForm = false,
    setToggleModal,
  } = props

  // STATES

  const [address, setAddress] = useState(selectedAddress)
  const [openMap, setOpenMap] = useState(false)
  const [error, setError] = useState({
    name_error: '',
    email_error: '',
    phone_error: '',
    areaCode_error: '',
    city_name_error: '',
    door_error: '',
    state_name_error: '',
    street_name_error: '',
    tag_error: '',
  })
  const { cancellablePromise } = useCancellablePromise()
  const dispatch = useContext(ToastContext)

  // use this function to fetch city and pincode
  const fetchCityAndStateOnAreacode = async (areaCode) => {
    // setCityStateLoading(true)
    try {
      const { data } = await cancellablePromise(
        axios.get(`${process.env.REACT_APP_MMI_BASE_URL}mmi/api/mmi_pin_info?pincode=${areaCode}`),
      )
      const cityName = data?.copResults?.city ? data?.copResults?.city : data?.copResults?.district
      const stateName = data?.copResults?.state
      setAddress((address) => ({
        ...address,
        city: cityName,
        state: stateName,
      }))
      setError((error) => ({
        ...error,
        city_name_error: '',
        state_name_error: '',
      }))
    } catch (err) {
      let message = 'Please enter valid Pin Code'
      if (err.response.status !== 500) {
        message = err.response.data.message
      }

      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: message,
        },
      })
      setAddress((address) => ({
        ...address,
        areaCode: '',
        city: '',
        state: '',
      }))
    }
  }

  useEffect(() => {
    setAddress(selectedAddress)
  }, [selectedAddress, IsBillingAddressSame])

  // add delivery address
  const handleAddDeliveryAddress = async () => {
    const allChecksPassed = [
      checkName(address, setError),
      checkEmail(address, setError),
      checkPhoneNumber(address, setError),
      checkStreetName(address, setError),
      checkLandMark(address, setError),
      checkCity(address, setError),
      checkState(address, setError),
      checkTag(address, setError),
      checkPinCode(address, setError),
      checkDoor(address, setError),
    ].every(Boolean)
    if (!allChecksPassed) {
      return
    }

    try {
      const data = await cancellablePromise(
        postCheckoutCall('/clientApis/v1/delivery_address', {
          descriptor: {
            name: address.name.trim(),
            email: address.email.trim(),
            phone: address.phone.trim(),
          },
          address: {
            areaCode: address.areaCode.trim(),
            building: address.door.trim(),
            city: address.city.trim(),
            country: 'IND',
            door: address.door.trim(),
            state: address.state.trim(),
            street: address.street.trim(),
            tag: address.tag.trim(),
            lat: address.lat,
            lng: address.lng,
          },
        }),
      )

      AddCookie('delivery_address', JSON.stringify(data))
      onAddAddress(data)
      fetchDeliveryAddress()
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err?.response?.data?.error?.message,
        },
      })
    }
  }

  // add billing address
  const handleAddBillingAddress = async () => {
    const allChecksPassed = [
      checkName(address, setError),
      checkEmail(address, setError),
      checkPhoneNumber(address, setError),
      checkStreetName(address, setError),
      checkLandMark(address, setError),
      checkCity(address, setError),
      checkState(address, setError),
      checkTag(address, setError),
      checkPinCode(address, setError),
      checkDoor(address, setError),
    ].every(Boolean)
    if (!allChecksPassed) {
      return
    }

    try {
      const updatedata = await cancellablePromise(
        postCheckoutCall('/clientApis/v1/billing_details', {
          name: address.name.trim(),
          address: {
            areaCode: address.areaCode.trim(),
            building: address.door.trim(),
            city: address.city.trim(),
            country: 'IND',
            door: address.door.trim(),
            state: address.state.trim(),
            street: address.street.trim(),
            tag: address.tag.trim(),
            lat: address.lat,
            lng: address.lng,
          },
          email: address.email.trim(),
          phone: address.phone.trim(),
        }),
      )
      fetchDeliveryAddress()
      AddCookie('billing_address', JSON.stringify(updatedata))
      onAddAddress(updatedata)
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err?.response?.data?.error?.message,
        },
      })
    }
  }

  // update delivery address
  const handleUpdateDeliveryAddress = async () => {
    const allChecksPassed = [
      checkName(address, setError),
      checkEmail(address, setError),
      checkPhoneNumber(address, setError),
      checkStreetName(address, setError),
      checkLandMark(address, setError),
      checkCity(address, setError),
      checkState(address, setError),
      checkTag(address, setError),
      checkPinCode(address, setError),
      checkDoor(address, setError),
    ].every(Boolean)
    if (!allChecksPassed) {
      return
    }

    try {
      const data = await cancellablePromise(
        postCheckoutCall(`/clientApis/v1/update_delivery_address/${address.id}`, {
          descriptor: {
            name: address.name.trim(),
            email: address.email.trim(),
            phone: address.phone.trim(),
          },
          address: {
            areaCode: address.areaCode.trim(),
            building: address.door.trim(),
            city: address.city.trim(),
            country: 'IND',
            door: address.door.trim(),
            state: address.state.trim(),
            street: address.street.trim(),
            tag: address.tag.trim(),
            lat: address.lat,
            lng: address.lng,
          },
        }),
      )
      fetchDeliveryAddress()
      onUpdateAddress(data)
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err?.response?.data?.error?.message,
        },
      })
    }
  }

  // update billing address
  const handleUpdateBillingAddress = async () => {
    const allChecksPassed = [
      checkName(address, setError),
      checkEmail(address, setError),
      checkPhoneNumber(address, setError),
      checkStreetName(address, setError),
      checkLandMark(address, setError),
      checkCity(address, setError),
      checkState(address, setError),
      checkTag(address, setError),
      checkPinCode(address, setError),
      checkDoor(address, setError),
    ].every(Boolean)
    if (!allChecksPassed) {
      return
    }

    try {
      const data = await cancellablePromise(
        postCheckoutCall(`/clientApis/v1/update_billing_details/${address.id}`, {
          name: address.name.trim(),
          address: {
            areaCode: address.areaCode.trim(),
            building: address.door.trim(),
            city: address.city.trim(),
            country: 'IND',
            door: address.door.trim(),
            state: address.state.trim(),
            street: address.street.trim(),
            tag: address.tag.trim(),
            lat: address.lat,
            lng: address.lng,
          },
          email: address.email.trim(),
          phone: address.phone.trim(),
        }),
      )
      onUpdateAddress(data)
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err?.response?.data?.error?.message,
        },
      })
    }
  }

  return (
    <AddressContainer>
      {!IsBillingAddressSame && (
        <Grid item xs={12} sm={12} md={7} lg={12} xl={12}>
          <Button
            className="address_button"
            color="primary"
            onClick={() => setOpenMap(!openMap)}
            style={{ margin: '5px' }}
          >
            {openMap ? 'Close Map' : 'Open Map'}
          </Button>
          <NameWrapper>
            <Note> * Please select your location on map first</Note>
          </NameWrapper>
          {isMapRequired && openMap && <MapPicker address={address} setAddress={setAddress} />}
        </Grid>
      )}
      <GridWrapper>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField
            className="disabled"
            required
            fullWidth
            id="name-input"
            name="name"
            label="Name"
            placeholder="Enter Name"
            type="text"
            disabled={IsBillingAddressSame}
            value={address.name || ''}
            onChange={(event) => {
              const name = event.target.value
              setAddress((address) => ({
                ...address,
                name: name,
              }))
              setError((error) => ({
                ...error,
                name_error: '',
              }))
            }}
            error={!!error.name_error}
            helperText={error.name_error}
            onBlur={checkName}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <TextField
            required
            disabled={IsBillingAddressSame}
            fullWidth
            id="email-input"
            name="email"
            label="Email"
            placeholder="Enter Email"
            type="email"
            value={address?.email || ''}
            onChange={(event) => {
              const name = event.target.value
              setAddress((address) => ({
                ...address,
                email: name,
              }))
              setError((error) => ({
                ...error,
                email_error: '',
              }))
            }}
            error={!!error.email_error}
            helperText={error.email_error}
            onBlur={checkEmail}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <TextField
            required
            disabled={IsBillingAddressSame}
            fullWidth
            id="phone-input"
            name="phone"
            label="Phone Number"
            placeholder="Enter Phone"
            type="text"
            value={address?.phone || ''}
            onChange={(event) => {
              const regexp = /^[0-9]+$/
              if (!regexp.test(event.target.value) && event.target.value !== '') return
              const name = event.target.value
              setAddress((address) => ({
                ...address,
                phone: name,
              }))
              setError((error) => ({
                ...error,
                phone_error: '',
              }))
            }}
            error={!!error.phone_error}
            helperText={error.phone_error}
            onBlur={checkPhoneNumber}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <TextField
            required
            disabled={IsBillingAddressSame}
            fullWidth
            id="Landmark-input"
            name="Building"
            label="Building"
            placeholder="Enter Building"
            type="text"
            value={address?.door || ''}
            onChange={(event) => {
              const name = event.target.value
              setAddress((address) => ({
                ...address,
                door: name,
              }))
              setError((error) => ({
                ...error,
                door_error: '',
              }))
            }}
            error={!!error.door_error}
            helperText={error.door_error}
            onBlur={checkLandMark}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField
            disabled
            required
            fullWidth
            id="Street-input"
            name="Street"
            label="Street"
            placeholder="Enter Street"
            type="text"
            value={address?.street || ''}
            onChange={(event) => {
              const name = event.target.value
              setAddress((address) => ({
                ...address,
                street: name,
              }))
              setError((error) => ({
                ...error,
                street_name_error: '',
              }))
            }}
            error={!!error.street_name_error}
            helperText={error.street_name_error}
            onBlur={checkStreetName}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <TextField
            disabled
            required
            fullWidth
            id="pin_code-input"
            name="pin_code"
            label="Pin Code"
            placeholder="Enter Pin Code"
            type="text"
            value={address?.areaCode || ''}
            inputProps={{
              maxLength: 6,
            }}
            onChange={(event) => {
              const regexp = /^[0-9]+$/
              if (!regexp.test(event.target.value) && event.target.value !== '') return
              const areaCode = event.target.value
              // if the length is 6 than call the city and state fetch call
              if (areaCode.length === 6) {
                fetchCityAndStateOnAreacode(areaCode)
              }

              setAddress((address) => ({
                ...address,
                areaCode: areaCode,
              }))
              setError((error) => ({
                ...error,
                areaCode_error: '',
              }))
            }}
            error={!!error.areaCode_error}
            helperText={error.areaCode_error}
            onBlur={checkPinCode}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <TextField
            disabled
            required
            fullWidth
            id="City-input"
            name="City"
            label="City"
            placeholder="Enter City"
            type="text"
            value={address?.city || ''}
            onChange={(event) => {
              const name = event.target.value
              setAddress((address) => ({
                ...address,
                city: name,
              }))
              setError((error) => ({
                ...error,
                city_name_error: '',
              }))
            }}
            error={!!error.city_name_error}
            helperText={error.city_name_error}
            onBlur={checkCity}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <TextField
            disabled
            required
            fullWidth
            id="State-input"
            name="State"
            label="State"
            placeholder="Enter State"
            type="text"
            value={address?.state || ''}
            onChange={(event) => {
              const name = event.target.value
              setAddress((address) => ({
                ...address,
                state: name,
              }))
              setError((error) => ({
                ...error,
                state_name_error: '',
              }))
            }}
            error={!!error.state_name_error}
            helperText={error.state_name_error}
            onBlur={checkState}
          />
        </Grid>
      </GridWrapper>

      {!IsBillingAddressSame && (
        <>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl>
              <Typography variant="body2" className={classes.tagLabel}>
                Tag
              </Typography>
              <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                {address_tags.length > 0 &&
                  address_tags.map((tag, ind) => {
                    return (
                      <div key={`Tag-radio-button-${ind}`} className={classes.selectAddressRadioContainer}>
                        <FormControlLabel
                          className={classes.formControlLabel}
                          onClick={() => {
                            setAddress((address) => ({
                              ...address,
                              tag: tag,
                            }))
                            setError((error) => ({
                              ...error,
                              tag_error: '',
                            }))
                          }}
                          control={<Radio className={classes.tagRadio} checked={tag === address?.tag} />}
                          label={tag}
                        />
                      </div>
                    )
                  })}
              </RadioGroup>
            </FormControl>
            {!!error.tag_error && (
              <Typography variant={'body2'} color="error">
                {error.tag_error}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {action_type === 'edit' ? (
              <Button
                className="address_button"
                color="primary"
                onClick={() => {
                  if (address_type === address_types.delivery) return handleUpdateDeliveryAddress()
                  handleUpdateBillingAddress()
                }}
              >
                Update Address
              </Button>
            ) : (
              <Button
                className="address_button"
                color="primary"
                onClick={() => {
                  setToggleModal && setToggleModal(false)
                  if (IsBillingForm) {
                    return handleAddBillingAddress()
                  }

                  if (address_type === address_types.delivery) return handleAddDeliveryAddress()
                }}
              >
                Add Address
              </Button>
            )}
            {fromCheckout && (
              <Button
                className="cancel_button"
                color="error"
                sx={{
                  ml: 1,
                }}
                onClick={() => {
                  onClose()
                }}
              >
                Cancel
              </Button>
            )}
          </Grid>
        </>
      )}
    </AddressContainer>
  )
}

export default AddressForm
