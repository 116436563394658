import React from 'react'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'

const Tabs = (props) => {
  const { value, handleChange, items, panelItems } = props
  return (
    <div>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              {items.map((item, index) => {
                return <Tab key={index} label={item.label} value={item.value} />
              })}
            </TabList>
          </Box>

          {panelItems.map((item, index) => {
            return (
              <TabPanel className="mui-tab-class" key={index} value={item.id}>
                {item.data}
              </TabPanel>
            )
          })}
        </TabContext>
      </Box>
    </div>
  )
}

export default Tabs
