import loader from 'assets/gif/loader1.gif'
import { Loader, LoaderWrapper } from 'styles/Loader'

const Spinner = () => (
  <div
    style={{
      display: 'flex',
      position: 'fixed',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 99999,
      backdropFilter: 'blur(0.4px)',
      background: 'rgba(0, 0, 0, 0.5)',
    }}
  >
    <LoaderWrapper>
      <Loader src={loader} alt="loader" />
    </LoaderWrapper>
  </div>
)

Spinner.defaultProps = {
  fullScreen: false,
}

export default Spinner
