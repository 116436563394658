import Cookies from 'js-cookie'
import { useQuery } from 'react-query'
import { useContext, useCallback } from 'react'
import { ToastContext } from 'context/toastContext'
import { toast_actions, toast_types } from 'components/shared/toast/utils/toast'
import axiosHttp from 'utils/axios'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
// import { deleteAllCookies } from 'utils/cookies'

const useGet = (key, url, configs) => {
  const dispatch = useContext(ToastContext)
  const history = useHistory()

  const get = useCallback(async () => {
    const token = Cookies.get('token')
    try {
      const { data } = await axiosHttp.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return data
    } catch (error) {

      if (error.response && error.response.status !== 401) {
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.warning,
            message: error?.response?.data?.message,
          },
        })
      }

      // deleteAllCookies()
      localStorage.clear()
      history.push('/')
      throw error
    }
  }, [dispatch, url])

  const defaultConfig = {
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false,
    ...configs,
  }

  return useQuery(key, get, defaultConfig)
}

export default useGet
