import React from 'react'
import ReactGA from 'react-ga4'
import { useHistory } from 'react-router-dom'
import moment from 'moment/moment'
import Chip from '@mui/material/Chip'
import Button from '@mui/material/Button'
import no_image_found from '../../../assets/images/no_image_found.png'
import useStyles, {
  ComplaintsWrapper,
  Container,
  ImageWrapper,
  ComplaintsDetailWrapper,
  TitleWrapper,
  NameWrapper,
  ComplaintsInfoContainer,
  ComplaintsInfoWrapper,
  ComplaintTitle,
  ComplaintDescription,
  ButtonContainer,
  MobileDetailContainer,
  MobileImageWrapper,
  MobileTitleWrapper,
} from '../../orders/orderCard/style'
import { ISSUE_TYPES } from '../../../constants/issue-types'

const TicketCard = ({ data, orderDetails }) => {
  const classes = useStyles()
  const history = useHistory()
  const { category, sub_category, issueId, issue_status, created_at } = data
  const descriptor = orderDetails?.items[0]?.product?.descriptor
  const allCategory = ISSUE_TYPES.map((item) => {
    return item.subCategory.map((subcategoryItem) => {
      return {
        ...subcategoryItem,
        category: item.value,
      }
    })
  }).flat()

  const newIssueId = issueId.substring(0, 6)

  return (
    <ComplaintsWrapper>
      <Container>
        <MobileDetailContainer>
          <MobileImageWrapper>
            <img
              src={descriptor?.images?.length > 0 ? descriptor?.images[0] : no_image_found}
              alt={`sub-cat-img-${orderDetails?.id}`}
              onError={(e) => {
                e.target.src =
                  'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
              }}
            />
          </MobileImageWrapper>
          <MobileTitleWrapper>
            <NameWrapper>{descriptor?.name}</NameWrapper>
            <Chip className="status-chip" label={issue_status} />
          </MobileTitleWrapper>
        </MobileDetailContainer>
        <ImageWrapper>
          <img
            src={descriptor?.images?.length > 0 ? descriptor?.images[0] : no_image_found}
            onError={(e) => {
              e.target.src =
                'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
            }}
            alt={`sub-cat-img-${orderDetails?.id}`}
          />
        </ImageWrapper>
        <ComplaintsDetailWrapper>
          <TitleWrapper>
            <NameWrapper>{descriptor?.name}</NameWrapper>
            <Chip className="status-chip" color={issue_status === 'Close' ? 'error' : 'success'} label={issue_status} />
          </TitleWrapper>
          <ComplaintsInfoContainer>
            <ComplaintsInfoWrapper>
              <ComplaintTitle>{`${category}: `}</ComplaintTitle>
              <ComplaintDescription>
                {allCategory.find((x) => x.enums === sub_category)?.value ?? 'NA'}
              </ComplaintDescription>
            </ComplaintsInfoWrapper>
            <ComplaintsInfoWrapper>
              <ComplaintTitle>{`Issue Id: `}</ComplaintTitle>
              <ComplaintDescription>{newIssueId}</ComplaintDescription>
            </ComplaintsInfoWrapper>
            <ComplaintsInfoWrapper>
              <ComplaintTitle> {`Issue Raised On: `}</ComplaintTitle>
              <ComplaintDescription>
                {moment(created_at).format('MMMM Do, YYYY')} at {moment(created_at).format('hh:mma')}
              </ComplaintDescription>
            </ComplaintsInfoWrapper>
          </ComplaintsInfoContainer>
        </ComplaintsDetailWrapper>
      </Container>
      <ButtonContainer>
        <Button
          className={classes.viewSummaryButton}
          variant="outlined"
          onClick={() => {
            history.push(`/complaint/${newIssueId}`, { data })
            ReactGA.event({
              category: 'Sidebar',
              action: 'Click',
              label: 'View Complaint Summary',
            })
          }}
        >
          View summary
        </Button>
      </ButtonContainer>
    </ComplaintsWrapper>
  )
}

export default TicketCard
