import { makeStyles } from '@mui/styles'
import palette from '../../../utils/Theme/palette'
import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

const useStyles = makeStyles({
  orderDetailsContainer: {
    padding: '45px 52px',
  },
  breadcrumbWrapper: {
    padding: '16px 52px',
    background: `${theme.TERTIARYBACKGROUNDCOLOR} !important`,
    width: '100%',
  },
  divider: {
    height: '1px',
    backgroundColor: `${theme.PRIMARYDIVIDERCOLOR} !important`,
    marginTop: '20px',
    marginBottom: '20px',
  },
  statusChip: {
    float: 'right',
    background: `${theme.PRIMARYCOLOR} !important`,
    marginRight: '5px !important',
    color: `${theme.SECONDARYTEXTCOLOR} !important`,
    borderRadius: '14px !important',
    fontWeight: '400 !important',
  },
  tagsChip: {
    float: 'right',
    // background: `${theme.PRIMARYCOLOR}`,
    marginRight: '5px !important',
    color: `${theme.PRIMARYCOLOR} !important`,
    borderRadius: '14px !important',
    fontWeight: '400 !important',
  },
  pending: {
    background: `${theme.SECONDARYYELLOW} !important`,
    color: `${theme.PRIMARYDARKYELLOW} !important`,
  },
  agentAssigned: {
    background: `${theme.LIGHTPURPLE} !important`,
    color: `${theme.PURPLE} !important`,
  },
  orderPickedUp: {
    background: `${theme.SECONDARYYELLOW} !important`,
    color: `${theme.PRIMARYDARKYELLOW} !important`,
  },
  outForDelivery: {
    background: `${theme.LIGHTGREEN} !important`,
    color: `${theme.PRIMARYGREEN} !important`,
  },
  orderDelivered: {
    background: `${theme.LIGHTBLUE} !important`,
    color: `${theme.DARKBLUE} !important`,
  },
  cancelled: {
    background: `${theme.ERRORCOLORLIGHT} !important`,
    color: `${theme.SECONDARYERRORCOLOR} !important`,
  },

  marginBottom12: {
    marginBottom: '12px !important',
  },

  marginBottom0: {
    marginBottom: '0px !important',
  },

  orderSummaryCard: {
    borderRadius: '6px !important',
    boxShadow: 'none !important',
    border: `1px solid ${theme.SECONDARYBORDERCOLOR} !important`,
  },
  orderNumberTypo: {
    fontSize: '14px !important',
    fontWeight: '400 !important',
    lineHeight: '20px !important',
  },
  orderNumberTypoBold: {
    fontWeight: '600 !important',
  },

  orderSummaryDivider: {
    height: '1px',
    backgroundColor: `${theme.PRIMARYDIVIDERCOLOR}`,
    marginTop: '16px',
    marginBottom: '26px',
  },
  timelineDot: {
    padding: '0px !important',
    margin: '7px 0px !important',
  },
  timelineIcon: {
    height: '20px !important',
    width: '20px !important',
  },
  dottedConnector: {
    borderLeft: `2px dashed ${theme.TIMELINECONNECTOR} !important`,
    background: `${theme.SECONDARYTEXTCOLOR} !important`,
  },
  timelineContentHeaderTypo: {
    fontSize: '16px !important',
    fontWeight: '500 !important',
  },
  timelineContentHeaderTimeTypo: {
    fontWeight: '500 !important',
    marginLeft: '8px !important',
    color: `${theme.PRIMARYTEXTCOLORDARK}`,
  },

  map: {
    width: '100% !important',
    height: '483px !important',
    borderRadius: '10px !important',
  },

  customerDetails: {
    border: `1px solid ${theme.SECONDARYBORDERCOLOR} !important`,
    borderRadius: '6px',
  },
  customerDetailsTypo: {
    fontWeight: '600 !important',
    fontSize: '18px !important',
    lineHeight: '26px !important',
    color: `${theme.PRIMARYTEXTCOLOR}`,
    background: `${theme.TERTIARYBACKGROUNDCOLOR} !important`,
    padding: '20px !important',
  },
  summaryItemValue: {
    width: '150px !important',
    textAlign: 'right',
    fontSize: '13px !important',
    fontWeight: '600 !important',
  },
  summaryItemLabelDescription: {
    color: `${theme.CUSTOMIZEDCOLOR} !important`,
    marginTop: '2px !important',
    maxWidth: '300px !important',
    flex: 1,
  },
  itemSummaryHeaderContainer: {
    display: 'flex',
    marginBottom: '17px !important',
  },
  itemSummaryHeaderLabel: {
    flex: 1,
    fontWeight: '600 !important',
    color: `${theme.PRIMARYTEXTCOLORDARK}`,
  },
  itemSummaryHeaderValue: {
    width: '150px !important',
    textAlign: 'right',
    fontWeight: '600 !important',
  },
  itemContainer: {
    display: 'flex',
    marginBottom: '17px !important',
  },
  itemLabel: {
    flex: 1,
    fontSize: '14px !important',
    fontWeight: '600 !important',
    color: `${theme.PRIMARYGREYCOLOR} !important`,
  },
  itemValue: {
    width: '150px !important',
    textAlign: 'right',
    color: `${palette.primary.main} !important`,
    fontSize: '14px !important',
    fontWeight: '600 !important',
  },

  customerDetailsLabel: {
    fontSize: '14px !important',
    fontWeight: '400 !important',
    lineHeight: '22px !important',
    marginBottom: '8px !important',
    color: `${theme.PRIMARYGREYCOLOR} !important`,
  },
  customerDetailsValue: {
    fontSize: '14px !important',
    fontWeight: '500 !important',
    lineHeight: '22px !important',
    color: `${theme.PRIMARYTEXTCOLORDARK} !important`,
  },
  downloadInvoiceButton: {
    marginRight: '12px !important',
  },
  summaryItemActionContainer: {
    marginTop: '30px !important',
    display: 'flex',
    gap: '10px !important',
    marginBottom: '17px !important',
  },
  helpButton: {
    flex: 1,
    color: `${theme.PRIMARYCOLOR}`,
    border: `1px solid ${theme.PRIMARYCOLOR} !important`,
  },
  cancelOrderButton: {
    flex: 1,
    background: `${theme.ERROR} !important`,
  },
  customizationValue: {
    width: '100px !important',
    textAlign: 'right',
    color: `${theme.CUSTOMIZEDCOLOR} !important`,
  },
  customizationLabel: {
    color: `${theme.CUSTOMIZEDCOLOR} !important`,
    marginTop: '2px !important',
    flex: 1,
  },
  totalLabel: {
    flex: 1,
    fontSize: '13px !important',
    fontWeight: '600 !important',
    color: `${theme.PRIMARYTEXTCOLORDARK} !important`,
  },
  totalValue: {
    width: '100px !important',
    textAlign: 'right',
    fontWeight: '600 !important',
    fontSize: '16px !important',
    lineHeight: '24px !important',
    color: `${theme.PRIMARYTEXTCOLORDARK} !important`,
  },
  actionButtons: {
    float: 'right',
    marginLeft: '12px !important',
    border: '1px solid rgba(0, 0, 0, 0.22) !important',
  },

  summaryCard: {
    borderRadius: '16px !important',
    background: `${palette.common.white} !important`,
    padding: '16px 20px !important',
  },
  summaryItemContainer: {
    display: 'flex',
    marginBottom: '10px',
  },
  summaryDeliveryItemContainer: {
    display: 'flex',
    marginBottom: '10px',
  },
  summaryQuoteItemContainer: {
    display: 'flex',
    marginBottom: '3px',
  },
  summaryItemLabel: {
    flex: 1,
    fontSize: '13px !important',
    fontWeight: '600 !important',
    marginTop: '20px !important',
  },
  summaryDeliveryLabel: {
    flex: 1,
    fontSize: '13px !important',
    fontWeight: '400 !important',
    color: `${theme.GREYCOLOR} !important`,
  },
  summaryCustomizationLabel: {
    flex: 1,
    color: `${theme.CUSTOMIZEDCOLOR} !important`,
    fontSize: '12px !important',
    fontWeight: '600 !important',
    marginLeft: '20px !important',
  },
  summaryItemPriceLabel: {
    flex: 1,
    fontSize: '12px !important',
    fontWeight: '600 !important',
    marginLeft: '10px !important',
  },
  summaryItemDiscountLabel: {
    flex: 1,
    color: `${theme.SUCCESS}`,
    fontSize: '12px !important',
    fontWeight: '600 !important',
    marginLeft: '10px !important',
  },
  summaryCustomizationDiscountLabel: {
    flex: 1,
    color: `${theme.DISCOUNTCOLOR}`,
    fontSize: '12px !important',
    fontWeight: '600 !important',
    marginLeft: '10px !important',
  },
  summaryItemTaxLabel: {
    flex: 1,
    color: `${theme.ERROR}`,
    fontSize: '12px !important',
    fontWeight: '600 !important',
    marginLeft: '10px !important',
  },
  summaryCustomizationTaxLabel: {
    flex: 1,
    color: `${theme.CUSTOMIZATIONLABELCOLOR}`,
    fontSize: '12px !important',
    fontWeight: '600 !important',
    marginLeft: '30px !important',
  },
  summaryCustomizationPriceLabel: {
    flex: 1,
    color: `${theme.CUSTOMIZEDCOLOR} !important`,
    fontSize: '12px !important',
    fontWeight: '600 !important',
    marginLeft: '30px !important',
  },
  summaryItemPriceValue: {
    width: '100px !important',
    textAlign: 'right',
    fontSize: '12px !important',
    fontWeight: '600 !important',
  },
  summaryCustomizationPriceValue: {
    color: `${theme.CUSTOMIZEDCOLOR} !important`,
    width: '100px !important',
    textAlign: 'right',
    fontSize: '12px !important',
    fontWeight: '600 !important',
  },
  subTotalLabel: {
    flex: 1,
    fontWeight: '600 !important',
  },
  summarySubtotalContainer: {
    display: 'flex',
    marginBottom: '0px !important',
    marginTop: '20px !important',
  },
  subTotalValue: {
    width: '100px !important',
    textAlign: 'right',
    fontWeight: '600 !important',
  },
  orderTotalDivider: {
    height: '1px',
    backgroundColor: `${theme.DIVIDERCOLOR} !important`,
    marginTop: '20px',
    marginBottom: '15px',
  },
  proceedToBuy: {
    marginTop: '8px !important',
  },
  orderSummaryHeading: {
    fontWeight: '600 !important',
    fontSize: '18px !important',
    lineHeight: '26px !important',
    color: `${theme.PRIMARYTEXTCOLOR}`,
    background: `${theme.TERTIARYBACKGROUNDCOLOR} !important`,
    padding: '20px !important',
  },
  detailsContainer: {
    paddingTop: '0 !important',
  },

  '@media (max-width: 600px)': {
    breadcrumbWrapper: {
      padding: '16px',
    },
    detailsContainer: {
      paddingLeft: '0 !important',
      marginBottom: '20px !important',
    },
    orderDetailsContainer: {
      padding: '45px 11px 0 52px !important',
    },
  },
})

export default useStyles

export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-bottom: 1px solid ${theme.DIVIDERCOLOR};
  padding-top: 16px;
`
export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  .disabledButton {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
  .return-order {
    flex: 1;
    background: ${theme.ERROR};
  }
  .return-order:disabled {
    color: ${theme.PRIMARYCOLOR} !important;
    border: 1px solid ${theme.PRIMARYCOLOR} !important;
    opacity: 0.5;
    background-color: ${theme.SECONDARYTEXTCOLOR} !important;
  }
`
export const StatusWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: 100%;
  svg {
    cursor: pointer;
  }
`
export const ProductInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  @media (max-width: 450px) {
    gap: 10px;
  }
`
export const ProductDetailWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
  @media (max-width: 450px) {
    flex-direction: column;
    gap: 0;
  }
`
export const ProductDetailSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
  @media (max-width: 450px) {
    gap: 0;
  }
`
export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const ProductWrapper = styled.div`
  display: flex;
  gap: 5px;
`
export const ProductImage = styled.div`
  margin: 5px 10px;

  img {
    width: 64px;
  }
`
export const ReturnOrderProductsWrapper = styled.div`
  padding: 10px;
`
export const OrderPaymentWrapper = styled.div`
  height: 100%;
  border-radius: 15px;
  border: 1px solid ${theme.PRIMARYDIVIDERCOLOR};
  padding: 10px;
  margin-top: 20px;
`
export const PaymentHeading = styled.h3`
  font-size: 14px;
  font-weight: 500;
  padding: 10px 0;
`
export const PaymentTitle = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: ${theme.GREYCOLOR};
`
export const PaymentValue = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: ${theme.GREYCOLOR};
`
export const TotalValue = styled.p`
  font-size: 13px;
  font-weight: 600;
`
export const PaymentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Tags = styled.div`
  background: gray;
`
export const CancelWrapper = styled.div`
  margin-top: 10px;
`
export const ReturnHeading = styled.h3`
  font-size: 14px;
  font-weight: 500;
  padding: 10px 0;
  color: ${theme.PRIMARYTEXTCOLORDARK};
`
export const ReturnItemsWrapper = styled.div`
  display: flex;
  gap: 5px;
  justify-content: space-between;
  margin-top: 20px;
`

export const ReturnWrapper = styled.div`
  margin-top: 10px;
`
export const ItemContentWrapper = styled.div`
  display: flex;
`
export const ItemTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  color: ${theme.PRIMARYTEXTCOLORDARK};
`
export const ItemQuantity = styled.div`
  display: flex;
`
export const ItemStatusWrapper = styled.div``

export const Container = styled.div`
  margin-top: 140px;
  @media (max-width: 450px) {
    margin-top: 180px;
  }
`
export const WebWrapper = styled.div`
  @media (max-width: 450px) {
    display: none;
  }
`
export const MobileWrapper = styled.div`
  display: none;
  @media (max-width: 450px) {
    display: block;
    margin-top: 40px;
  }
`
export const RefundedAmountValue = styled.div`
  color: ${theme.PRIMARYTEXTCOLORDARK};
  font-size: 13px;
  font-weight: 600;
`
