import * as React from 'react'
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none" {...props}>
    <path
      fill="#B5CAD1"
      d="M9.333 11.667a1.167 1.167 0 0 0 2.334 0H9.333Zm4.667 3.5V14a1.167 1.167 0 0 0-1.167 1.167H14Zm-1.167 1.166a1.167 1.167 0 0 0 2.334 0h-2.334Zm-1.166-4.666c0-.462.136-.913.393-1.297l-1.94-1.296a4.667 4.667 0 0 0-.787 2.593h2.334Zm.393-1.297c.256-.383.62-.682 1.047-.859l-.893-2.156a4.667 4.667 0 0 0-2.094 1.719l1.94 1.296Zm1.047-.859a2.333 2.333 0 0 1 1.348-.133l.455-2.288a4.667 4.667 0 0 0-2.696.265l.893 2.156Zm1.348-.133c.453.09.869.312 1.195.639l1.65-1.65a4.666 4.666 0 0 0-2.39-1.277l-.455 2.288Zm1.195.639c.326.326.548.742.638 1.194l2.289-.455a4.667 4.667 0 0 0-1.277-2.39l-1.65 1.65Zm.638 1.194c.09.453.044.922-.132 1.349l2.155.893a4.666 4.666 0 0 0 .266-2.697l-2.289.455Zm-.132 1.349c-.177.426-.476.79-.86 1.047l1.297 1.94a4.667 4.667 0 0 0 1.718-2.095l-2.155-.892Zm-.86 1.047A2.333 2.333 0 0 1 14 14v2.333c.923 0 1.825-.273 2.593-.786l-1.297-1.94Zm-2.463 1.56v1.166h2.334v-1.166h-2.334ZM23.333 14A9.333 9.333 0 0 1 14 23.333v2.334c6.443 0 11.667-5.224 11.667-11.667h-2.334ZM14 23.333A9.333 9.333 0 0 1 4.667 14H2.333c0 6.443 5.224 11.667 11.667 11.667v-2.334ZM4.667 14A9.333 9.333 0 0 1 14 4.667V2.333C7.557 2.333 2.333 7.557 2.333 14h2.334ZM14 4.667A9.333 9.333 0 0 1 23.333 14h2.334c0-6.443-5.224-11.667-11.667-11.667v2.334Z"
    />
    <circle cx={14} cy={19.833} r={1.167} fill="#B5CAD1" />
  </svg>
)
export default SvgComponent
