import React, { useContext, useEffect, useRef, useState } from 'react'
import ReactGA from 'react-ga4'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import useStyles, { CartContainer } from './styles'
import { Link, useHistory } from 'react-router-dom'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import MuiLink from '@mui/material/Link'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Button, Divider, Drawer, Grid, Typography } from '@mui/material'
import { getCall, getSelectCall, postCheckoutCall, putCall } from '../../../api/axios'
import { AddCookie, getValueFromCookie } from '../../../utils/cookies'
import { constructQouteObject } from '../../../api/utils/constructRequestObject'
import useCancellablePromise from '../../../api/cancelRequest'
import { SSE_TIMEOUT } from '../../../constants/sse-waiting-time'
import { AddressContext } from '../../../context/addressContext'
import { CartContext } from '../../../context/cartContext'
import EditCustomizations from './EditCustomizations'
import { ToastContext } from '../../../context/toastContext'
import { toast_actions, toast_types } from '../../shared/toast/utils/toast'
// import { SearchContext } from '../../../context/searchContext'
import { isLoggedIn } from '../../../utils/validateToken'
import { LocationContext } from '../.././../context/locationContext'
import { emptyCartScreen } from '../cart/empty-cart'
import ModalComponent from '../../common/Modal'
import LoginModal from '../../../views/LoginModal'
import ProductImage from 'assets/images/no_image_found.png'
import {
  HeadSection,
  CartItemMainContainer,
  CartItemContainer,
  CartTableItemsWrapper,
  ButtonWrapper,
  CartSummary,
  WebCartWrapper,
  MobileCartWrapper,
  CartWrapper,
  CartDetailWrapper,
  ProductDetailSection,
  ProductTitleSection,
  QuantityWrapper,
  CartItemTitle,
  TitleContainer,
  CartItemPrice,
  ProviderTitle,
  MobileContent,
  CartItemsDetails,
  ItemDetails,
  PriceWrapper,
  ProductCartWrapper,
  ProductName,
  ProviderName,
  AvailabilityWrapper,
  Wrapper,
} from '../../../styles/searchProducts'
import DeleteLogo from 'assets/svg/DeleteLogo'
import IncreaseQuantity from 'assets/svg/IncreaseQuantity'
import DecreaseQuantity from 'assets/svg/DecreaseQuantity'
import DeleteIcon from '../../../assets/svg/DeleteIcon'
import { formatIndianRupees, generateItemsPayloads, getOrCreateDeviceId, getUserId } from '../../../helper/index'
import Spinner from 'components/Loader'
import usePost from 'hooks/usePost'
import { getErrorMessageFromErrorCode } from 'helper/errorCode'
import ConfirmationModal from 'views/ConfirmationModal'
import { checkPolygonAvailability, checkInterCityHyperLocal } from 'utils/serviceableHelper'

export default function Cart({ showOnlyItems = false, setCheckoutCartItems }) {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useContext(ToastContext)
  const { deliveryAddress } = useContext(AddressContext)
  const { fetchCartItems } = useContext(CartContext)

  // const { locationData: deliveryAddressLocation } = useContext(SearchContext)

  const { latitude, longitude } = useContext(LocationContext)

  const { cancellablePromise } = useCancellablePromise()
  const { mutateAsync } = usePost()

  const getPinCode = localStorage.getItem('pinCode')
  const currentPincode = localStorage.getItem('currentpinCode')

  const responseRef = useRef([])
  const eventTimeOutRef = useRef([])
  const updatedCartItems = useRef([])
  const [loading, setLoading] = useState(false)
  const [cartItems, setCartItems] = useState([])
  // const [transactionData, setTransactionData] = useState()

  // const [haveDistinctProviders, setHaveDistinctProviders] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [productPayload, setProductPayload] = useState(null)
  const [customization_state, setCustomizationState] = useState({})
  const [currentCartItem, setCurrentCartItem] = useState(null)
  const [checkoutLoading, setCheckoutLoading] = useState(false)
  const [loginModal, setLoginModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [cartItemId, setCartItemId] = useState(false)
  const [outoffStock, setOutoffStock] = useState(false)
  const [itemId, setItemId] = useState([])
  const [serviceableCardItemId, setserviceableCardItemId] = useState('')
  const [availablity, setAvailablity] = useState('')

  const checkAvailabilityForCartItems = async (cartItems, latitude, longitude, setserviceableCardItemId) => {
    if (cartItems.length) {
      const providerTags = cartItems
        .flatMap((item) => item?.item?.provider?.tags)
        .filter((tag) => tag?.code === 'serviceability')

      const commonCategoryIds = []
      cartItems.forEach((item) => {
        const categoryId = item?.item?.product?.category_id
        if (
          categoryId &&
          providerTags.some((tag) => tag?.list[2]?.value === '13' && tag?.list[1]?.value === categoryId)
        ) {
          commonCategoryIds.push(categoryId)
          if (!setserviceableCardItemId) return // Exit early if setserviceableCardItemId is not provided
          setserviceableCardItemId(item?._id)
        } else if (
          categoryId &&
          providerTags.some((tag) => tag?.list[2]?.value === '11' && tag?.list[1]?.value === categoryId)
        ) {
          return
        } else if (
          categoryId &&
          providerTags.some((tag) => tag?.list[2]?.value === '10' && tag?.list[1]?.value === categoryId)
        ) {
          const checkAva = checkInterCityHyperLocal(cartItems, categoryId, '10', latitude, longitude)
          // const checkAva = cartItems.forEach((ele) => {
          //   checkInterCityHyperLocal(ele.item, categoryId, '10', latitude, longitude)
          // })

          setAvailablity(checkAva)
          return
        } else {
          //Pan India local 12
          return
        }
      })

      await Promise.all(
        commonCategoryIds.map(async (categoryId) => {
          const checkAvailability = await checkPolygonAvailability(providerTags, categoryId, [latitude, longitude])
          setAvailablity(checkAvailability)
        }),
      )
    }
  }

  useEffect(() => {
    checkAvailabilityForCartItems(cartItems, latitude, longitude, setserviceableCardItemId)
  }, [cartItems, latitude, longitude, setserviceableCardItemId])

  const getCartSubtotal = () => {
    let subtotal = 0
    if (Array.isArray(cartItems)) {
      cartItems?.forEach((cartItem) => {
        const quantity = cartItem?.item?.quantity?.count || 0
        if (cartItem.item.hasCustomisations) {
          subtotal += parseFloat(getPriceWithCustomisations(cartItem)) * quantity
        } else {
          subtotal += parseFloat(cartItem?.item?.product?.subtotal) * quantity
        }
      })
    }

    const total = formatIndianRupees(subtotal.toFixed(2))
    // Round off the subtotal to two digits after the decimal point
    return total
  }

  // const checkDistinctProviders = () => {
  //   if (cartItems?.length < 2) {
  //     setHaveDistinctProviders(false)
  //   } else {
  //     const firstProvider = cartItems[0]?.item.provider?.id
  //     let haveDifferentProvider = false

  //     for (let i = 1; i < cartItems?.length; i++) {
  //       if (cartItems[i].item.provider?.id !== firstProvider) {
  //         haveDifferentProvider = true
  //         break
  //       }
  //     }

  //     setHaveDistinctProviders(haveDifferentProvider)
  //   }
  // }

  const getCartItems = async () => {
    const userId = getUserId()
    const deviceId = await getOrCreateDeviceId()

    setLoading(true)

    try {
      const url = `/clientApis/v2/cart/${userId}/${deviceId}`
      const res = await getCall(url)
      // extractTransactionIds(res)
      setCartItems(res)

      localStorage.setItem('cartItems', JSON.stringify(res))
      updatedCartItems.current = res
      if (setCheckoutCartItems) {
        setCheckoutCartItems(res)
      }

      setLoading(false)
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: error?.response?.data?.error?.message,
        },
      })
      setLoading(false)
    }
  }

  const deleteCartItem = async (itemId) => {
    setCartItemId(itemId)
    setDeleteModal(true)
  }

  const getProductDetails = async (productId) => {
    try {
      const data = await cancellablePromise(
        getCall(`${process.env.REACT_APP_BASE_PROTOCOL_URL}/protocol/item-details?id=${productId}`),
      )
      setProductPayload(data)
      return data
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: 'Cant able to fetch Product Details',
        },
      })
    }
  }

  useEffect(() => {
    getCartItems()
  }, [cartItems?.length])

  // useEffect(() => {
  //   getCartItems()
  //   checkDistinctProviders()
  //   checkDifferentCategory()
  // }, [openDrawer, cartItems?.length])

  // const checkDifferentCategory = () => {
  //   const everyEnvHasSameValue = cartItems?.every(({ item }) => item.domain === cartItems[0]?.item.domain) // use proper name
  //   setIsProductCategoryIsDifferent(!everyEnvHasSameValue)
  // }

  // useEffect(() => {
  //   checkDistinctProviders()
  //   checkDifferentCategory()
  // }, [deliveryAddressLocation])

  // function to dispatch error
  function dispatchError(message) {
    dispatch({
      type: toast_actions.ADD_TOAST,
      payload: {
        id: Math.floor(Math.random() * 100),
        type: toast_types.error,
        message,
      },
    })
  }

  const updateCartItem = async (cartId, increment, uniqueId) => {
    const userId = getUserId()
    const url = `/clientApis/v2/cart/${userId}/${uniqueId}`
    const items = [...cartItems] // Use spread operator to create a new array

    const itemIndex = items.findIndex((item) => item._id === uniqueId)
    if (itemIndex !== -1) {
      let updatedCartItem = { ...items[itemIndex] } // Create a copy of the item
      const { item } = updatedCartItem

      if (cartId !== null) {
        if (increment) {
          const productMaxQuantity = item?.product?.quantity?.maximum
          if (productMaxQuantity && item.quantity.count < productMaxQuantity.count) {
            item.quantity.count += 1
            updatedCartItem.item = item
            await putCall(url, updatedCartItem.item)
            // setLoading(false)
            // getCartItems()
            fetchCartItems()
          } else {
            dispatchError(`Maximum allowed quantity is ${productMaxQuantity.count}`)
          }
        } else {
          if (item.quantity.count > 1) {
            item.quantity.count -= 1
            updatedCartItem.item = item

            await putCall(url, updatedCartItem.item)
            // setLoading(false)
            // getCartItems()
            fetchCartItems()
          }
        }
      }
    }
  }

  const renderTableHeads = () => {
    return (
      <CartTableItemsWrapper>
        <Grid item container xs={14} gap="10px">
          <Grid item xs={4.3}>
            <Typography variant="body1" className={classes.tableHead}>
              Products
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1" className={classes.tableHead} sx={{ marginLeft: '6px' }}>
              Price
            </Typography>
          </Grid>
          <Grid item xs={1.2}>
            <Typography variant="body1" className={classes.tableHead} sx={{ marginLeft: '12px' }}>
              Qty
            </Typography>
          </Grid>
          <Grid item xs={1.4}>
            <Typography variant="body1" className={classes.tableHead}>
              Subtotal
            </Typography>
          </Grid>
        </Grid>
      </CartTableItemsWrapper>
    )
  }

  const getCustomizations = (cartItem) => {
    if (cartItem?.item.customisations) {
      const customisations = cartItem?.item.customisations

      return customisations?.map((c, idx) => {
        const isLastItem = idx === customisations.length - 1
        const price = parseFloat(c.item_details.price.value).toFixed(2) // Parse and format price to two decimal places
        return (
          <Grid container key={idx}>
            <Typography variant="subtitle1" color="#686868">
              {c.item_details.descriptor.name} (₹{price}) {isLastItem ? '' : '+'}
            </Typography>
          </Grid>
        )
      })
    }

    return null
  }

  const getPriceWithCustomisations = (cartItem) => {
    let basePrice = parseFloat(cartItem?.item?.product?.price?.value) || 0 // Parse base price as float
    let price = 0

    // Calculate total price of customisations
    cartItem?.item?.customisations?.forEach((c) => {
      price += parseFloat(c.item_details.price.value) || 0 // Parse customisation prices as float
    })

    // Calculate total price including base price and customisation prices
    let totalPrice = basePrice + price

    // Round off the total price to two decimal places
    return totalPrice.toFixed(2)
  }

  const renderVegNonVegTag = (cartItem) => {
    const FnB = 'ONDC:RET11'
    const grocery = 'ONDC:RET10'

    if (cartItem?.item?.domain == grocery || cartItem?.item?.domain == FnB) {
      const tags = cartItem?.item?.product?.tags
      let category = 'veg'

      for (let i = 0; i < tags.length; i++) {
        if (tags[i].code === 'veg_nonveg') {
          const vegNonVegValue = tags[i].list[0]?.value

          if (vegNonVegValue === 'yes' || vegNonVegValue === 'Yes') {
            category = 'veg'
          } else if (vegNonVegValue === 'no') {
            category = 'nonveg'
          } else if (vegNonVegValue === 'egg') {
            category = 'egg'
          }
        }
      }

      const getTagColor = () => {
        if (category === 'veg') {
          return '#008001'
        } else if (category == 'nonveg') {
          return 'red'
        } else {
          return '#008001'
        }
      }

      return (
        <Grid container alignItems="center" className={classes.tagContainer}>
          <div className={classes.square} style={{ borderColor: getTagColor() }}>
            <div className={classes.circle} style={{ backgroundColor: getTagColor() }}></div>
          </div>
        </Grid>
      )
    }

    return null
  }

  const renderProducts = () => {
    return cartItems?.map((cartItem) => {
      return (
        <Grid key={cartItem._id} style={{ width: '100%' }}>
          <WebCartWrapper>
            <Grid
              container
              key={cartItem?.item?.id}
              style={{ display: 'inline-flex', alignItems: 'flex-start', gap: '10px', flexWrap: 'nowrap' }}
            >
              <Grid item xs={4.3}>
                <ProductCartWrapper>
                  <div className={classes.moreImages}>
                    <div className={classes.greyContainer}>
                      <img
                        className={classes.moreImage}
                        alt="product-image"
                        src={
                          cartItem?.item?.product?.descriptor?.symbol
                            ? cartItem?.item?.product?.descriptor?.symbol
                            : ProductImage
                        }
                        onError={(e) => {
                          e.target.src =
                            'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                        }}
                        onClick={() => history.push(`/products?productId=${cartItem?.item?.id}`)}
                      />
                      {renderVegNonVegTag(cartItem)}
                    </div>
                  </div>
                  <Grid sx={{ maxWidth: '200px' }}>
                    <ProductName>{cartItem?.item?.product?.descriptor?.name}</ProductName>

                    {getCustomizations(cartItem)}
                    {cartItem?.item?.hasCustomisations && (
                      <Grid
                        container
                        sx={{
                          marginTop: '4px',
                          width: 'max-content',
                          cursor: 'pointer',
                        }}
                        alignItems="center"
                        onClick={() => {
                          setCustomizationState(cartItem?.item?.customisationState)
                          getProductDetails(cartItem?.item?.id)
                          setCurrentCartItem(cartItem)
                          setOpenDrawer(true)
                        }}
                      >
                        <EditOutlinedIcon
                          size="small"
                          sx={{
                            color: '#115469',
                            fontSize: 16,
                            marginRight: '5px',
                          }}
                        />
                        <div>Customise</div>
                      </Grid>
                    )}
                    <Wrapper>
                      <div className={classes.logoContainer}>
                        <img
                          // className={classes.logo}
                          alt={'store-logo'}
                          src={
                            cartItem?.item?.provider?.descriptor?.symbol
                              ? cartItem?.item?.provider?.descriptor?.symbol
                              : ProductImage
                          }
                          onError={(e) => {
                            e.target.src =
                              'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                          }}
                        />
                      </div>
                      <ProviderName>{cartItem?.item?.provider?.descriptor?.name}</ProviderName>
                      {serviceableCardItemId === cartItem._id && availablity && (
                        <AvailabilityWrapper>{availablity}</AvailabilityWrapper>
                      )}
                    </Wrapper>
                  </Grid>
                </ProductCartWrapper>
              </Grid>
              <Grid item xs={1}>
                <PriceWrapper>
                  {cartItem.item.hasCustomisations
                    ? `₹ ${formatIndianRupees(getPriceWithCustomisations(cartItem))}`
                    : `₹ ${formatIndianRupees(parseFloat(cartItem?.item?.product?.price?.value).toFixed(2))}`}
                </PriceWrapper>
              </Grid>

              <Grid item xs={1.2}>
                <div className={classes.qtyContainer}>
                  <Typography variant="body1" sx={{ marginRight: '12px', fontWeight: 600 }}>
                    {cartItem?.item?.quantity?.count}
                  </Typography>
                  <KeyboardArrowUpIcon
                    className={classes.qtyArrowUp}
                    onClick={() => {
                      updateCartItem(cartItem?.item?.id, true, cartItem._id)
                    }}
                  />
                  <KeyboardArrowDownIcon
                    className={classes.qtyArrowDown}
                    onClick={() => updateCartItem(cartItem?.item?.id, false, cartItem._id)}
                  />
                </div>
              </Grid>

              <Grid item xs={1.4}>
                <Typography variant="body" sx={{ fontWeight: 500, minWidth: 50 }}>
                  ₹
                  {formatIndianRupees(
                    (cartItem?.item?.product?.price?.value * cartItem?.item?.quantity?.count).toFixed(2),
                  )}
                </Typography>
              </Grid>
              <div onClick={() => deleteCartItem(cartItem._id)} style={{ cursor: 'pointer' }}>
                <DeleteIcon />
              </div>
            </Grid>
            {((cartItem?.item?.quantity?.count > cartItem?.item.product?.quantity?.available?.count &&
              itemId.find((id) => id === cartItem?.item?.product?.id)) ||
              (outoffStock && itemId.find((id) => id === cartItem?.item?.product?.id))) && (
              // (outoffStock && cartItem?.item?.product?.id === itemId)) && (
              <Grid>
                <div className={classes.infoBox}>
                  <Typography className={classes.infoText}>out of stock</Typography>
                </div>
              </Grid>
            )}

            <Divider sx={{ borderColor: '#616161', margin: '20px 0', width: '98.5%' }} />
          </WebCartWrapper>
          <MobileCartWrapper>
            <CartWrapper>
              <Grid>
                <div className={classes.moreImages}>
                  <div className={classes.greyContainer}>
                    <img
                      className={classes.moreImage}
                      alt="product-image"
                      src={
                        cartItem?.item?.product?.descriptor?.symbol
                          ? cartItem?.item?.product?.descriptor?.symbol
                          : ProductImage
                      }
                      onError={(e) => {
                        e.target.src =
                          'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                      }}
                      onClick={() => history.push(`/products?productId=${cartItem.item.id}`)}
                    />
                    {renderVegNonVegTag(cartItem)}
                  </div>
                </div>
                <MobileContent>
                  {((cartItem?.item?.quantity?.count > cartItem?.item.product?.quantity?.available?.count &&
                    itemId.find((id) => id === cartItem?.item?.product?.id)) ||
                    (outoffStock && itemId.find((id) => id === cartItem?.item?.product?.id))) && (
                    <Grid>
                      <div className={classes.infoBoxMobile}>
                        <Typography className={classes.infoText}>out of stock</Typography>
                      </div>
                    </Grid>
                  )}
                </MobileContent>
              </Grid>

              <CartDetailWrapper>
                <ProductDetailSection>
                  <ProductTitleSection>
                    <CartItemTitle>{cartItem?.item?.product?.descriptor?.name}</CartItemTitle>
                    <div onClick={() => deleteCartItem(cartItem._id)} style={{ cursor: 'pointer', textAlign: 'end' }}>
                      <DeleteLogo />
                    </div>
                  </ProductTitleSection>
                  {getCustomizations(cartItem)}
                  {cartItem.item.hasCustomisations && (
                    <Grid
                      container
                      sx={{
                        marginTop: '4px',
                        width: 'max-content',
                        cursor: 'pointer',
                      }}
                      alignItems="center"
                      onClick={() => {
                        setCustomizationState(cartItem?.item.customisationState)
                        getProductDetails(cartItem?.item.id)
                        setCurrentCartItem(cartItem)
                        setOpenDrawer(true)
                      }}
                    >
                      <EditOutlinedIcon
                        size="small"
                        sx={{
                          color: '#115469',
                          fontSize: 16,
                          marginRight: '5px',
                        }}
                      />
                      <div>Customize</div>
                    </Grid>
                  )}

                  <TitleContainer>
                    <CartItemPrice variant="body" sx={{ fontWeight: 500 }}>
                      {cartItem?.item.hasCustomisations
                        ? `₹ ${getPriceWithCustomisations(cartItem)}`
                        : `₹ ${cartItem?.item?.product?.price?.value.toFixed(2)}`}
                    </CartItemPrice>
                    <ProviderTitle>{cartItem?.item?.provider?.descriptor?.name}</ProviderTitle>
                  </TitleContainer>
                </ProductDetailSection>

                <ItemDetails>
                  <QuantityWrapper>
                    <div onClick={() => updateCartItem(cartItem?.item?.id, false, cartItem._id)}>
                      <DecreaseQuantity />
                    </div>
                    <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '12px' }}>
                      {cartItem?.item?.quantity?.count}
                    </Typography>
                    <div
                      onClick={() => {
                        updateCartItem(cartItem?.item?.id, true, cartItem._id)
                      }}
                    >
                      <IncreaseQuantity />
                    </div>
                  </QuantityWrapper>
                  <Grid item xs={1}>
                    <Grid item xs={1}>
                      <Typography variant="body" sx={{ fontWeight: 500, fontSize: '12px' }}>
                        ₹{(cartItem?.item?.product?.price?.value * cartItem?.item?.quantity?.count).toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>
                </ItemDetails>
              </CartDetailWrapper>
            </CartWrapper>
          </MobileCartWrapper>
        </Grid>
      )
    })
  }

  const renderProductsForCheckoutPage = () => {
    return cartItems?.map((cartItem) => {
      return (
        <Grid key={cartItem._id}>
          <Grid
            container
            key={cartItem?.item?.id || 0}
            style={{ alignItems: 'flex-start', display: 'grid', gridTemplateColumns: 'auto auto' }}
          >
            <Grid item xs={5.5}>
              <Grid container>
                <div className={classes.moreImages}>
                  <div className={classes.greyContainer}>
                    <img
                      className={classes.moreImage}
                      alt="product-image"
                      src={
                        cartItem?.item?.product?.descriptor?.symbol
                          ? cartItem?.item?.product?.descriptor?.symbol
                          : ProductImage
                      }
                      onError={(e) => {
                        e.target.src =
                          'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                      }}
                      onClick={() => history.push(`/products?productId=${cartItem?.item?.id}`)}
                    />
                    {renderVegNonVegTag(cartItem)}
                  </div>
                </div>
                <Grid sx={{ maxWidth: '200px' }}>
                  <Typography variant="body1" sx={{ width: 200, fontWeight: 600 }}>
                    {cartItem?.item?.product?.descriptor?.name}
                  </Typography>
                  {getCustomizations(cartItem)}
                  {cartItem?.item?.hasCustomisations && (
                    <Grid
                      container
                      sx={{
                        marginTop: '4px',
                        width: 'max-content',
                        cursor: 'pointer',
                      }}
                      alignItems="center"
                      onClick={() => {
                        setCustomizationState(cartItem?.item.customisationState)
                        getProductDetails(cartItem?.item.id)
                        setCurrentCartItem(cartItem)
                        setOpenDrawer(true)
                      }}
                    >
                      <EditOutlinedIcon
                        size="small"
                        sx={{
                          color: '#196AAB',
                          fontSize: 16,
                          marginRight: '5px',
                        }}
                      />
                      <Typography variant="subtitle1" color="#196AAB">
                        Customise
                      </Typography>
                    </Grid>
                  )}
                  <Grid container sx={{ marginTop: '4px' }} alignItems="center">
                    <Typography variant="subtitle1" color="#686868" sx={{ fontWeight: 500 }}>
                      {cartItem?.item?.provider?.descriptor?.name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={1.5}>
              <div className={classes.qtyContainer}>
                <Typography variant="body1" sx={{ fontWeight: 600 }}>
                  Qty: {cartItem?.item?.quantity?.count}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={1.4}>
              <Typography sx={{ fontWeight: 600 }} style={{ width: 'max-content' }}>
                {cartItem.item.hasCustomisations
                  ? `₹ ${parseInt(getPriceWithCustomisations(cartItem)) * parseInt(cartItem?.item?.quantity?.count)}`
                  : `₹ ${parseInt(cartItem?.item?.product?.subtotal)}`}
              </Typography>
            </Grid>
            <Grid item xs={1.3}>
              <div style={{ marginTop: '5px' }}>
                <Button
                  variant="text"
                  startIcon={<DeleteOutlineIcon size="small" />}
                  color="error"
                  onClick={() => deleteCartItem(cartItem._id)}
                >
                  <Typography>Delete</Typography>
                </Button>
              </div>
            </Grid>
          </Grid>
          {cartItem?.item?.quantity?.count > cartItem?.item?.product?.quantity.available?.count && (
            <Grid>
              <div className={classes.infoBox}>
                <Typography className={classes.infoText}>
                  Only {cartItem?.item?.product?.quantity?.available?.count} available instead of{' '}
                  {cartItem?.item?.quantity?.count}. Update the quantity or switch to another provider.
                </Typography>
              </div>
            </Grid>
          )}
          {/* {idx === cartItems?.length - 1 && haveDistinctProviders && (
            <Grid>
              <div className={classes.infoBox} style={{ background: '#FAE1E1', width: '98.5%' }}>
                <Typography className={classes.infoText} style={{ color: '#D83232', textAlign: 'center' }}>
                  You are ordering from different store. Please check your order again.
                </Typography>
              </div>
            </Grid>
          )}
          {idx === cartItems?.length - 1 && isProductCategoryIsDifferent && (
            <Grid>
              <div className={classes.infoBox} style={{ background: '#FAE1E1', width: '98.5%' }}>
                <Typography className={classes.infoText} style={{ color: '#D83232', textAlign: 'center' }}>
                  You are ordering from different category. Please check your order again.
                </Typography>
              </div>
            </Grid>
          )} */}
          {cartItems?.length > 1 && <Divider sx={{ borderColor: '#616161', margin: '20px 0', width: '98.5%' }} />}
        </Grid>
      )
    })
  }

  const renderSummaryCard = () => {
    return (
      <CartSummary>
        <ButtonWrapper>
          <Typography variant="h6" className={classes.summaryTypo}>
            Cart Total
          </Typography>
          <Divider sx={{ borderColor: '#616161', margin: '10px 0' }} />
          <Grid container justifyContent="space-between" sx={{ marginBottom: '14px' }}>
            <Typography variant="subtitle1" className={classes.summaryLabel}>
              Total
            </Typography>
            <Typography variant="subtitle1" className={classes.summaryLabel}>
              ₹{getCartSubtotal()}
            </Typography>
          </Grid>

          <Button
            variant="contained"
            sx={{ marginTop: 1, marginBottom: 2 }}
            // disabled={outoffStock}
            onClick={() => {
              if (cartItems?.length > 0) {
                let c = cartItems?.map((item) => {
                  return item.item
                })

                if (!isLoggedIn()) {
                  setLoginModal(true)
                  return
                }

                const request_object = constructQouteObject(c)
                getQuote(request_object)
                getProviderIds(request_object)
              }

              ReactGA.event({
                category: 'Button',
                action: 'Click',
                label: 'Proceed to Checkout',
              })
            }}
          >
            {checkoutLoading && <Spinner />}
            Proceed to Checkout
          </Button>
        </ButtonWrapper>
      </CartSummary>
    )
  }

  const getProviderIds = (request_object) => {
    let providers = []
    request_object?.forEach((cartItem) => {
      cartItem.forEach((ele) => {
        providers.push(ele?.provider.local_id)
      })
    })

    const ids = [...new Set(providers)]
    AddCookie('providerIds', ids)
    return ids
  }

  const getQuote = async (items) => {
    responseRef.current = []

    try {
      setCheckoutLoading(true)
      const userId = getUserId()
      const multiSelleSelectPayload = items.map((ele) => {
        const data = generateItemsPayloads(ele)

        return {
          context: {
            domain: data?.domain,
            city: getPinCode ? getPinCode : currentPincode,
          },
          message: {
            cart: {
              items: data?.itemsPayloads,
            },
            fulfillments: [
              {
                end: {
                  location: {
                    gps: `${latitude},${longitude}`,
                    address: {
                      area_code: `${getPinCode ? getPinCode : currentPincode ? currentPincode : deliveryAddress?.address?.areaCode}`,
                    },
                  },
                },
              },
            ],
          },
          userId: userId,
        }
      })
      // const cartOrdersInfoMap = new Map()
      // multiSelleSelectPayload.forEach((ele) => {
      //   const provider_id = ele?.message?.cart?.items[0]?.provider?.id
      //   if (provider_id) {
      //     cartOrdersInfoMap.set(provider_id, {
      //       transaction_id: ele?.context?.transaction_id,
      //     })
      //   }
      // })

      // AddCookie('orders-cart-info', JSON.stringify(Array.from(cartOrdersInfoMap.entries())))
      const data = await cancellablePromise(postCheckoutCall('/clientApis/v2/select', multiSelleSelectPayload))

      const messageId = data?.map((txn) => {
        const { context } = txn
        return context?.message_id
      })

      //Error handling workflow eg, NACK
      // const isNACK = data.find((item) => item.error && item?.message?.ack?.status === 'NACK')
      const isNACK = data.find((item) => item?.message?.ack?.status === 'NACK' || item.error)
      const nackSeller = data?.map((item) => {
        return item?.message?.provider_name
      })
      const nackSellerString = nackSeller.join(' ')

      if (isNACK) {
        setCheckoutLoading(false)
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message: nackSeller
              ? ` ${nackSellerString} Seller is not reachable to accept the orders. Please try again later.`
              : 'The Seller is not reachable to accept the orders. Please try again later. Thank you for being patience.',
          },
        })
      } else {
        // fetch through events
        onFetchQuote(messageId, items?.length)
      }
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.warning,
          message: 'The seller is not reachable at the moment. Please try again later. Thank you for your patience.',
        },
      })

      setCheckoutLoading(false)
    }
  }

  function onFetchQuote(message_id, itemLength) {
    // setCheckoutLoading(true)
    eventTimeOutRef.current = []

    const token = getValueFromCookie('token')
    let header = {
      headers: {
        ...(token && {
          Authorization: `Bearer ${token}`,
        }),
      },
    }

    if (message_id) {
      message_id.forEach((id) => {
        let es = new window.EventSourcePolyfill(
          `${process.env.REACT_APP_BASE_URL}clientApis/events/v2?messageId=${id}`,
          header,
        )

        es.addEventListener('on_select', (e) => {
          const { messageId } = JSON.parse(e.data)

          onGetQuote(messageId, itemLength)

          // Close the event connection as soon as the response is received
          es.close()
          clearTimeout(eventTimeOutRef.current.find((item) => item.eventSource === es).timer)
          eventTimeOutRef.current = eventTimeOutRef.current.filter((item) => item.eventSource !== es)
        })

        const timer = setTimeout(() => {
          eventTimeOutRef.current.forEach(({ eventSource, timer }) => {
            eventSource.close()
            clearTimeout(timer)
          })

          if (responseRef.current.length < itemLength) {
            setCheckoutLoading(false)
            dispatch({
              type: toast_actions.ADD_TOAST,
              payload: {
                id: Math.floor(Math.random() * 100),
                type: toast_types.warning,
                message:
                  'The seller is not reachable at the moment. Please try again later. Thank you for your patience.',
              },
            })
            history.replace('/products')
            return
          }

          let c = cartItems?.map((item) => {
            return item.item
          })
          const request_object = constructQouteObject(c)
          if (responseRef.current.length !== request_object.length) {
            dispatch({
              type: toast_actions.ADD_TOAST,
              payload: {
                id: Math.floor(Math.random() * 100),
                type: toast_types.error,
                message: 'Cannot fetch details for some products; those products will be ignored!',
              },
            })
          }
        }, SSE_TIMEOUT)

        eventTimeOutRef.current = [
          ...eventTimeOutRef.current,
          {
            eventSource: es,
            timer,
          },
        ]
      })
    }
  }

  // const [totalErrorCount, setTotalErrorCount] = useState(0)

  const onGetQuote = async (message_id, itemLength) => {
    try {
      let errCount = []
      setCheckoutLoading(true)
      const data = await cancellablePromise(getSelectCall(`/clientApis/v2/on_select?messageIds=${message_id}`))

      responseRef.current = [...responseRef.current, data[0]]
      // const itemId = data[0]?.message?.quote?.items[0]?.id

      let availableCount = data[0]?.message?.quote?.quote?.breakup[0]?.item?.quantity?.available?.count
      let count = data[0]?.message?.quote?.quote?.breakup[0]?.['@ondc/org/item_quantity']?.count

      if (availableCount < count) {
        setOutoffStock(true)
      }

      if (availableCount !== '99') {
        setOutoffStock(true)
      } else {
        setOutoffStock(false)
      }

      if (data[0]?.error) {
        const err = data[0]?.error
        errCount.push(err)

        // setCheckoutLoading(false)

        const errMessage = getErrorMessageFromErrorCode(data[0]?.error?.code)
        errCount.push(errMessage)

        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message: errMessage, // Use the error message from the API response
          },
        })

        // setTotalErrorCount((prevCount) => prevCount + errCount.length)

        // Return from the function or take any other necessary action
        // return
      }

      data[0]?.message.quote.items.forEach((item) => {
        const findItemIndexFromCart = updatedCartItems.current.findIndex((prod) => prod.item.product.id === item.id)
        if (findItemIndexFromCart > -1) {
          updatedCartItems.current[findItemIndexFromCart].item.product.fulfillment_id = item.fulfillment_id
          updatedCartItems.current[findItemIndexFromCart].item.product.fulfillments = data[0].message.quote.fulfillments
        }
      })

      localStorage.setItem('cartItems', JSON.stringify(updatedCartItems.current))
      localStorage.setItem('updatedCartItems', JSON.stringify(responseRef.current))

      const findItemIdsWithErrorCode = (data, errorCode) => {
        const itemIdsWithErrors = []

        data.forEach((entry) => {
          if (entry.error && entry.error.code === errorCode) {
            // Parse the JSON string in the message field

            const messageArray = JSON.parse(entry?.error?.message)
            messageArray.forEach((item) => {
              if (item.error === errorCode) {
                itemIdsWithErrors.push(item.item_id)
              }
            })
          }
        })

        setItemId(itemIdsWithErrors)
        return itemIdsWithErrors
      }

      const errorCode = '40002'
      const errorId = findItemIdsWithErrorCode(responseRef.current, errorCode)
      setItemId(errorId)
      const hasError = responseRef.current
        .map((item) => {
          const err = item?.error?.code
          if (err === '40002') return err
          return null // Explicitly return null if there's no error code '40002'
        })
        .filter((err) => err !== null) // Filter out null values

      if (hasError.length >= 1) {
        setOutoffStock(true)
      }

      if (itemLength === responseRef.current.length) setCheckoutLoading(false)
      if (itemLength === responseRef.current.length && hasError.length === 0) {
        setCheckoutLoading(false)
        history.push(`/checkout`)
        // setErrorLength([])
      } else {
        history.push(`/cart`)
        // setCheckoutLoading(false)
      }
    } catch (err) {
      setCheckoutLoading(false)
      // setTotalErrorCount((prevCount) => prevCount + 1)
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err.message,
        },
      })
      history.push(`/cart`)
    }
  }

  const handleNew = async () => {
    let userId
    let userEmail
    const userCookie = getValueFromCookie('user')
    if (userCookie) {
      const user = JSON.parse(userCookie)
      userId = user.id
      userEmail = user.email
    }

    const deviceId = await getOrCreateDeviceId()
    // const customCartId = localStorage.getItem('custom_cart_id')
    // wishlist_key: customWishlistId || '',

    const payload = {
      userId: userId || '',
      // cart_key: customCartId || '',
      // wishlist_key: customWishlistId || '',
      email: userEmail || '',
      deviceId: deviceId || '',
    }
    try {
      await mutateAsync({
        url: '/clientApis/userProfile',
        payload: payload,
      })
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err?.response?.data?.error?.message,
        },
      })
    }
  }

  const handleLoginSuccess = () => {
    if (cartItems?.length > 0) {
      let c = cartItems?.map((item) => {
        return item.item
      })
      const request_object = constructQouteObject(c)

      getQuote(request_object)
      getProviderIds(request_object)
      handleNew()
    }
  }

  return (
    <div>
      <HeadSection>
        <Breadcrumbs aria-label="breadcrumb">
          <MuiLink component={Link} underline="hover" color="inherit" to="/">
            <Typography className="text-color">Home</Typography>
          </MuiLink>
          <Typography className="title-color ">Shopping Cart</Typography>
        </Breadcrumbs>
      </HeadSection>
      <CartItemMainContainer>
        {loading ? (
          <div className={classes.loadingContainer}>
            <Spinner />
          </div>
        ) : (
          <>
            {cartItems?.length === 0 ? (
              emptyCartScreen()
            ) : (
              <div>
                {!showOnlyItems ? (
                  <CartContainer>
                    <CartItemContainer>
                      {!showOnlyItems && (
                        <div className={{ display: 'flex' }}>
                          <div className={`${classes.headingContainer}`}>
                            <Typography  className="label-color">
                              Shopping Cart
                            </Typography>
                          </div>
                        </div>
                      )}
                      <Grid item xs={8} className="mainContainer">
                        {renderTableHeads()}
                        <CartItemsDetails>{renderProducts()}</CartItemsDetails>
                      </Grid>
                    </CartItemContainer>
                    <Grid item xs={4}>
                      {renderSummaryCard()}
                    </Grid>
                  </CartContainer>
                ) : (
                  <div>
                    <div>{renderProductsForCheckoutPage()}</div>
                  </div>
                )}
                <Drawer
                  anchor={'right'}
                  open={openDrawer}
                  onClose={() => {
                    setProductPayload(null)
                    setCustomizationState({})
                    setOpenDrawer(false)
                  }}
                >
                  <EditCustomizations
                    cartItems={cartItems}
                    productPayload={productPayload}
                    setProductPayload={setProductPayload}
                    customization_state={customization_state}
                    setCustomizationState={setCustomizationState}
                    setOpenDrawer={setOpenDrawer}
                    currentCartItem={currentCartItem}
                  />
                </Drawer>
              </div>
            )}
          </>
        )}
      </CartItemMainContainer>

      {deleteModal && (
        <ModalComponent
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
          title={'Remove Product'}
          titleBg={true}
        >
          <ConfirmationModal
            onClose={() => setDeleteModal(false)}
            id={cartItemId}
            setCartItems={setCartItems}
            cartItems={cartItems}
            fetchDeliveryAddress={fetchCartItems}
            title={'Are you sure want to remove product from your cart ? '}
            subTitle={' You could also save it to Wishlist.'}
            type={'cartItem'}
          />
        </ModalComponent>
      )}
      {loginModal && (
        <ModalComponent open={loginModal} onClose={() => setLoginModal(false)}>
          <LoginModal onClose={() => setLoginModal(false)} onLoginSuccess={handleLoginSuccess} />
        </ModalComponent>
      )}
    </div>
  )
}
