import React, { useContext, useEffect, useState } from 'react'
import useStyles, { CategoryContainer, BreadcrumbWrapper } from './style'
import { Link, useLocation, useHistory } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import MuiLink from '@mui/material/Link'
import Pagination from '@mui/material/Pagination'
import useCancellablePromise from '../../../api/cancelRequest'
import { getAllProductRequest, getAllFiltersRequest, getAllFilterValuesRequest } from '../../../api/product.api'
import { getCall, postCall } from '../../../api/axios'
import { CartContext } from '../../../context/cartContext'
import { ToastContext } from '../../../context/toastContext'
import { toast_actions, toast_types } from '../../shared/toast/utils/toast'
import { SearchContext } from '../../../context/searchContext'
import SearchProducts from '../../SearchProducts/searchProducts'
// import { GridListViewWrapper } from '../../../styles/home'
import { getOrCreateDeviceId, getUserId } from '../../../helper'
import Spinner from 'components/Loader'
import { useTheme } from '../../../context/newThemeContext'

const Products = ({ brandDetails, brandId }) => {
  const classes = useStyles()
  const history = useHistory()
  const { fetchCartItems } = useContext(CartContext)
  const { locationData: deliveryAddressLocation } = useContext(SearchContext)
  // const [viewType, setViewType] = useState('grid')

  const { descriptor } = brandDetails
  const { name: brandName } = descriptor || {}
  const locationData = useLocation()
  const useQuery = () => {
    const { search } = locationData
    return React.useMemo(() => new URLSearchParams(search), [search])
  }
  let query = useQuery()
  const [productLoading, setProductLoading] = useState(false)
  productLoading
  const [productPayload, setProductPayload] = useState(null)
  productPayload
  const [products, setProducts] = useState([])
  const [totalProductCount, setTotalProductCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: 18,
    searchData: [],
  })
  const dispatch = useContext(ToastContext)

  // HOOKS
  const { cancellablePromise } = useCancellablePromise()

  const getAllProducts = async (brandId, customMenuId) => {
    setIsLoading(true)
    try {
      let paginationData = Object.assign({}, JSON.parse(JSON.stringify(paginationModel)))
      paginationData.searchData = paginationData?.searchData.filter((item) => item?.selectedValues.length > 0)
      paginationData.searchData = paginationData?.searchData.reduce(function (r, e) {
        r[e.code] = e.selectedValues.join()
        return r
      }, {})
      paginationData.searchData.page = paginationData.page
      paginationData.searchData.limit = paginationData.pageSize
      if (brandId) {
        paginationData.searchData.providerIds = brandId || ''
      }

      if (customMenuId) {
        paginationData.searchData.customMenu = customMenuId || ''
      }

      const data = await cancellablePromise(getAllProductRequest(paginationData.searchData))

      setProducts(data.data)
      setTotalProductCount(data.count)
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: 'Product details not found',
        },
      })
    } finally {
      setIsLoading(false)
    }
  }

  const getFilterValues = async (attributeCode) => {
    try {
      const data = await cancellablePromise(getAllFilterValuesRequest(attributeCode, '', brandId))
      let filterValues = data.data
      filterValues = filterValues.map((value) => {
        const createObj = {
          id: value,
          name: value,
        }
        return createObj
      })
      return filterValues
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err?.response?.data?.error?.message,
        },
      })
    } finally {
      setIsLoading(false)
    }
  }

  const getAllFilters = async () => {
    setIsLoading(true)
    try {
      // const data = await cancellablePromise(getAllFiltersRequest('', brandId))
      const data = await cancellablePromise(getAllFiltersRequest(brandId))
      let filtersData = data.data
      filtersData = filtersData.filter((item) => item.code !== 'size_chart')
      filtersData = Object.values(filtersData.reduce((acc, obj) => ({ ...acc, [obj.code]: obj }), {}))

      for (let filter of filtersData) {
        const values = await getFilterValues(filter.code)
        const findIndex = filtersData.findIndex((item) => item.code === filter.code)
        if (findIndex > -1) {
          filtersData[findIndex].options = values
          filtersData[findIndex].selectedValues = []
        }
      }

      let paginationData = Object.assign(JSON.parse(JSON.stringify(paginationModel)))
      paginationData.searchData = filtersData
      if (JSON.stringify(paginationModel) !== JSON.stringify(paginationData)) {
        setPaginationModel(paginationData)
      }
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err?.response?.data?.error?.message,
        },
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (locationData) {
      const customMenuId = query.get('cm')
      if (customMenuId) {
        getAllProducts(brandId, customMenuId)
      }
    }
  }, [locationData, deliveryAddressLocation])

  useEffect(() => {
    getAllFilters()
  }, [])

  useEffect(() => {
    getAllProducts(brandId, '')
  }, [paginationModel])

  const getProductDetails = async (productId) => {
    try {
      setProductLoading(true)
      const data = await cancellablePromise(
        getCall(`${process.env.REACT_APP_BASE_PROTOCOL_URL}/protocol/item-details?id=${productId}`),
      )
      setProductPayload(data)
      return data
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: error?.response?.data?.error?.message,
        },
      })
    } finally {
      setProductLoading(false)
    }
  }

  const handleAddToCart = async (productPayload, navigate) => {
    const deviceId = await getOrCreateDeviceId()
    const userId = getUserId()

    const url = `/clientApis/v2/cart/${userId}/${deviceId}`
    // const url = `/clientApis/v2/cart/${user.id ? user.id : customCartId}`

    const subtotal = productPayload.item_details.price.value
    const payload = {
      id: productPayload.id,
      local_id: productPayload.local_id,
      bpp_id: productPayload.bpp_details.bpp_id,
      bpp_uri: productPayload.context.bpp_uri,
      domain: productPayload.context.domain,
      quantity: {
        count: 1,
      },
      provider: {
        id: productPayload.bpp_details.bpp_id,
        locations: productPayload.locations,
        ...productPayload.provider_details,
      },
      product: {
        id: productPayload.id,
        subtotal,
        ...productPayload.item_details,
      },
      customisations: [],
      hasCustomisations:
        productPayload.hasOwn('customisation_groups') && productPayload.customisation_groups.length > 0,
    }

    await postCall(url, payload)
    if (navigate) {
      history.push('/cart')
    }

    fetchCartItems()
  }

  const { theme } = useTheme()

  return (
    <CategoryContainer theme={theme}>
      <BreadcrumbWrapper>
        <Breadcrumbs aria-label="breadcrumb">
          <MuiLink component={Link} underline="hover" color="inherit" to="/">
            <Typography className="text-color">Home</Typography>
          </MuiLink>
          <MuiLink component={Link} underline="hover" color="inherit" to="/providers">
            <Typography className="text-color">Providers</Typography>
          </MuiLink>
          {brandName && <Typography className="title-color">{brandName}</Typography>}
        </Breadcrumbs>
      </BreadcrumbWrapper>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid container spacing={4} className="products-wrapper">
          {isLoading ? (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Spinner />
            </Grid>
          ) : (
            <>
              {products?.length > 0 ? (
                <>
                  <SearchProducts
                    products={products}
                    handleAddToCart={handleAddToCart}
                    getProductDetails={getProductDetails}
                  />
                </>
              ) : (
                ''
              )}
            </>
          )}
        </Grid>
      </Grid>
      {products?.length > 0 && (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.paginationContainer}>
          <Pagination
            className={classes.pagination}
            count={Math.ceil(totalProductCount / paginationModel.pageSize)}
            shape="rounded"
            color="primary"
            page={paginationModel.page}
            onChange={(event, page) => {
              event
              let paginationData = Object.assign({}, paginationModel)
              paginationData.page = page
              setPaginationModel(paginationData)
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }}
          />
        </Grid>
      )}
      {/* </Grid> */}
    </CategoryContainer>
  )
}

export default Products
