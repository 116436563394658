import React, { useContext, useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import Timeline from '@mui/lab/Timeline'
// import TimelineItem from '@mui/lab/TimelineItem'
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import useStyles from 'components/orders/orderDetails/style'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import MuiLink from '@mui/material/Link'
import moment from 'moment'
import { getOrderDetailsRequest } from 'api/orders.api'
import useCancellablePromise from 'api/cancelRequest'
import { ToastContext } from 'context/toastContext'
import Loading from 'components/shared/loading/loading'
import { toast_actions, toast_types } from 'components/shared/toast/utils/toast'
import TimelineIcon from 'assets/svg/TimelineIcon'
import StateCompletionIcon from 'assets/svg/StateCompletionIcon'
import ProductImage from 'assets/images/no_image_found.png'
import {
  Container,
  MainWrapper,
  Wrapper,
  TimelineWrapper,
  OrderState,
  TimelineDetailWrapper,
  Header,
  TitleContainer,
  Price,
  TimelineInfo,
  Image,
  StatusContainer,
  Heading,
  CancelledItems,
  Title,
  MobileContainer,
  MobileTitleContainer,
  MobilePrice,
  QuantityContainer,
  MobileTimelineWrapper,
} from 'styles/TrackOrder'

const TrackOrder = () => {
  const classes = useStyles()
  const { orderId } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [orderDetails, setOrderDetails] = useState([])
  const dispatch = useContext(ToastContext)

  const PreviousStateIcon = <StateCompletionIcon />
  const CurrentStateIcon = <TimelineIcon />
  // HOOKS
  const { cancellablePromise } = useCancellablePromise()

  useEffect(() => {
    if (orderId) {
      getOrderDetails(true)
    }
  }, [orderId])
  const getOrderDetails = async (isLoading = true) => {
    if (isLoading) {
      setIsLoading(true)
    }

    try {
      const data = await cancellablePromise(getOrderDetailsRequest(orderId))
      setOrderDetails(data[0])
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err?.response?.data?.error?.message,
        },
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbWrapper}>
        <MuiLink component={Link} underline="hover" color="inherit" to="/">
          <Typography>Home</Typography>
        </MuiLink>
        <MuiLink component={Link} underline="hover" color="inherit" to={`/order/${orderId}`}>
          Order Details
        </MuiLink>
        {orderId && <Typography color="#115469">Track Order</Typography>}
      </Breadcrumbs>
      <Grid container spacing={5} className={classes.orderDetailsContainer}>
        <Heading>Track Status</Heading>
        {isLoading ? (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.loaderContainer}>
            <Loading />
          </Grid>
        ) : (
          <>
            {orderDetails?.fulfillmentTracking?.map((item, index) => {
              return (
                <MainWrapper key={index}>
                  <Wrapper>
                    <Header>
                      <TitleContainer>
                        <Image
                          src={
                            item?.item_details?.product?.descriptor?.images[0]
                              ? item?.item_details?.product?.descriptor?.images[0]
                              : ProductImage
                          }
                          alt={item?.item_details?.product?.descriptor?.name}
                          onError={(e) => {
                            e.target.src =
                              'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                          }}
                        />
                        <Title>{item?.item_details?.product?.descriptor?.name}</Title>
                        <CancelledItems>{item?.cancellation_status}</CancelledItems>
                      </TitleContainer>
                      <QuantityContainer>
                        Quantity : <span>{item?.item_details?.quantity?.count}</span>
                      </QuantityContainer>
                      <Price>
                        Base Price : <span>₹{item?.item_details?.product?.price?.value}</span>
                      </Price>
                      <MobileContainer>
                        <MobileTitleContainer>
                          Quantity : <span>{item?.item_details?.quantity?.count}</span>
                        </MobileTitleContainer>
                        <MobilePrice>
                          Base Price : <span>₹{item?.item_details?.product?.price?.value}</span>
                        </MobilePrice>
                      </MobileContainer>
                    </Header>
                    <TimelineWrapper>
                      <div>
                        <Timeline
                          sx={{
                            flexDirection: 'row', // Set the timeline direction to row
                            [`& .${timelineItemClasses.root}`]: {
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              minWidth: '200px', // Adjust the min-width for each item
                            },
                            [`& .${timelineItemClasses.root}:before`]: {
                              display: 'none', // Hide the default vertical line
                            },
                          }}
                        >
                          {item?.tracking?.map((trackingItem, index) => {
                            const isPreviousState = index < item?.tracking?.length - 1
                            const icon =
                              isPreviousState || trackingItem.state === 'Order-delivered'
                                ? PreviousStateIcon
                                : CurrentStateIcon
                            return (
                              <TimelineItem key={index}>
                                <TimelineSeparator>
                                  <TimelineDot className="timeline-dot">{icon}</TimelineDot>
                                  {index < item?.tracking?.length - 1 && (
                                    <TimelineConnector
                                      sx={{
                                        border: '1px dashed #B9B9B9 !important',
                                        width: '100%',
                                        height: '1px',
                                        backgroundColor: 'transparent',
                                      }}
                                    />
                                  )}
                                </TimelineSeparator>
                                <TimelineContent>
                                  <Wrapper>
                                    <StatusContainer>
                                      <OrderState state={trackingItem.state}>{trackingItem.state}</OrderState>
                                      {trackingItem.state === 'Cancelled' && (
                                        <span>(Qty : {trackingItem?.itemIds[item?.item_id]?.quantity})</span>
                                      )}
                                    </StatusContainer>
                                    <TimelineDetailWrapper>
                                      <TimelineInfo variant="body2">
                                        {moment(trackingItem.updatedAt).format('MMMM Do, YYYY')}
                                      </TimelineInfo>
                                      <TimelineInfo variant="body2">
                                        {moment(trackingItem.updatedAt).format('hh:mma')}
                                      </TimelineInfo>
                                    </TimelineDetailWrapper>
                                  </Wrapper>
                                </TimelineContent>
                              </TimelineItem>
                            )
                          })}
                        </Timeline>
                      </div>
                    </TimelineWrapper>
                    <MobileTimelineWrapper>
                      <div>
                        <Timeline
                          sx={{
                            [`& .${timelineItemClasses.root}:before`]: {
                              flex: 0,
                              padding: 0,
                            },
                          }}
                        >
                          {item?.tracking?.map((trackingItem, index) => {
                            const isPreviousState = index < item?.tracking?.length - 1
                            const icon =
                              isPreviousState || trackingItem.state === 'Order-delivered'
                                ? PreviousStateIcon
                                : CurrentStateIcon
                            return (
                              <TimelineItem key={index}>
                                <TimelineSeparator>
                                  <TimelineDot className="timeline-dot">{icon}</TimelineDot>
                                  {index < item?.tracking?.length - 1 && (
                                    <TimelineConnector
                                      sx={{
                                        border: '1px dashed #B9B9B9 !important',
                                        height: '1px',
                                        backgroundColor: 'transparent',
                                      }}
                                    />
                                  )}
                                </TimelineSeparator>
                                <TimelineContent>
                                  <Wrapper>
                                    <StatusContainer>
                                      <OrderState state={trackingItem.state}>{trackingItem.state}</OrderState>
                                      {trackingItem.state === 'Cancelled' && (
                                        <span>(Qty : {trackingItem?.itemIds[item?.item_id]?.quantity})</span>
                                      )}
                                    </StatusContainer>
                                    <TimelineDetailWrapper>
                                      <TimelineInfo variant="body2">
                                        {moment(trackingItem.updatedAt).format('DD/MM/yy')}
                                      </TimelineInfo>
                                      <TimelineInfo variant="body2">
                                        {moment(trackingItem.updatedAt).format('hh:mma')}
                                      </TimelineInfo>
                                    </TimelineDetailWrapper>
                                  </Wrapper>
                                </TimelineContent>
                              </TimelineItem>
                            )
                          })}
                        </Timeline>
                      </div>
                    </MobileTimelineWrapper>
                  </Wrapper>
                </MainWrapper>
              )
            })}
          </>
        )}
      </Grid>
    </Container>
  )
}

export default TrackOrder
