import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin-top: 130px;
  @media (max-width: 767px) {
    gap: 0;
    margin-top: 180px;
  }
`
export const ContactUsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin: 35px 80px;
  @media (max-width: 767px) {
    margin: 30px 16px;
    gap: 24px;
  }
`
export const ContactUsHeading = styled.div`
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
  @media (max-width: 767px) {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }
`
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`
export const ContactUsContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
`
export const ContactFormWrapper = styled.div`
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`
export const ContactDetailSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  background: ${theme.CONTACTBACKGROUNDCOLOR};
  padding: 48px 32px;
  max-width: 477px;
  width: 100%;
  @media (max-width: 767px) {
    padding: 24px;
  }
`
export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const MainHeading = styled.div`
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  color: ${theme.SECONDARYTEXTCOLOR};
  @media (max-width: 767px) {
    font-size: 32px;
    line-height: 40px;
  }
`
export const SubHeading = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: ${theme.SECONDARYBACKGROUNDCOLOR};
`
export const DetailSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (max-width: 767px) {
    gap: 16px;
  }
`
export const ContactDetailWrapper = styled.div``
export const DetailWrapper = styled.div`
  display: flex;
  gap: 5px;
`
export const ContactDetail = styled.div`
  display: flex;
  flex-direction: column;
`
export const ContactContent = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.SECONDARYTEXTCOLOR};
`
/*********** Contact Form CSS ************/
export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: ${theme.TERTIARYBACKGROUNDCOLOR};
  padding: 48px 32px;
  width: 100%;
  @media (max-width: 767px) {
    padding: 24px;
  }
`
export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 10px;
  @media (max-width: 767px) {
    grid-template-columns: unset;
    gap: 16px;
  }
`
export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const FieldTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
  .asterik {
    color: ${theme.SECONDARYERRORCOLOR};
  }
`
export const FieldInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  input {
    background: ${theme.SECONDARYTEXTCOLOR};
    padding: 8px;
    font-size: 14px;
    border: 1px solid ${theme.SECONDARYBORDERCOLOR};
    border-radius: 6px;
  }
  textarea {
    padding: 8px;
    font-size: 14px;
    border: 1px solid ${theme.SECONDARYBORDERCOLOR};
    border-radius: 6px;
  }
`
export const ErrorMessage = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.ERROR};
  padding: 2px 0;
  text-align: start;
`
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
