import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const ProviderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 140px;
  @media (max-width: 767px) {
    gap: 15px;
    margin-top: 180px;
  }
`
export const BreadcrumbWrapper = styled.div`
  background: ${theme.TERTIARYBACKGROUNDCOLOR};
  padding: 16px 100px;
  @media (max-width: 767px) {
    padding: 16px;
  }
`
export const Title = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
`

export const ProvidersListingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 0 100px 30px 100px;
  @media (max-width: 767px) {
    margin: 0 16px;
    gap: 14px;
  }
`
export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const MainHeading = styled.div`
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
  @media (max-width: 767px) {
    font-size: 25px;
  }
`
export const CardContainer = styled.div``
export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 30px 0;
  margin-bottom: 20px;
  .empty-state {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`
export const ProviderCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  @media (max-width: 1495px) {
    gap: 18px;
  }
  @media (max-width: 1450px) {
    gap: 15px;
  }
  @media (max-width: 767px) {
    justify-content: center;
  }
  @media (max-width: 450px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`
export const IconButton = styled.div`
  padding: 10px;
  max-width: 40px;
  width: 100%;
  height: 40px;
  border-radius: 50%;
  background: ${theme.PRIMARYCOLOR};
  cursor: pointer;
  @media (max-width: 767px) {
    display: none;
  }
`

export const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 163px;
  width: 100%;
  gap: 12px;
`
export const Wrapper = styled.div`
  max-width: 163px;
  min-width: 163px;
  width: 100%;
  max-height: 156px;
  min-height: 156px;
  border: 1px solid ${theme.SECONDARYBORDERCOLOR};
  border-radius: 8px;
  padding: 25px;
  cursor: pointer;
  @media (max-width: 450px) {
    display: flex;
    justify-content: center;
    max-width: 160px;
    min-width: 160px;
    max-height: 180px;
    min-height: 160px;
  }
`
export const ImageWrapper = styled.div`
  img {
    width: 102px;
    height: 106px;
    object-fit: contain;
  }
`
export const ProviderName = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.PRIMARYTEXTCOLOR};
  text-align: center;
`

export const SearchWrapper = styled.div`
  width: 100%;
  max-width: 534px;

  @media screen and (max-width: 1000px) {
    max-width: 400px;
  }
`

export const SearchContent = styled.form`
  padding: 2px 10px !important;
  display: flex;
  align-items: center;
  border-radius: 12px;
  min-height: 40px;
  border: 1px solid ${theme.TERTIARYGREYCOLOR};
  @media (max-width: 450px) {
    padding: 2px 1px !important;
    display: flex;
    align-items: center;
    border-radius: 12px;
    min-height: 40px;
    border: 1px solid ${theme.TERTIARYGREYCOLOR};
    width: 145px;
  }
`
export const NoDataWrapper = styled.div``
