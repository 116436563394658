import React, { useContext } from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Button from '@mui/material/Button'
import BharathamLogo from 'assets/images/bharatham.png'

import {
  MainContainer,
  LeftContainer,
  Logo,
  RightContainer,
  Wrapper,
  Content,
  Heading,
  Title,
  SubTitle,
  CheckboxWrapper,
  ButtonContainer,
  HelpText,
} from 'styles/Feedback'
import { Controller, useForm } from 'react-hook-form'
import { TextField } from '@mui/material'
import usePost from 'hooks/usePost'
import { toast_actions, toast_types } from 'components/shared/toast/utils/toast'
import { ToastContext } from 'context/toastContext'

const Feedback = () => {
  const { mutateAsync } = usePost()
  const { control, handleSubmit, reset } = useForm()
  const dispatch = useContext(ToastContext)

  const currentUrl = window.location.href
  const url = currentUrl
  const parts = url.split('/')
  const orderId = parts[parts.length - 1]

  const onSubmit = async (data) => {
    const payload = {
      message: data.recommendations,
      websiteDesign: data.visuallyAppealing,
      deliveryTime: data.estimatedDelivery,
      quality: data.productQuality,
      recommendWebsite: data.recommendWebsite,
      checkoutSatisfaction: data.checkoutSatisfaction,
    }
    try {
      const res = await mutateAsync({
        url: `clientApis/v2/feedback/${orderId}`,
        method: 'POST',
        payload: payload,
      })
      if (res) {
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.success,
            message: res?.message,
          },
        })
      }
    } catch (error) {
      return error
    }

    reset()
  }

  return (
    <MainContainer>
      <LeftContainer>
        <Logo src={BharathamLogo} alt="" />
      </LeftContainer>
      <RightContainer>
        <Wrapper>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Content>
              <Heading>Hi </Heading>
              <SubTitle>We fulfilled your order from our store and would love to get your feedback.</SubTitle>
            </Content>
            <Content>
              <Title>Did you find the website design visually appealing?</Title>
              <CheckboxWrapper>
                <Controller
                  name="visuallyAppealing"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel value={true} control={<Radio />} label="Yes" />
                      <FormControlLabel value={false} control={<Radio />} label="No" />
                    </RadioGroup>
                  )}
                />
              </CheckboxWrapper>
            </Content>
            <Content>
              <Title>Did you receive your order within the estimated delivery time?</Title>
              <CheckboxWrapper>
                <Controller
                  name="estimatedDelivery"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel value={true} control={<Radio />} label="Yes" />
                      <FormControlLabel value={false} control={<Radio />} label="No" />
                    </RadioGroup>
                  )}
                />
              </CheckboxWrapper>
            </Content>
            <Content>
              <Title>Were you satisfied with the quality of the products received?</Title>
              <CheckboxWrapper>
                <Controller
                  name="productQuality"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel value={true} control={<Radio />} label="Yes" />
                      <FormControlLabel value={false} control={<Radio />} label="No" />
                    </RadioGroup>
                  )}
                />
              </CheckboxWrapper>
            </Content>
            <Content>
              <Title>Would you recommend our website to others?</Title>
              <CheckboxWrapper>
                <Controller
                  name="recommendWebsite"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel value={true} control={<Radio />} label="Yes" />
                      <FormControlLabel value={false} control={<Radio />} label="No" />
                    </RadioGroup>
                  )}
                />
              </CheckboxWrapper>
            </Content>
            <Content>
              <Title>Were you satisfied with the checkout process?</Title>
              <CheckboxWrapper>
                <Controller
                  name="checkoutSatisfaction"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel value={true} control={<Radio />} label="Yes" />
                      <FormControlLabel value={false} control={<Radio />} label="No" />
                    </RadioGroup>
                  )}
                />
              </CheckboxWrapper>
            </Content>
            <Content>
              <Title>Any Recommendations</Title>
              <Controller
                name="recommendations"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} rows={3} multiline variant="outlined" fullWidth />}
              />
            </Content>
            <ButtonContainer>
              <Button variant="contained" type="submit">
                Submit Review
              </Button>
            </ButtonContainer>
          </form>
          <HelpText>
            Need Help? Ask at <span>team@thewitslab.com</span> or visit our <span>help center</span>
          </HelpText>
        </Wrapper>
      </RightContainer>
    </MainContainer>
  )
}

export default Feedback
