import * as React from 'react'
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={19} fill="none" {...props}>
    <g fill="#D83232" clipPath="url(#a)">
      <path d="M14.249 5.449a.75.75 0 0 0-.75.75v8.393a1.44 1.44 0 0 1-1.508 1.357H6.008a1.44 1.44 0 0 1-1.508-1.357V6.199a.75.75 0 1 0-1.5 0v8.393a2.938 2.938 0 0 0 3.008 2.857h5.985a2.938 2.938 0 0 0 3.007-2.857V6.199a.75.75 0 0 0-.75-.75Zm.75-2.25h-3v-1.5a.75.75 0 0 0-.75-.75h-4.5a.75.75 0 0 0-.75.75v1.5h-3a.75.75 0 0 0 0 1.5h12a.75.75 0 0 0 0-1.5Zm-7.5 0v-.75h3v.75h-3Z" />
      <path d="M8.249 12.95V7.7a.75.75 0 1 0-1.5 0v5.25a.75.75 0 1 0 1.5 0Zm3 0V7.7a.75.75 0 1 0-1.5 0v5.25a.75.75 0 1 0 1.5 0Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .2h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
