import { useState, useMemo, useEffect } from 'react'
import FilterContainer from '../../views/searchProducts/filterSection'
import { ProductCards } from '../../views/searchProducts/productCard'
import RemoveFilterIcon from '../../assets/svg/RemoveProductIcon'
import {
  ProductsContainer,
  ContentSection,
  ProductSection,
  ActiveFilters,
  WebFilter,
  MobileFilter,
  FilterMobile,
  ActiveFilterWrapper,
  ActiveFilterText,
} from '../../styles/searchProducts'
import { useTheme } from '../../context/newThemeContext'
import { Drawer } from '@mui/material'
import FilterIcon from '../../assets/svg/FilterIcon'

const getPriceRangeValues = (priceRange) => {
  switch (priceRange) {
    case 'All Price':
      return [0, Infinity]
    case 'Under ₹20':
      return [0, 20]
    case 'Under ₹100':
      return [0, 100]
    case 'Under ₹500':
      return [0, 500]
    case 'Under ₹1000':
      return [0, 1000]
    case 'Under ₹10,000':
      return [0, 10000]
    case 'Above ₹10,000':
      return [10000, Infinity]
    default:
      return [0, Infinity]
  }
}

const SearchProducts = ({ products }) => {
  const [priceRange, setPriceRange] = useState('All Price')
  const [activeFilter, setActiveFilter] = useState(null)

  const handlePriceRangeChange = (event) => {
    const selectedPriceRange = event.target.value
    setPriceRange(selectedPriceRange)
    setActiveFilter(selectedPriceRange)
  }

  const handleRemoveFilter = () => {
    setPriceRange('All Price')
    setActiveFilter(null)
  }

  const filteredProducts = useMemo(() => {
    let filtered = products

    // Apply selected price range filter
    if (priceRange !== 'All Price') {
      const [minPrice, maxPrice] = getPriceRangeValues(priceRange)
      filtered = filtered.filter((product) => {
        const price = Number(product?.item_details?.price?.value)
        return price >= minPrice && price < maxPrice
      })
    }

    // Apply active filter
    if (activeFilter && activeFilter !== 'All Price') {
      const [minPrice, maxPrice] = getPriceRangeValues(activeFilter)
      filtered = filtered.filter((product) => {
        const price = Number(product?.item_details?.price?.value)
        return price >= minPrice && price < maxPrice
      })
    } else if (activeFilter === 'All Price') {
      filtered = products
    }

    return filtered
  }, [products, priceRange, activeFilter])

  const [activeProducts, setActiveProducts] = useState(filteredProducts)
  useEffect(() => {
    setActiveProducts(filteredProducts)
  }, [filteredProducts])

  const { theme } = useTheme()

  const [open, setOpen] = useState(false)
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen)
  }

  const DrawerList = (
    <div style={{ padding: '20px' }}>
      <FilterContainer onPriceRangeChange={handlePriceRangeChange} priceRange={priceRange} setOpen={setOpen} />
    </div>
  )

  return (
    <ProductsContainer theme={theme}>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
      <MobileFilter>
        <FilterMobile onClick={toggleDrawer(true)}>
          <FilterIcon /> Add Filters
        </FilterMobile>
      </MobileFilter>
      <ContentSection>
        <WebFilter>
          <FilterContainer onPriceRangeChange={handlePriceRangeChange} priceRange={priceRange} />
        </WebFilter>
        <ProductSection>
          <ActiveFilters>
            <ActiveFilterWrapper>
              Active Filter : {activeFilter}
              <div>{activeFilter && <RemoveFilterIcon onClick={handleRemoveFilter} />}</div>
            </ActiveFilterWrapper>
            <ActiveFilterText>{`${filteredProducts?.length} ${filteredProducts?.length === 1 || filteredProducts?.length === 0 ? 'result' : 'results'} found`}</ActiveFilterText>
          </ActiveFilters>
          <ProductCards products={activeProducts} setProducts={setActiveProducts} />
        </ProductSection>
      </ContentSection>
    </ProductsContainer>
  )
}

export default SearchProducts
