import { createContext, useState } from 'react'

export const UserContext = createContext({
  userName: null,
  setUserName: () => {
    return
  },
})

export function UserContextProvider({ children }) {
  const [userName, setUserName] = useState()
  return (
    <UserContext.Provider
      value={{
        setUserName,
        userName,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
