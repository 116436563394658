import { TranslateOutlined } from '@mui/icons-material'
import { GoogleTranslateElement } from 'styles/navbar'
import { useEffect } from 'react'

const TranslateWidget = () => {
  useEffect(() => {
    // Function to add the script tag to the DOM
    const addGoogleTranslateScript = () => {
      if (!document.querySelector("script[src*='translate_a/element.js']")) {
        const script = document.createElement('script')
        script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
        script.async = true
        document.body.appendChild(script)
      }
    }

    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: 'en',
          autoDisplay: false,
          includedLanguages: 'en,as,brx,gu,hi,kn,ml,mni,mr,or,pa,ta,te,bn',
        },
        'google_translate_element',
      )
    }

    // Inject the script tag
    addGoogleTranslateScript()

    // Setup MutationObserver to hide the dropdown
    // const observer = new MutationObserver((mutationsList, observer) => {
    //   mutationsList.forEach((mutation) => {
    //     if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
    //       const dropdown = document.querySelector('.goog-te-combo')
    //       if (dropdown && dropdown.style.display !== 'none') {
    //         dropdown.style.display = 'none' // Ensure dropdown is visible before hiding
    //         observer.disconnect()
    //       }
    //     }
    //   })
    // })

    // // Start observing the entire body for attribute changes in the subtree
    // observer.observe(document.body, { attributes: true, subtree: true })

    // Cleanup function to remove the script, the global function, and the observer
    return () => {
      // Remove the script from the DOM
      const script = document.querySelector("script[src*='translate_a/element.js']")
      if (script) {
        document.body.removeChild(script)
      }

      // Remove the global function
      delete window.googleTranslateElementInit

      // Disconnect the observer
      //   observer.disconnect()
    }
  }, [])

  return (
    <>
      <GoogleTranslateElement>
        <TranslateOutlined />
        <div id="google_translate_element"></div>
      </GoogleTranslateElement>
    </>
  )
}

export default TranslateWidget
