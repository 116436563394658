import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  tagLabel: {
    marginBottom: '10px !important',
  },
  selectAddressRadioContainer: {
    display: 'flex !important',
    marginLeft: '10px !important',
  },
  formControlLabel: {
    flex: '1 !important',
  },
  addAddressContainer: {
    display: 'flex !important',
  },
  tagRadio: {
    marginRight: '5px !important',
    marginBottom: '5px !important',
  },
  '@media (max-width: 600px)': {
    tagLabel: {
      marginBottom: '0 !important',
    },
    tagRadio: {
      marginRight: '0 !important',
      marginBottom: '0 !important',
    },
    selectAddressRadioContainer: {
      marginLeft: '0 !important',
    },
  },
})

export default useStyles
