import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useTheme } from '../../context/newThemeContext'
import {
  GridContainer,
  InputContainer,
  ThemeContainer,
  ButtonContainer,
  UpdatedValue,
  TitleContainer,
  UploadWrapper,
} from '../../styles/themeForm'
import { initialPaletteState } from '../../utils/NewTheme/newThemePalette'

const PaletteForm = ({ setshowTheme }) => {
  const { theme, updateTheme } = useTheme(initialPaletteState)
  const [updatedTheme, setUpdatedTheme] = useState(theme || initialPaletteState)
  const [uploadedImage, setUploadedImage] = useState(null)

  const handleChange = (property, value) => {
    setUpdatedTheme((prevTheme) => ({
      ...prevTheme,
      [property]: value,
    }))
  }

  const handleImageUpload = (event) => {
    const file = event.target.files[0]
    const allowedTypes = ['image/jpeg', 'image/png']
    const maxSize = 5 * 1024 * 1024 // 5MB

    if (file && allowedTypes.includes(file.type) && file.size <= maxSize) {
      const reader = new FileReader()
      reader.onload = () => {
        setUploadedImage(reader.result)
      }

      reader.readAsDataURL(file)
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    localStorage.setItem('theme', JSON.stringify(updatedTheme))

    const updatedThemeWithImage = {
      ...updatedTheme,
      uploadedImage: uploadedImage,
    }
    // Update the theme context with the updated theme
    updateTheme(updatedThemeWithImage)

    setshowTheme(false)
  }

  return (
    <ThemeContainer>
      <TitleContainer>Choose Your Preferences</TitleContainer>
      <form onSubmit={handleSubmit}>
        <GridContainer>
          <div>
            <label className="label" htmlFor="primaryColor">
              Primary Color
            </label>
            <InputContainer>
              <input
                type="color"
                id="primaryColor"
                value={updatedTheme.primaryColor}
                onChange={(e) => handleChange('primaryColor', e.target.value)}
              />
              <UpdatedValue>{updatedTheme.primaryColor}</UpdatedValue>
            </InputContainer>
          </div>
          <div>
            <label className="label" htmlFor="secondaryColor">
              Secondary Color
            </label>
            <InputContainer>
              <input
                type="color"
                id="secondaryColor"
                value={updatedTheme.secondaryColor}
                onChange={(e) => handleChange('secondaryColor', e.target.value)}
              />
              <UpdatedValue>{updatedTheme.secondaryColor}</UpdatedValue>
            </InputContainer>
          </div>
        </GridContainer>
        <GridContainer>
          <div>
            <label className="label" htmlFor="secondaryColor">
              Background Color
            </label>
            <InputContainer>
              <input
                type="color"
                id="secondaryColor"
                value={updatedTheme.backgroundColor}
                onChange={(e) => handleChange('backgroundColor', e.target.value)}
              />
              <UpdatedValue>{updatedTheme.backgroundColor}</UpdatedValue>
            </InputContainer>
          </div>
          <div>
            <label className="label" htmlFor="fontFamily">
              Font Style
            </label>
            <InputContainer>
              <select
                id="fontFamily"
                value={updatedTheme.fontFamily}
                onChange={(e) => handleChange('fontFamily', e.target.value)}
              >
                <option value="Poppins">Poppins</option>
                <option value="Arial">Arial</option>
                <option value="Times New Roman">Times new roman</option>
                <option value="Verdana">Verdana</option>
                <option value="Georgia">Georgia</option>
              </select>
            </InputContainer>
          </div>
        </GridContainer>
        {/* File Upload Input */}
        <div>
          <label className="label" htmlFor="imageUpload">
            Upload Image:
          </label>
        </div>
        <InputContainer>
          <input
            type="file"
            id="imageUpload"
            accept="image/*"
            onChange={handleImageUpload}
            style={{ display: 'none' }}
          />
          <label className="uploadLabel" htmlFor="imageUpload">
            Select file upto 5mb and format should be in png/jpg
          </label>
        </InputContainer>
        {/* Display the uploaded image */}
        {uploadedImage && (
          <UploadWrapper>
            <img src={uploadedImage} alt="Uploaded" />
          </UploadWrapper>
        )}
        <ButtonContainer>
          <Button variant="contained" type="submit">
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setshowTheme(false)
            }}
          >
            Close
          </Button>
        </ButtonContainer>
      </form>
    </ThemeContainer>
  )
}

export default PaletteForm
