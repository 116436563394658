import styled from 'styled-components'

const getStateColor = (state) => {
  switch (state) {
    case 'Pending':
      return '#FFA500'
    case 'In-Progress':
      return '#1D2E88 '
    case 'Agent-Assigned':
      return '#1D2E88'
    case 'Out-for-delivery':
      return '#107EE3'
    case 'Order-picked-up':
      return '#e2b00e'
    case 'Order-delivered':
      return '#08D604 '
    case 'Return_Initiated':
      return '#D32F2F '
    default:
      return '#115469'
  }
}

export const Container = styled.div`
  margin-top: 140px;
  @media (max-width: 767px) {
    margin-top: 180px;
  }
`
export const Heading = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  padding: 40px 40px 20px 40px;
  @media (max-width: 767px) {
    padding: 16px 0 12px 8px;
  }
`

export const MainWrapper = styled.div`
  width: 100%;
  padding: 0 40px;
  margin: 10px 0;
  @media (max-width: 767px) {
    padding: 0;
  }
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 767px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
`
export const StatusContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;

  > span {
    color: #adb5bd;
    font-size: 14px;
  }
`

export const TimelineWrapper = styled.div`
  width: 100%;
  border: 1px solid #b9b9b9;
  border-top: none;
  border-radius: 0 0 10px 10px;
  overflow-x: auto;
  .timeline-dot {
    box-shadow: unset !important;
    background: transparent !important;
  }

  .timeline-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .MuiTimelineSeparator-root {
    display: flex;
    flex-direction: unset !important;
    width: -webkit-fill-available;
    transform: translateX(90px);
  }
  @media (max-width: 767px) {
    display: none;
  }
`
export const MobileTimelineWrapper = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: flex;
    width: 100%;
    border: 1px solid #b9b9b9;
    border-top: none;
    border-radius: 0 0 10px 10px;
    span {
      svg {
        fill: #115469 !important;
      }
    }
  }
  .timeline-dot {
    box-shadow: unset !important;
    background: transparent !important;
  }
`
export const OrderState = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* color: ${({ state }) => getStateColor(state)}; */
  color: #115469;
`
export const TimelineDetailWrapper = styled.div`
  display: flex;
  gap: 5px;
`
export const TimelineInfo = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #686868;
`
export const OrderSummaryWrapper = styled.div`
  max-width: 792px;
  width: 100%;
`
export const Header = styled.div`
  background: #e7eef0;
  width: 100%;
  height: 100px;
  padding: 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px 10px 0 0;
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 4px;
    padding: 16px;
    justify-content: flex-start;
    align-items: start;
    height: unset;
  }
`
export const TitleContainer = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  font-weight: 600;
`
export const QuantityContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 600;
  @media (max-width: 767px) {
    display: none;
  }
`
export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1d1d1d;
  max-width: 300px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const CancelledItems = styled.div`
  color: red;
`
export const Image = styled.img`
  width: 99.03px;
  height: 56.48px;
  object-fit: contain;
`

export const Price = styled(TitleContainer)`
  gap: 10px;
  span {
    color: #115469;
  }
  @media (max-width: 767px) {
    display: none;
  }
`
export const MobileContainer = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* gap: 40px; */
    width: 100%;
    padding-left: 13px;
  }
`

export const MobileTitleContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
`
export const MobilePrice = styled(TitleContainer)`
  span {
    color: #115469;
  }
`
