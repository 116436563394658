import React, { useContext, useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import {
  RevokeModalWrapper,
  RevokeContainer,
  ContentWrapper,
  ContentSection,
  ButtonContainer,
} from '../../styles/AddressDeleteModal'
import { deleteWithAuthentication, deleteCall, postLoginCall } from 'api/axios'
import { toast_actions, toast_types } from 'components/shared/toast/utils/toast'
import { ToastContext } from 'context/toastContext'
import { deleteAllCookies } from 'utils/cookies'
import useGet from 'hooks/useGet'
import ReactGA from 'react-ga4'
import { CartContext } from 'context/cartContext'
import { useHistory } from 'react-router-dom'
import { getOrCreateDeviceId, getUserId } from 'helper'
import { LoginContext } from 'context/loginContext'
import { isLoggedIn } from 'utils/validateToken'
import ModalComponent from 'components/common/Modal'
import LoginModal from 'views/LoginModal'

const ConfirmationModal = ({ onClose, title, type, id, fetchDeliveryAddress, cartItems, setCartItems, subTitle }) => {
  const dispatch = useContext(ToastContext)
  const history = useHistory()
  const [deviceId, setDeviceId] = useState('')
  const [loginModal, setLoginModal] = useState('')
  const { setCartItems: setCartContext } = useContext(CartContext)
  const { logout } = useContext(LoginContext)

  const userId = getUserId()
  useEffect(() => {
    const fetchId = async () => {
      const id = await getOrCreateDeviceId()
      setDeviceId(id)
    }

    fetchId()
  }, [])

  const { refetch: fetchWishlist } = useGet('wishlist', deviceId && `/clientApis/v2/wishlist/${userId}/${deviceId}`)

  useEffect(() => {
    if (title === 'wishlist') fetchWishlist()
  }, [])

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search, title: 'Wishlist' })
  }, [])

  const handleConfirm = (type, itemId) => {
    switch (type) {
      case 'wishlist':
        deleteWishlistItem(itemId)
        break
      case 'address':
        deleteAddressItem(itemId)
        break
      case 'cartItem':
        deleteCartItem(itemId)
        break
      case 'logout':
        handleLogOut()
        break
    }
  }
  const deleteWishlistItem = async (id) => {
    const userId = getUserId()
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: 'remove from wishlist',
    })
    try {
      const url = `/clientApis/v2/wishlist/${userId}/${id}`
      await deleteWithAuthentication(url)
      onClose()
      fetchWishlist()
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.success,
          message: 'Item has been removed successfully!',
        },
      })
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: error?.response?.data?.error?.message,
        },
      })
    }
  }

  const deleteAddressItem = async (itemId) => {
    try {
      const url = `/clientApis/v1/delete_delivery_address/${itemId}`
      await deleteWithAuthentication(url)
      onClose()
      fetchDeliveryAddress()
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.success,
          message: 'Address has been deleted successfully!',
        },
      })
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: error?.response?.data?.error?.message,
        },
      })
    }
  }

  const addToWishlist = async (itemId) => {
    function findItemById(itemId, itemsArray) {
      return itemsArray.find((item) => item._id === itemId)
    }

    const product = findItemById(itemId, cartItems)

    const userId = getUserId()
    if (!isLoggedIn()) {
      setLoginModal(true)
      return
    }

    try {
      const cartUrl = `/clientApis/v2/cart/${userId}/${itemId}`
      await deleteCall(cartUrl)
      const newCartItems = cartItems.filter((item) => item._id !== itemId)
      setCartItems(newCartItems)
      setCartContext(newCartItems)

      const deviceId = await getOrCreateDeviceId()
      const wishlistUrl = `/clientApis/v2/wishlist/${userId}/${deviceId}`
      const payload = {
        local_id: product?.item?.local_id,
        id: product?.item?.id,
        provider: {
          id: product?.item?.provider?.id,
        },
        quantity: {
          count: 1,
        },
      }
      const data = await postLoginCall(wishlistUrl, payload)
      if (data) {
        // fetchCartItems()
        localStorage.setItem('wishlistItems', JSON.stringify(data))
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.success,
            message: 'Item added to wishlist successfully.',
          },
        })
      }

      onClose()
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.warning,
          message: error?.message,
        },
      })
      onClose()
    }
  }
  const deleteCartItem = async (itemId) => {
    try {
      const url = `/clientApis/v2/cart/${userId}/${itemId}`
      await deleteCall(url)
      const newCartItems = cartItems.filter((item) => item._id !== itemId)
      setCartItems(newCartItems)
      setCartContext(newCartItems)
      onClose()
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.success,
          message: 'Item has been removed successfully!',
        },
      })
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: error?.response?.data?.error?.message,
        },
      })
    }
  }

  const handleLogOut = () => {
    deleteAllCookies()
    localStorage.removeItem('product_list')
    localStorage.removeItem('cartItems')
    localStorage.removeItem('token')
    logout()
    history.push('/')
    onClose()
  }

  return (
    <RevokeModalWrapper>
      <RevokeContainer>
        <ContentSection>
          <ContentWrapper>{title}</ContentWrapper>
          <ContentWrapper>{subTitle}</ContentWrapper>
        </ContentSection>

        <ButtonContainer>
          {type !== 'cartItem' && (
            <Button color="primary" variant="outlined" onClick={() => onClose()}>
              Cancel
            </Button>
          )}
          {type !== 'cartItem' && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                handleConfirm(type, id)
              }}
            >
              Confirm
            </Button>
          )}
          {type === 'cartItem' && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                deleteCartItem(id)
              }}
            >
              Remove
            </Button>
          )}
          {type === 'cartItem' && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                addToWishlist(id)
              }}
            >
              Move To Wishlist
            </Button>
          )}
        </ButtonContainer>
      </RevokeContainer>
      {loginModal && (
        <ModalComponent open={loginModal} onClose={() => setLoginModal(false)}>
          <LoginModal onClose={() => setLoginModal(false)} />
        </ModalComponent>
      )}
    </RevokeModalWrapper>
  )
}

export default ConfirmationModal
