import React from 'react'
import useStyles from './style'
import ReactGA from 'react-ga4'
import Button from '@mui/material/Button'
import { useHistory } from 'react-router-dom'
import moment from 'moment/moment'
import ProductImage from 'assets/images/no_image_found.png'
import {
  OrderDeatilWrapper,
  OrderWrapper,
  OrderIdWrapper,
  OrderId,
  OrderDetailWrapper,
  DetailWrapper,
  Title,
  Content,
  OrderHistoryWrapper,
  Wrapper,
  OrderImageWrapper,
  ProductDetailWrapper,
  ProductTitle,
  StoreTitle,
  QunatityWrapper,
  QuantityTitle,
  ProductQuantity,
  ButtonWrapper,
} from 'styles/OrderHistory'

const OrderCard = ({ orderDetails }) => {
  const classes = useStyles()
  const history = useHistory()
  const { provider, items, createdAt, state } = orderDetails
  const { descriptor } = provider

  return (
    <OrderDeatilWrapper>
      <OrderWrapper>
        <OrderIdWrapper>
          <OrderId>Order number :</OrderId>
          <OrderId>{orderDetails?.id}</OrderId>
        </OrderIdWrapper>
        <OrderDetailWrapper>
          <DetailWrapper>
            <Title>Order On : </Title>
            <Content>
              {moment(createdAt).format('MMMM Do, YYYY')} at {moment(createdAt).format('hh:mma')}
            </Content>
          </DetailWrapper>
          <DetailWrapper>
            <Title>Order Status: </Title>
            <Content>{state}</Content>
          </DetailWrapper>
        </OrderDetailWrapper>
        <OrderDetailWrapper>
          <DetailWrapper>
            <Title>Estimated Delivery : </Title>
            <Content>
              {moment(orderDetails?.items?.product?.fulfillments?.['@ondc/org/TAT']).format('DD MMMM YYYY')}
            </Content>
          </DetailWrapper>
          <DetailWrapper>
            <Title>Payment Method : </Title>
            <Content>Prepaid</Content>
          </DetailWrapper>
        </OrderDetailWrapper>
      </OrderWrapper>
      <OrderHistoryWrapper>
        {items
          ?.filter((item) => item?.quantity?.count > 0)
          ?.map((item, index) => {
            return (
              <Wrapper key={index}>
                <OrderImageWrapper>
                  <img
                    src={
                      item?.product?.descriptor?.images?.length > 0
                        ? item?.product?.descriptor?.images[0]
                        : ProductImage
                    }
                    onError={(e) => {
                      e.target.src =
                        'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                    }}
                    alt="product-image"
                  />
                </OrderImageWrapper>
                <ProductDetailWrapper>
                  <ProductTitle>{item?.product?.descriptor?.name}</ProductTitle>
                  <StoreTitle>{descriptor?.name}</StoreTitle>
                  <QunatityWrapper>
                    <QuantityTitle>Quantity:</QuantityTitle>
                    <ProductQuantity>
                      {item?.quantity?.count ? item?.quantity?.count : item?.originalCount}
                      <span>
                        {(item?.return_status || item?.cancellation_status) && (
                          <div>(Status: {item?.return_status || item?.cancellation_status})</div>
                        )}
                      </span>
                      {/* {item?.originalCount} */}
                    </ProductQuantity>
                  </QunatityWrapper>
                </ProductDetailWrapper>
              </Wrapper>
            )
          })}

        {orderDetails?.state === 'Confirmed' && (
          <Button className={classes.trackOrderButton} variant="contained">
            Track Order
          </Button>
        )}
        <ButtonWrapper>
          <Button
            className={classes.viewSummaryButton}
            variant="contained"
            onClick={() => {
              history.push(`/order/${orderDetails?.id}`)
              ReactGA.event({
                category: 'Sidebar',
                action: 'Click',
                label: 'View Order Details',
              })
            }}
          >
            View Details
          </Button>
        </ButtonWrapper>
      </OrderHistoryWrapper>
    </OrderDeatilWrapper>
  )
}

export default OrderCard
