import * as React from 'react'
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={54} height={59} fill="none" {...props}>
    <path
      fill="#169B62"
      d="M24.511 39.64c-11.782 20.846-.225.397-10.151 17.958a1.105 1.105 0 0 1-1.975-.103l-2.861-6.58a1.105 1.105 0 0 0-1.158-.654l-7.113.94a1.105 1.105 0 0 1-1.108-1.638l10.152-17.958 7.107 4.018 7.107 4.016ZM52.125 51.2l-7.114-.94a1.105 1.105 0 0 0-1.158.655l-2.86 6.58c-.364.836-1.527.897-1.975.103l-5.242-9.271-1.589-2.811-3.321-5.877 7.107-4.017 7.107-4.017 10.152 17.958a1.105 1.105 0 0 1-1.107 1.638Z"
    />
    <path
      fill="#127D4F"
      d="m17.406 35.623 7.107 4.017-4.915 8.695c-4.002.153-7.948-3.014-7.706-7.754A2.658 2.658 0 0 0 9.1 37.789a7.605 7.605 0 0 1-2.18-.207l3.378-5.977 7.108 4.018Zm29.056 1.961a7.512 7.512 0 0 1-2.182.205 2.658 2.658 0 0 0-2.791 2.792c.1 2.058-.603 3.986-1.983 5.435a7.478 7.478 0 0 1-5.729 2.31l-1.588-2.81-3.322-5.877 7.107-4.017 7.108-4.017 3.38 5.98Z"
    />
    <path
      fill="#1ED688"
      d="M44.4 35.438c-2.94-.156-5.414 2.276-5.263 5.262.233 4.741-5.58 7.1-8.726 3.615a4.996 4.996 0 0 0-3.721-1.654 4.996 4.996 0 0 0-3.721 1.654c-3.16 3.5-8.967 1.096-8.726-3.615.156-2.939-2.276-5.414-5.263-5.262-4.74.232-7.099-5.58-3.615-8.726a5.012 5.012 0 0 0 0-7.442C1.863 16.107 4.27 10.3 8.98 10.543c2.94.156 5.414-2.275 5.263-5.262-.232-4.729 5.572-7.108 8.726-3.615a5.013 5.013 0 0 0 7.442 0c3.163-3.502 8.968-1.095 8.726 3.615-.156 2.94 2.276 5.414 5.263 5.262 4.74-.232 7.099 5.581 3.615 8.727a5.012 5.012 0 0 0 0 7.442c3.502 3.162 1.094 8.968-3.615 8.726Z"
    />
    <path
      fill="#35E298"
      d="M44.398 35.438c-2.94-.156-5.414 2.276-5.262 5.262.232 4.741-5.581 7.1-8.726 3.615a4.996 4.996 0 0 0-3.722-1.654 4.996 4.996 0 0 0-3.72 1.654 5.03 5.03 0 0 1-1.827 1.302c-1.853-.734-3.284-2.53-3.162-4.917.156-2.939-2.275-5.414-5.263-5.262-4.74.232-7.099-5.58-3.615-8.726a4.993 4.993 0 0 0 1.654-3.721c0-1.363-.55-2.726-1.654-3.721-3.501-3.163-1.094-8.969 3.615-8.727 2.94.156 5.415-2.275 5.263-5.262-.095-1.722.736-3.765 1.611-5.272 1.208.073 2.415.591 3.377 1.657a5.013 5.013 0 0 0 7.443 0c3.162-3.502 8.968-1.095 8.726 3.615-.157 2.94 2.275 5.414 5.262 5.262 4.74-.232 7.1 5.581 3.615 8.727a5.012 5.012 0 0 0 0 7.442c3.502 3.162 1.095 8.968-3.615 8.726Z"
    />
    <path
      fill="#169B62"
      d="M42.785 22.99c0 8.02-5.866 14.672-13.541 15.895-.845.135-1.7.203-2.555.202-8.89 0-16.097-7.207-16.097-16.096 0-8.89 7.207-16.097 16.096-16.097.87 0 1.724.07 2.556.202 7.675 1.224 13.541 7.875 13.541 15.895Z"
    />
    <path
      fill="#1AB975"
      d="M42.786 22.99c0 8.02-5.866 14.672-13.541 15.895-7.676-1.223-13.542-7.874-13.542-15.894S21.57 8.32 29.244 7.096c7.676 1.224 13.542 7.875 13.542 15.895Z"
    />
    <path
      fill="#FFD993"
      d="M37.426 23.03v5.786a4.536 4.536 0 0 1-4.523 4.523H23.99a2.136 2.136 0 0 1-2.13-2.13v-9.805c0-1.171.959-2.13 2.13-2.13h1.941a.628.628 0 0 0 .485-.229l4.57-5.515c.784-.953 2.178-1.182 3.19-.475a2.355 2.355 0 0 1 .47 3.42l-1.466 1.78a.628.628 0 0 0 .49 1.02 3.756 3.756 0 0 1 3.757 3.755Z"
    />
    <path
      fill="#FFA90B"
      d="M15.953 31.517V20.803c0-.739.599-1.337 1.337-1.337h2.989c.87 0 1.58.712 1.58 1.582v10.224c0 .87-.71 1.581-1.58 1.581h-2.99a1.337 1.337 0 0 1-1.336-1.336Z"
    />
  </svg>
)
export default SvgComponent
