import { FilterSection, Title, RadioSection } from '../../styles/searchProducts'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

const FilterContainer = ({ onPriceRangeChange, priceRange, setOpen }) => {
  return (
    <FilterSection>
      <Title>Price Range</Title>
      <RadioSection>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={'All Price'}
          name="radio-buttons-group"
          onChange={onPriceRangeChange}
          value={priceRange}
          onClick={() => setOpen(false)}
        >
          <FormControlLabel value="All Price" control={<Radio className="radio-group" />} label="All Price" />
          <FormControlLabel value="Under ₹20" control={<Radio className="radio-group" />} label="Under ₹20" />
          <FormControlLabel value="Under ₹100" control={<Radio className="radio-group" />} label="Under ₹100" />
          <FormControlLabel value="Under ₹500" control={<Radio className="radio-group" />} label="Under ₹500" />
          <FormControlLabel value="Under ₹1000" control={<Radio className="radio-group" />} label="Under ₹1000" />
          <FormControlLabel value="Under ₹10,000" control={<Radio className="radio-group" />} label="Under ₹10,000" />
          <FormControlLabel value="Above ₹10,000" control={<Radio className="radio-group" />} label="Above ₹10,000" />
        </RadioGroup>
      </RadioSection>
    </FilterSection>
  )
}

export default FilterContainer
