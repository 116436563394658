import styled from 'styled-components'

export const LoaderWrapper = styled.div``
export const Loader = styled.img`
  /* height: 200px; */
  width: 120px;
  @media (max-width: 767px) {
    height: 80px;
    width: 80px;
  }
`
