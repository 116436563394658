import { makeStyles } from '@mui/styles'
import palette from '../../utils/Theme/palette'
import { styled } from 'styled-components'
import { theme } from 'styles/Theme/theme'

const useStyles = makeStyles({
  header: {
    background: palette.common.white,
    padding: '20px 100px 15px 100px !important',
  },
  headerTypo: {
    fontWeight: '600 !important',
    lineHeight: '18px !important',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  bodyContainer: {
    background: '#F9F9F9 !important',
    padding: '20px 100px !important',
    // height: '100 !important'
  },
  stepRoot: {
    marginTop: '24px !important',
    borderBottom: '1px solid #C8C8C8',
  },
  stepContent: {
    borderLeft: 'none !important',
    padding: '32px !important',
    marginLeft: '16px !important',
  },
  stepContentHidden: {
    borderLeft: 'none !important',
    padding: '16px !important',
    marginLeft: '16px !important',
  },
  stepLabel: {
    alignItems: 'start !important',
    '& .MuiStepLabel-labelContainer': {
      marginLeft: '16px !important',
    },
  },
  labelTypo: {
    fontWeight: '600 !important',
    lineHeight: '20px !important',
  },

  summaryCard: {
    borderRadius: '6px !important',
    border: '1px solid #E8E8E8',
    background: `${palette.common.white} !important`,
    boxShadow: 'none !important',
    padding: '16px 20px !important',
  },
  summaryItemContainer: {
    display: 'flex',
    marginBottom: '10px',
  },
  summaryDeliveryItemContainer: {
    display: 'flex',
    marginBottom: '5px',
  },
  summaryQuoteItemContainer: {
    display: 'flex',
    gap: '10px',
    marginBottom: '3px',
  },
  summaryItemLabel: {
    // flex: 1,
    fontSize: '14px !important',
    fontWeight: '400 !important',
    // marginTop: '10px !important',
  },
  summaryDeliveryLabel: {
    flex: 1,
    fontSize: '14px !important',
    fontWeight: '500 !important',
    color: `${theme.GREYCOLOR}`,
  },
  summaryCustomizationLabel: {
    flex: 1,
    color: `${theme.CUSTOMIZEDCOLOR} !important`,
    fontSize: '12px !important',
    fontWeight: '600 !important',
    marginLeft: '20px !important',
  },
  summaryItemPriceLabel: {
    flex: 1,
    fontSize: '14px !important',
    fontWeight: '400 !important',
    color: `${theme.GREYCOLOR}`,
    // marginLeft: '10px !important',
  },
  summaryItemDiscountLabel: {
    flex: 1,
    color: `${theme.SUCCESS}`,
    fontSize: '12px !important',
    fontWeight: '600 !important',
    marginLeft: '10px !important',
  },
  summaryCustomizationDiscountLabel: {
    flex: 1,
    color: `${theme.DISCOUNTCOLOR}`,
    fontSize: '12px !important',
    fontWeight: '600 !important',
    marginLeft: '10px !important',
  },
  summaryItemTaxLabel: {
    flex: 1,
    color: `${theme.TERTIARYERRORCOLOR}`,
    fontSize: '12px !important',
    fontWeight: '600 !important',
    marginLeft: '10px !important',
  },
  summaryCustomizationTaxLabel: {
    flex: 1,
    color: `${theme.CUSTOMIZATIONLABELCOLOR}`,
    fontSize: '12px !important',
    fontWeight: '600 !important',
    marginLeft: '30px !important',
  },
  summaryCustomizationPriceLabel: {
    flex: 1,
    color: `${theme.CUSTOMIZEDCOLOR} !important`,
    fontSize: '12px !important',
    fontWeight: '600 !important',
    marginLeft: '30px !important',
  },
  summaryItemPriceValue: {
    width: '100px !important',
    textAlign: 'right',
    fontSize: '12px !important',
    fontWeight: '500 !important',
    color: `${theme.PRIMARYTEXTCOLORDARK}`,
  },
  summaryItemQuantityLabel: {
    flex: 1,
    fontSize: '12px !important',
    fontWeight: '600 !important',
    color: `${theme.PRIMARYTEXTCOLOR}`,
  },
  summaryItemQuantityValue: {
    width: '120px !important',
    textAlign: 'right',
    fontSize: '12px !important',
    fontWeight: '600 !important',
    color: `${theme.PRIMARYTEXTCOLORDARK}`,
  },
  marginBottom10: {
    marginBottom: '10px !important',
  },
  marginTop10: {
    marginTop: '10px !important',
  },
  marginTop20: {
    marginTop: '20px !important',
  },
  summaryCustomizationPriceValue: {
    color: `${theme.CUSTOMIZEDCOLOR} !important`,
    width: '100px !important',
    textAlign: 'right',
    fontSize: '12px !important',
    fontWeight: '600 !important',
  },
  summaryItemValue: {
    width: '100px !important',
    textAlign: 'right',
    fontSize: '13px !important',
    fontWeight: '600 !important',
  },
  customizationValue: {
    width: '100px !important',
    textAlign: 'right',
    color: `${theme.CUSTOMIZEDCOLOR} !important`,
  },
  summaryItemLabelDescription: {
    color: `${theme.CUSTOMIZEDCOLOR} !important`,
    marginTop: '2px !important',
    flex: 1,
  },
  subTotalLabel: {
    flex: 1,
    fontWeight: '500 !important',
    fontSize: '14px !important',
    color: `${theme.GREYCOLOR}`,
  },
  summarySubtotalContainer: {
    display: 'flex',
    marginBottom: '0px !important',
    marginTop: '16px !important',
    color: `${theme.PRIMARYTEXTCOLOR} !important`,
  },
  subTotalValue: {
    width: '100px !important',
    textAlign: 'right',
    fontWeight: '600 !important',
    color: `${theme.PRIMARYTEXTCOLOR} !important`,
  },
  totalLabel: {
    flex: 1,
    fontSize: '16px !important',
    fontWeight: '500 !important',
    color: `${theme.SECONDARYERRORCOLOR} `,
  },
  totalValue: {
    width: '100px !important',
    textAlign: 'right',
    fontWeight: '600 !important',
    color: `${theme.SECONDARYERRORCOLOR} `,
  },
  outOfStockDivider: {
    height: '1px',
    backgroundColor: `${theme.DIVIDERCOLOR}`,
    marginTop: '20px',
    marginBottom: '20px',
  },
  divider: {
    height: '1px',
    backgroundColor: `${theme.DIVIDERCOLOR}`,
    margin: '12px 0',
    // marginBottom: "20px",
  },
  orderTotalDivider: {
    height: '1px',
    backgroundColor: `${theme.DIVIDERCOLOR}`,
    marginTop: '12px',
  },
  proceedToBuy: {
    marginTop: '8px !important',
  },
  '@media (max-width: 600px)': {
    summaryCard: {
      padding: '10px !important',
      boxShadow: 'unset !important',
    },
    divider: {
      marginTop: '6px',
    },
    summaryItemLabel: {
      fontSize: '14px !important',
    },
  },
})

export const BillingText = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
  @media (max-width: 450px) {
    margin-bottom: 0;
  }
`
export const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  width: max-content;
  color: ${theme.PRIMARYTEXTCOLORDARK};
  min-width: 110px;
  .asterik {
    color: ${theme.SECONDARYERRORCOLOR};
  }
`
export const ErrorMessage = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.ERROR};
  padding: 2px 0;
  text-align: start;
`
export const ItemsWrapper = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 3px;
`
export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 450px) {
    padding: 6px 0;
  }
`
export const DeliveryModeItemsWrapper = styled.div`
  display: flex;
  gap: 12px;
`

export const DeliveryModeImageWrapper = styled.div`
  border-right: 1px solid ${theme.GREYCOLORDARK};
  padding: 8px;
  img {
    max-width: 64px;
    min-width: 64px;
    height: 64px;
    object-fit: contain;
  }
`
export const ItemImageWrapper = styled.div`
  padding: 8px;
  img {
    max-width: 64px;
    min-width: 64px;
  }
`
export const InputFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 56px;
`
export const Content = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${theme.GREYCOLOR};
  margin-bottom: 20px;
`
export const Note = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${theme.GREYCOLOR};
  margin-bottom: 5px;
  margin-top: 5px;
`
export const BillingContainer = styled.div`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  margin: 150px 50px 30px;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    margin: 180px 16px 16px 16px;
    gap: 0;
  }
`
export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 60%;
  @media screen and (max-width: 1050px) {
    max-width: 100%;
  }
`
export const SummaryContainer = styled.div`
  width: 100%;
  max-width: 35%;
  button {
    background: ${theme.PRIMARYCOLOR};
    text-transform: none;
    &:hover {
      background: ${theme.PRIMARYCOLOR};
    }
  }

  @media screen and (max-width: 1050px) {
    max-width: 100%;
    margin-top: 20px;
  }
`

export const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 4px 8px;
`
export const ProductDeliveryModeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  border: 1px solid ${theme.SECONDARYBORDERCOLOR};
  border-radius: 8px;
  padding: 10px;
`

export const UserContent = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 30px;
`
export const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  input {
    padding: 10px;
    font-size: 12px;
    background-color: ${theme.SECONDARYTEXTCOLOR};
    color: ${theme.GREYCOLOR};
  }
  .phone-input {
    input {
      width: 100% !important;
    }
  }
`
export const ButtonContainer = styled.button`
  background: ${theme.PRIMARYCOLOR};
  color: ${theme.SECONDARYTEXTCOLOR};
  padding: 10px 20px;
  font-size: 12px;
  border-radius: 4px;
  border: none;
  max-width: 300px;
`
export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
  @media screen and (max-width: 450px) {
    grid-template-columns: auto;
  }
  input {
    border-radius: 10px;
    font-size: 13px;
  }
  .disabled {
    background: ${theme.SECONDARYTEXTCOLOR} !important;
  }
`
export const ModalButtonWrapper = styled.div`
  margin: '5px 0';
  button {
    font-size: 13px;
    text-transform: capitalize;
    padding: 5px 12px;
    min-width: 120px;
  }
  .address_button {
    background: ${theme.PRIMARYCOLOR} !important;
    color: ${theme.SECONDARYTEXTCOLOR};
    :hover {
      box-shadow:
        0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    }
  }
`
export const MapWrapper = styled.div`
  width: 100%;
  height: 300px;
  transition: ease-in-out 1s;
`
export const AddressContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  display: grid;
  gap: 30px;
  margin-bottom: 40px;
  button {
    font-size: 13px;
    text-transform: capitalize;
    padding: 5px 12px;
    min-width: 120px;
  }
  .address_button {
    background: ${theme.PRIMARYCOLOR} !important;
    color: ${theme.SECONDARYTEXTCOLOR};
    :hover {
      box-shadow:
        0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    }
  }
  .cancel_button {
    padding: 4px 10px;
    color: #f40012;
    border: 1px solid #f40012;
  }
  .MuiButtonBase-root-MuiRadio-root {
    padding: 3px 0;
  }
  @media (max-width: 767px) {
    gap: 10px;
  }
`
export const UserInformationWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  background: ${theme.TERTIARYBACKGROUNDCOLOR};
  padding: 16px;
  border-radius: 8px;
  gap: 10px;
  @media (max-width: 450px) {
    grid-template-columns: none;
  }
`
export const AddressInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid ${theme.TERTIARYBORDERCOLOR};
  border-radius: 8px;
  padding: 16px;
`
export const AddressWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  .locality-wrapper {
    height: 36px !important;
    font-size: 12px !important;
    &:disabled {
      cursor: not-allowed;
    }
  }

  @media (max-width: 450px) {
    grid-template-columns: unset;
  }
`
export const ItemsDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 30px;
`
export const AddressDetailSection = styled.div`
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  max-width: 232px;
  height: 163px;
  width: 100%;
  border-radius: 8px;
  background: ${theme.TERTIARYBACKGROUNDCOLOR};
  padding: 12px;
  @media (max-width: 450px) {
    max-width: 100%;
  }
`
export const UserAddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  width: 100%;
  .address-wrapper {
    display: flex !important;
    flex-direction: unset !important;
    flex-wrap: wrap !important;
    gap: 20px !important;
    width: 100% !important;

    label {
      margin-right: unset !important;
    }
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    gap: 2px !important;
    display: flex !important;
    justify-content: flex-start !important;
    width: 100% !important;
  }
  .slick-prev:before {
    display: none;
  }
  .slick-next:before {
    display: none;
  }
  .slick-prev {
    color: ${theme.SECONDARYTEXTCOLOR};
  }
  .slick-next {
    color: ${theme.SECONDARYTEXTCOLOR};
  }
  /* .slick-list {
    margin-left: 20px;
  } */
  @media (max-width: 1500px) {
    .slick-list {
      margin-left: 5px;
    }
    .slick-track {
      gap: 15px !important;
      left: -15px !important;
    }
  }
  @media (max-width: 450px) {
    padding-bottom: 20px;
  }
`
export const IconButton = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;

  svg > path {
    stroke: ${theme.PRIMARYCOLOR};
    width: 20px;
    height: 20px;
  }
  @media (max-width: 767px) {
    display: none;
  }
`
export const AddWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid ${theme.PRIMARYCOLOR};
`
export const AddressDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  .slick-prev:before {
    display: none;
  }
  .slick-next:before {
    display: none;
  }
  .slick-prev {
    color: ${theme.SECONDARYTEXTCOLOR};
  }
  .slick-next {
    color: ${theme.SECONDARYTEXTCOLOR};
  }
  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    gap: 20px;
    @media (max-width: 767px) {
      justify-content: center;
    }
  }
`

export const ShippingTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
export const SliderWrapper = styled.div`
  height: 200px;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 767px) {
    width: 100%;
    margin: 0;
    height: 150px;
  }
`
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  button {
    border: 1px solid ${theme.PRIMARYCOLOR};
    height: 36px;
    color: ${theme.SECONDARYTEXTCOLOR};
    font-weight: 500;
    cursor: pointer;
    text-transform: none;
  }
  @media (max-width: 450px) {
    padding-top: 0;
  }
`
export const UserName = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
`
export const AddressDetail = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
  max-width: 220px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const ButtonSection = styled.div`
  display: flex;
  gap: 10px;
  padding: 16px 0 0 0;
  button {
    width: 100px;
    border-radius: 4px;
    text-transform: none;
    font-weight: 700;
  }
  .edit-button {
    border: 1px solid ${theme.PRIMARYCOLOR};
  }
`
export const DetailWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const ItemNameWrapper = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
`
export const SummarCardWrapper = styled.div`
  border: 1px solid ${theme.SECONDARYBORDERCOLOR};
  border-radius: 8px;
`
export const HeadingWrapper = styled.div`
  padding: 20px 20px 0 20px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
`
export const SubtotalContainer = styled.div`
  padding: 10px 20px 20px 17px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0;
`
export const DeliveryChargesWrapper = styled.div`
  padding: 20px 20px 0 20px;
  background: ${theme.SECONDARYBACKGROUNDCOLOR};
`
export const OrderTotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0;
  background: ${theme.SECONDARYBACKGROUNDCOLOR};
`
export const ProceedButtonWrapper = styled.div`
  padding: 0 20px 20px 20px;
  background: ${theme.SECONDARYBACKGROUNDCOLOR};
`
export const ChargesTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.PRIMARYGREYCOLOR};
`
export default useStyles

// summarySubtotalContainer: {
//   display: 'flex',
//   marginBottom: '0px !important',
//   marginTop: '16px !important',
// },
