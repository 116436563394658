import React, { useState, useContext, useEffect } from 'react'
import { getCall } from '../../../../api/axios'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Radio from '../../../common/Radio'
import useStyles from './style'
import TextField from '@mui/material/TextField'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { ToastContext } from '../../../../context/toastContext'
import { LocationContext } from '../../../../context/locationContext'
import { toast_actions, toast_types } from '../../../shared/toast/utils/toast'
import { PinCodeContainer, ButtonWrapper } from '../../../../styles/navbar'

const SelectAddress = ({ onClose, currentPincode }) => {
  const classes = useStyles()
  const [localAddress, setLocalAddress] = useState(JSON.parse(localStorage.getItem('address')))
  const { setLocation } = useContext(LocationContext)
  const [pinCode, setPinCode] = useState()
  const dispatch = useContext(ToastContext)
  const [validatePinCode, setValidatePinCode] = useState(false)
  const [error, setError] = useState({
    areaCode_error: '',
  })

  const getPinCode = localStorage.getItem('pinCode')

  const checkPinCode = () => {
    setError((error) => ({
      ...error,
      areaCode_error: '',
    }))

    let pin = pinCode !== undefined ? pinCode : getPinCode ? getPinCode : currentPincode

    if (!pin || pin?.length !== 6 || !/^\d+$/.test(pin)) {
      setError((error) => ({
        ...error,
        areaCode_error: 'Pin code must contain only 6 digits',
      }))
      return false
    }

    return true
  }

  const getPinCodeAddressRequest = async () => {
    try {
      const data = await getCall(
        `/clientApis/v2/map/getCordinates?postcode=${pinCode ? pinCode : getPinCode ? getPinCode : currentPincode}`,
      )
      setLocation(data?.data?.latitude, data?.data?.longitude)
      localStorage.setItem('pinCode', pinCode ? pinCode : getPinCode ? getPinCode : currentPincode)
      localStorage.setItem('latAndLng', data?.data?.latitude.toFixed(6), data?.data?.longitude.toFixed(6))
      if (data) {
        onClose()
      }

      return data
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: 'Invalid Pin Code',
        },
      })
      onClose()
    }
  }

  const handleDeleteAddress = () => {
    localStorage.removeItem('address')
    setLocalAddress(null)
  }

  const handleSubmit = () => {
    setValidatePinCode(true)
    if (checkPinCode()) {
      getPinCodeAddressRequest()
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [pinCode])

  return (
    <Grid container spacing={3}>
      {localAddress && (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FormControl className={classes.formControlRoot}>
            <div className={classes.selectAddressRadioContainer}>
              <FormControlLabel
                className={classes.formControlLabel}
                control={<Radio />}
                label={
                  <div>
                    <Typography variant="h5">
                      {localAddress?.tag ? `${localAddress?.tag} (${localAddress?.name})` : localAddress?.name}
                    </Typography>
                    <Typography variant="body1">{`${localAddress?.email} - ${localAddress?.phone}`}</Typography>
                    <Typography variant="body1">
                      {localAddress?.street ? localAddress?.street : localAddress?.door}, {localAddress?.city}
                      {localAddress?.state}
                    </Typography>
                    <Typography variant="body1">{localAddress?.areaCode}</Typography>
                  </div>
                }
              />
              <Button
                variant="text"
                startIcon={<DeleteOutlineIcon size="small" />}
                color="error"
                onClick={handleDeleteAddress}
              >
                <Typography>Delete</Typography>
              </Button>
            </div>
          </FormControl>
        </Grid>
      )}

      <PinCodeContainer>
        <TextField
          required
          fullWidth
          id="pin_code-input"
          name="pin_code"
          placeholder="Enter Pin Code"
          type="text"
          className="pincode"
          value={pinCode !== undefined ? pinCode : getPinCode ? getPinCode : currentPincode}
          inputProps={{
            maxLength: 6,
          }}
          onChange={(event) => {
            const regexp = /^[0-9]+$/
            if (!regexp.test(event.target.value) && event.target.value !== '') return
            const areaCode = event.target.value
            setPinCode(areaCode)
            setError((error) => ({
              ...error,
              areaCode_error: '',
            }))
          }}
          error={!!(validatePinCode && error.areaCode_error)}
          helperText={(validatePinCode && error.areaCode_error) || ''}
          onBlur={() => {
            if (validatePinCode) {
              checkPinCode()
            }
          }}
        />
        <ButtonWrapper>
          <Button variant="outlined" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </ButtonWrapper>
      </PinCodeContainer>
    </Grid>
  )
}

export default SelectAddress
