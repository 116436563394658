import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ReactGA from 'react-ga4'
import Button from '@mui/material/Button'
import useGet from 'hooks/useGet'
import { deleteWithAuthentication, postLoginCall } from 'api/axios'
import { getOrCreateDeviceId, getUserId } from 'helper'
import { toast_actions, toast_types } from 'components/shared/toast/utils/toast'
import { ToastContext } from 'context/toastContext'
import { CartContext } from 'context/cartContext'
import CustomizationRenderer from 'components/application/product-list/product-details/CustomizationRenderer'
import NoDataFound from 'views/EmptyData'
import OutOfStock from 'components/outOfStock'

import ProductImage from 'assets/images/no_image_found.png'
import WishlistFilledIcon from 'assets/svg/WishlistFilledIcon'
import {
  Container,
  Wrapper,
  WishlistTitle,
  WishlistSection,
  WishlistProductSection,
  WishlistCard,
  OptionSection,
  WishlistIconSection,
  ProductImageSection,
  ProductName,
  ButtonSection,
  NoDataWrapper,
  NoDataWishlistSection,
} from 'styles/MobileWishlist'

const MobileWishlist = () => {
  const history = useHistory()
  const dispatch = useContext(ToastContext)
  const [customization_state, setCustomizationState] = useState({})
  const [deviceId, setDeviceId] = useState('')
  setCustomizationState
  const userId = getUserId()

  const { fetchCartItems } = useContext(CartContext)

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search, title: 'Wishlist' })
  }, [])
  useEffect(() => {
    let id = ''
    const fetchId = async () => {
      id = await getOrCreateDeviceId()
      setDeviceId(id)
    }

    fetchId()
  }, [])
  const { refetch: fetchWishlist, data: wishlistData } = useGet(
    'wishlist',
    `/clientApis/v2/wishlist/${userId}/${deviceId}`,
  )

  const handleClick = (productId) => {
    history.push(`/products?productId=${productId}`)
  }

  useEffect(() => {
    if (deviceId) fetchWishlist()
  }, [])

  const handleDelete = async (id) => {
    try {
      const url = `/clientApis/v2/wishlist/${userId}/${id}`
      await deleteWithAuthentication(url)
      fetchWishlist()
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.success,
          message: 'item has been removed successfully!',
        },
      })
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: error?.response?.data?.error?.message,
        },
      })
    }
  }

  let selectedCustomizationIds = []

  const getCustomization_ = (groupId) => {
    let group = customization_state[groupId]
    if (!group) return

    group.selected.map((s) => selectedCustomizationIds.push(s.id))
    group?.childs?.map((child) => {
      getCustomization_(child)
    })
  }

  const getCustomizations = (product) => {
    if (!product?.customisation_items?.length) return null
    const customizations = []

    const firstGroupId = customization_state['firstGroup']?.id

    if (!firstGroupId) return
    getCustomization_(firstGroupId)

    for (const cId of selectedCustomizationIds) {
      let c = product?.customisation_items.find((item) => item.local_id === cId)
      if (c) {
        c = {
          ...c,
          quantity: {
            count: 1,
          },
        }
        customizations.push(c)
      }
    }

    return customizations
  }

  const handleAddToCart = async (product) => {
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: 'Add to wishlist',
    })
    // const customCartId = localStorage.getItem('custom_cart_id')

    // if (!customCartId) {
    //   const randomID = await generateUniqueBrowserId()
    //   localStorage.setItem('custom_cart_id', randomID)
    // }
    const deviceId = await getOrCreateDeviceId()

    const url = `/clientApis/v2/cart/${userId}/${deviceId}`

    // const subtotal = product?.item_details?.price?.value

    // Pass getCustomizations(product) directly to the payload
    const payload = {
      customisations: getCustomizations(product),
      hasCustomisations: !!getCustomizations(product),
      customisationState: customization_state,
      local_id: product?.item?.local_id,
      id: product?.item?.id,
      provider: {
        id: product?.item?.provider?.id,
      },
      quantity: {
        count: 1,
      },
    }

    try {
      const data = await postLoginCall(url, payload)
      if (data) {
        fetchCartItems()
        localStorage.setItem('cartItems', JSON.stringify(data))
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.success,
            message: 'Item added to cart successfully.',
          },
        })
      }
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message:
            'Unfortunately, We’re experiencing some technical issues while adding items to your cart. Please bear with us & get back to us sometime.',
        },
      })
    }

    try {
      const url = `/clientApis/v2/wishlist/${userId}/${product._id}`
      await deleteWithAuthentication(url)
      fetchWishlist()
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: error?.response?.data?.error?.message,
        },
      })
    }
  }

  return (
    <Container>
      <Wrapper>
        <WishlistTitle>Wishlist</WishlistTitle>
        <WishlistSection className={wishlistData && wishlistData.length > 0 ? '' : 'no-data'}>
          {wishlistData && wishlistData?.length > 0 ? (
            wishlistData?.map((item, index) => {
              return (
                <WishlistProductSection key={index}>
                  <WishlistCard onClick={() => handleClick(item?.item?.id)}>
                    <OptionSection>
                      {item?.item?.product?.quantity?.available?.count === '0' ? <OutOfStock /> : ''}
                      <WishlistIconSection>
                        <WishlistFilledIcon
                          onClick={(event) => {
                            event.stopPropagation()
                            handleDelete(item._id)
                          }}
                        />
                      </WishlistIconSection>
                    </OptionSection>

                    <ProductImageSection>
                      <img
                        src={
                          item?.item?.product?.descriptor?.images[0]
                            ? item?.item?.product?.descriptor?.images[0]
                            : ProductImage
                        }
                        alt="Image"
                        onError={(e) => {
                          e.target.src =
                            'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                        }}
                      />
                    </ProductImageSection>
                  </WishlistCard>
                  <ProductName>{item?.item?.product?.descriptor?.name}</ProductName>
                  <ButtonSection>
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleAddToCart(item)
                      }}
                      disabled={item?.item?.product?.quantity?.available?.count === '0'}
                    >
                      Add to cart
                    </Button>
                  </ButtonSection>
                </WishlistProductSection>
              )
            })
          ) : (
            <NoDataWishlistSection>
              <NoDataWrapper>
                <NoDataFound />
              </NoDataWrapper>
            </NoDataWishlistSection>
          )}
        </WishlistSection>
        <CustomizationRenderer
          customization_state={customization_state}
          setCustomizationState={setCustomizationState}
        />
      </Wrapper>
    </Container>
  )
}

export default MobileWishlist
