import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const NoResultContainer = styled.div`
  width: 100%;
  padding: 100px;
`
export const NoResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-top: 40px;
  button {
    background-color: ${theme.PRIMARYCOLOR} !important;
    text-transform: none;
  }
  @media (max-width: 767px) {
    img {
      width: 70px;
      height: 82px;
    }
  }
`
export const TextHeading = styled.h1`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: ${theme.PRIMARYTEXTCOLORDARK};
  @media (max-width: 450px) {
    font-size: 21px;
    margin-bottom: 0;
  }
`
export const HeadingWrapper = styled.div`
  max-width: 500px;
`
export const SubHeading = styled.h2`
  font-size: 14px;
  font-weight: 400;
  color: ${theme.SECONDARYTEXTCOLORDARK};
  text-align: center;
`
