import * as React from 'react'
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={18} fill="none" {...props}>
    <path
      fill="#115469"
      d="M18.126 11.269a3.029 3.029 0 0 0-3.025 3.025 3.029 3.029 0 0 0 3.025 3.025 3.028 3.028 0 0 0 3.025-3.025 3.029 3.029 0 0 0-3.025-3.025Zm0 4.538a1.514 1.514 0 0 1-1.513-1.513c0-.834.679-1.512 1.513-1.512.834 0 1.513.678 1.513 1.512s-.679 1.513-1.513 1.513ZM7.79 11.269a3.029 3.029 0 0 0-3.025 3.025A3.029 3.029 0 0 0 7.79 17.32a3.029 3.029 0 0 0 3.025-3.025A3.029 3.029 0 0 0 7.79 11.27Zm0 4.538a1.514 1.514 0 0 1-1.513-1.513c0-.834.679-1.512 1.513-1.512.834 0 1.512.678 1.512 1.512s-.678 1.513-1.512 1.513ZM20.163 2.61a.757.757 0 0 0-.676-.417h-3.983v1.513h3.517l2.06 4.096 1.351-.68-2.269-4.512Z"
    />
    <path
      fill="#115469"
      stroke="#115469"
      d="M21.959 7.33a.254.254 0 0 0-.202-.099H15.76V1.437a.256.256 0 0 0-.256-.256H2.9a.256.256 0 1 0 0 .512h12.349v5.794c0 .142.115.257.256.257h6.127l.15.193 1.101 1.419.105.135v4.571h-2.592a.256.256 0 0 0 0 .514h2.849a.256.256 0 0 0 .256-.257V9.403a.258.258 0 0 0-.054-.157L21.96 7.33Zm0 0 1.487 1.916L21.96 7.33ZM5.727 10.487a.256.256 0 0 1-.256.257H1.99a.256.256 0 1 1 0-.513h3.48c.141 0 .256.115.256.256Zm4.907 3.576h4.723v.513h-4.723v-.513Zm-7.735 0h2.622a.256.256 0 1 1 0 .513H2.899a.256.256 0 1 1 0-.513ZM.756 7.256H7.21a.256.256 0 1 1 0 .513H.756a.256.256 0 1 1 0-.513Z"
    />
    <path fill="#115469" d="M8.445 3.782H1.992a.756.756 0 1 0 0 1.512h6.453a.756.756 0 1 0 0-1.512Z" />
  </svg>
)
export default SvgComponent
