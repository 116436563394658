import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const FaqSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export const FaqSectionFrame = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 62px;
  @media (max-width: 767px) {
    width: 100%;
  }
  @media only screen and (min-width: 768px) and (max-width: 1224px) {
    width: 80%;
  }
`



export const FaqAccordianContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 39px;
  flex-wrap: wrap;
  /* max-width: 1000px; */

  @media (max-width: 767px) {
    width: 100%;
    gap: 12px;
  }
  @media only screen and (min-width: 768px) and (max-width: 1224px) {
    flex-direction: column;
  }
`

export const FaqAccordian = styled.div`
  background: ${(props) => (props.isOpen ? '' : '#F6F6F6')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 12px;
  cursor: pointer;
  width: calc(50% - 20px);
  box-sizing: border-box;
  flex-shrink: 0;
  border: 1px solid ${theme.SECONDARYBORDERCOLOR};
  margin-bottom: ${(props) => (props.isOpen ? '20px' : '0')};
  @media (max-width: 767px) {
    width: 100%;
  }
  @media only screen and (min-width: 768px) and (max-width: 1224px) {
    width: 100%;
  }
`

export const FaqAccordianHeading = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  gap: 12px;
  background: ${(props) => (props.isOpen ? '#F6F6F6' : '')};
  border-radius: 12px 12px 0 0;
  padding: 20px;
`

export const FaqAccordianTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  color: ${theme.PRIMARYCOLOR};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  word-break: break-word;

  @media (max-width: 767px) {
    font-size: 16px;
    word-break: break-word;
  }
  @media only screen and (min-width: 768px) and (max-width: 1224px) {
    font-size: 16px;
    word-break: break-word;
  }
`

export const FaqAccordianContent = styled.div`
  color:${theme.PRIMARYGREYCOLOR};
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  width: 100%;
  padding: 10px 20px;
  word-break: break-word;
`

export const FaqLists = styled.li`
  text-decoration: none;
  list-style: none;

  .title {
    text-decoration: underline;
  }
`
