import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const OrderModalWrapper = styled.div`
  .address-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
`
export const ProductDetailWrapper = styled.div`
  display: flex;
  gap: 20px;
  border-bottom: 1px solid ${theme.SECONDARYBORDERCOLOR};
  padding-bottom: 20px;

  @media (max-width: 450px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }
`
export const ProductImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  max-width: 300px;
  width: 100%;
  img {
    width: 190px;
    height: 190px;
    object-fit: contain;
  }

  /* min-width: 300px;
  max-width: 300px;
  width: 100%;
  img {
    width: 300px;
    height: 190px;
    object-fit: cover;
  } */
`
export const ProductDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  max-width: 550px;
  min-width: 550px;
  width: 100%;
  @media (max-width: 450px) {
    min-width: 300px;
    max-width: 350px;
  }
`
export const ProductName = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
`
export const ProductPricingSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
export const ProductPrice = styled.div`
  display: flex;
  gap: 10px;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  padding-top: 10px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
  @media (max-width: 450px) {
    padding-top: 0;
  }
`
export const OriginalPrice = styled.div`
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  color: ${theme.PRIMARYGREYCOLOR};
  text-decoration: line-through;
`

export const QuantityWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 1px 24px;
  max-width: 100px;
  width: 100%;
  border: 1px solid ${theme.PRIMARYCOLOR};
  border-radius: 7px;
`

export const QuantityOption = styled.div`
  cursor: pointer;
  color: ${theme.PRIMARYTEXTCOLORDARK};
  font-size: 20px;
`
export const ProductQuantity = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
`
export const InformationSection = styled.div`
  display: flex;
  padding: 10px 0;
  gap: 20px;
  @media (max-width: 450px) {
    flex-direction: column;
  }
`
export const CartSummaryWrapper = styled.div`
  max-width: 300px;
  width: 100%;
  background: ${theme.SECONDARYBACKGROUNDCOLOR};
  border-radius: 12px;
  padding: 15px;
  @media (max-width: 450px) {
    max-width: 100%;
  }
`
export const CartSummaryTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
`
export const DeliveryAddressTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
`
export const CartDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`
export const CartDetailWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const CartDetailTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: ${theme.PRIMARYGREYCOLOR};
  max-width: 175px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const CartDetailContent = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
`
export const PaymentModeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 6px 0;
  border-top: 1px solid ${theme.SECONDARYTEXTCOLOR};
  border-bottom: 1px solid ${theme.SECONDARYTEXTCOLOR};
`
export const PaymentType = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
`
export const PaymentDetailContent = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
`
export const OrderTotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 10px;
`
export const TotalOrderContent = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: ${theme.SECONDARYERRORCOLOR};
`

export const DeliveryAddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const DeliverAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${theme.TERTIARYBACKGROUNDCOLOR};
  border-radius: 8px;
  width: 100%;
  min-height: 200px;
`
export const AddressText = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.SECONDARYBORDERCOLOR};
`
export const AddressListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 150px;
  overflow-y: scroll;
  .radio-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`
export const InfoDetailSection = styled.div`
  display: flex;
  gap: 10px;
  width: 400px;
  @media (max-width: 450px) {
    flex-direction: column;
    gap: 0;
  }
`
export const AddressListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: ${theme.TERTIARYBACKGROUNDCOLOR};
  border-radius: 8px;
  padding: 10px;
`

export const DeliveryModeWrapper = styled.div`
  display: flex;
  height: 100px;
  align-items: center;
  border-bottom: 1px solid ${theme.SECONDARYBORDERCOLOR};
  gap: 50px;
  @media (max-width: 450px) {
    height: unset;
  }
`
export const DeliveryModeContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  gap: 20px;
  background: ${theme.TERTIARYBACKGROUNDCOLOR};
  border-radius: 8px;

  .form-controller {
    margin: 0;
    @media (max-width: 450px) {
      align-items: flex-start !important;
    }
  }
  .radio-button {
    width: 5px;
    height: 5px;
    span {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
`
export const DeliveryModeSection = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 0;
  gap: 20px;
  border-radius: 8px;

  .form-controller {
    margin: 0;
    @media (max-width: 450px) {
      align-items: flex-start !important;
    }
  }
  .radio-button {
    width: 5px;
    height: 5px;
    span {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
`
export const RadioWrapper = styled.div`
  display: flex;
  gap: 10px;
`

export const AddressDetailSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: ${theme.TERTIARYBACKGROUNDCOLOR};
  gap: 12px;
  .form-controller {
    margin: 0;
    @media (max-width: 450px) {
      align-items: flex-start !important;
    }
  }
  .radio-button {
    width: 5px;
    height: 5px;
    span {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
`
export const IconWrapper = styled.div`
  padding-left: 10px;
  svg {
    cursor: pointer;
  }
`

export const UserName = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
  max-width: 100px;
  width: 100%;
`
export const AddressDetail = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
  max-width: 220px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const Pincode = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
`

export const AddressInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid ${theme.TERTIARYBORDERCOLOR};
  border-radius: 8px;
  padding: 12px;
`
export const AddAddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const AddressWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 10px;
  @media (max-width: 450px) {
    display: flex;
    flex-direction: column;
  }
`
export const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  .phone-input {
    input {
      width: 100% !important;
    }
  }
  input {
    padding: 8px;
    font-size: 14px;
    border: 1px solid ${theme.SECONDARYBORDERCOLOR};
  }
  .locality-wrapper {
    height: 36px !important;
    font-size: 12px !important;
    &:disabled {
      cursor: not-allowed;
    }
  }
`
export const ErrorMessage = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.ERROR};
  padding: 2px 0;
  text-align: start;
`
export const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  width: max-content;
  color: ${theme.PRIMARYTEXTCOLORDARK};
  min-width: 110px;
  .asterik {
    color: ${theme.SECONDARYERRORCOLOR};
  }
`
export const InputFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 56px;
  & input[type='number'] {
    -moz-appearance: textfield;
  }

  & input[type='number']::-webkit-outer-spin-button,
  & input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
export const ItemsDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 12px;
  button {
    color: ${theme.SECONDARYTEXTCOLOR};
    font-weight: 500;
    text-transform: none;
  }

  @media (max-width: 450px) {
    margin-bottom: 20px;
  }
`
export const AddressButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  button {
    color: ${theme.SECONDARYTEXTCOLOR};
    font-weight: 500;
    text-transform: none;
  }

  @media (max-width: 450px) {
    margin-bottom: 20px;
  }
`
export const DeliveryMode = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .radio-group {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  @media (max-width: 450px) {
    flex-direction: column;
  }
`

/**** Address Modal CSS *****/
export const AddressModalWrapper = styled.div`
  min-width: 500px;
  max-width: 500px;
  @media (max-width: 600px) {
    min-width: 300px;
    max-width: 350px;
  }
`

export const LoaderWrapper = styled.div`
  display: flex;
  position: absolute;
  gap: 10px;
  top: -500px;
  bottom: 0;
  right: 0;
  left: 0;
  background: ${theme.LOADERBACKGROUNDCOLOR};
  justify-content: center;
  align-items: center;
  z-index: 10000000059;
  backdrop-filter: blur(0.5px);
  background-color: rgba(0, 0, 0, 0.5);
  .loader-icon {
    position: fixed;
  }
  img {
    width: 100%;
    max-width: 100px;
    @media screen and (max-width: 650px) {
      max-width: 80px;
    }
  }
  .loader,
  .loader:after {
    border-radius: 50%;
    width: 16.667vw;
    height: 16.667vw;
  }
  .loader {
    margin: 160px auto;
    position: relative;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`
export const CrossIconWrapper = styled.div`
  padding: 10px;
  display: flex;
  justify-content: end;
  svg {
    cursor: pointer;
  }
`
export const LoaderContainer = styled.div`
  background-color: ${theme.SECONDARYTEXTCOLOR};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 10px;
  height: 170px;
  width: 280px;
  border-radius: 8px;
`
