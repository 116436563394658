import React from 'react'
import BreadcrumbComponent from 'components/Breadcrumbs'
import ContactForm from 'views/ContactUsForm'
import {
  MainWrapper,
  ContactUsWrapper,
  ContactUsHeading,
  ContentWrapper,
  ContactUsContent,
} from 'styles/FooterLinks/ContactUsPage'

const ContactUs = () => {
  return (
    <MainWrapper>
      <ContactUsWrapper>
        <BreadcrumbComponent title="Contact Us" />
        <ContactUsHeading>Contact Us</ContactUsHeading>
        <ContentWrapper>
          <ContactUsContent>
            Service at your fingertips! Easily request a return, and request exchanges via your Order History under
            &quot;My Account.&quot;
          </ContactUsContent>
          <ContactUsContent>
            If you can&#39;t find what you need, our Customer service team is available from Monday to Saturday, 11 am
            to 7 pm, to assist with all your inquiries! (At Bharatham, we prioritize work- life balance for our
            employees, so please note that our support channels are closed on major Indian holidays, including: New
            Year’s Day, Republic Day, Holi, Independence Day, Gandhi Jayanti, Diwali, and Christmas Day.)
          </ContactUsContent>
          <ContactUsContent>
            Please take a moment to fill out the contact form below. We&#39;re here to assist you with any questions or
            concerns you may have. Thank you!
          </ContactUsContent>
        </ContentWrapper>
        <ContactForm />
      </ContactUsWrapper>
    </MainWrapper>
  )
}

export default ContactUs
