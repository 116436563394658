import React from 'react'
import useStyles, { ProductInfoSection, ProductDetailWrapper } from './style'

import Grid from '@mui/material/Grid'
// import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

// import { ReactComponent as CallIcon } from '../../../assets/images/callBrand.svg'
import moment from 'moment/moment'

const CustomerDetails = ({ orderDetails }) => {
  const classes = useStyles()

  const fetchAddress = (address) => {
    const { locality, building, city, state, country } = address
    let addressString = ''
    addressString = `${locality ? `${locality}` : ''} ${building ? `,${building}` : ''} ${city ? `,${city}` : ''} ${
      state ? `,${state}` : ''
    } ${country ? `,${country}` : ''}`
    return addressString
  }

  return (
    // eslint-disable-next-line react/no-unknown-property
    <div className={classes.customerDetails}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="h4" className={classes.customerDetailsTypo}>
          Customer Details
        </Typography>
      </Grid>
      <ProductInfoSection>
        <ProductDetailWrapper>
          <Typography component="div" variant="body" className={classes.customerDetailsLabel}>
            Order Number
          </Typography>
          <Typography component="div" variant="body" className={classes.customerDetailsValue}>
            {orderDetails?.id}
          </Typography>
        </ProductDetailWrapper>
        <ProductDetailWrapper>
          <Typography component="div" variant="body" className={classes.customerDetailsLabel}>
            Payment mode
          </Typography>
          <Typography component="div" variant="body" className={classes.customerDetailsValue}>
            {orderDetails?.payment?.type === 'ON-FULFILLMENT' ? 'Cash on delivery' : 'Prepaid'}
          </Typography>
        </ProductDetailWrapper>
        <ProductDetailWrapper>
          <Typography component="div" variant="body" className={classes.customerDetailsLabel}>
            Customer Name
          </Typography>
          <Typography component="div" variant="body" className={classes.customerDetailsValue}>
            {orderDetails?.billing?.name}
          </Typography>
        </ProductDetailWrapper>
        <ProductDetailWrapper>
          <Typography component="div" variant="body" className={classes.customerDetailsLabel}>
            Phone Number
          </Typography>
          <Typography component="div" variant="body" className={classes.customerDetailsValue}>
            {orderDetails?.billing?.phone}
          </Typography>
        </ProductDetailWrapper>
        <ProductDetailWrapper>
          <Typography component="div" variant="body" className={classes.customerDetailsLabel}>
            Date
          </Typography>
          <Typography component="div" variant="body" className={classes.customerDetailsValue}>
            {`${moment(orderDetails?.createdAt).format('MMMM Do, YYYY')} at ${moment(orderDetails?.createdAt).format(
              'hh:mma',
            )}`}
          </Typography>
        </ProductDetailWrapper>
        <ProductDetailWrapper item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="div" variant="body" className={classes.customerDetailsLabel}>
            Delivered To
          </Typography>
          <Typography component="div" variant="body" className={classes.customerDetailsValue}>
            {orderDetails?.billing?.address ? fetchAddress(orderDetails?.billing?.address) : ''}
          </Typography>
        </ProductDetailWrapper>
        {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Button
          target="_blank"
          variant="outlined"
          className={classes.downloadInvoiceButton}
          href={`${orderDetails?.documents?.[0]?.url}`}
          disabled={orderDetails?.documents == undefined}
        >
          Download Invoice
        </Button>
        <Button variant="outlined" startIcon={<CallIcon />}>
          {`Call ${orderDetails?.provider?.descriptor?.name}`}
        </Button>
      </Grid> */}
      </ProductInfoSection>
    </div>
  )
}

export default CustomerDetails
