import React, { useContext, useRef, useState, useEffect } from 'react'
import styles from '../../../styles/search-product-modal/searchProductModal.module.scss'
import productCartStyles from '../../../styles/products/productCard.module.scss'
import ErrorMessage from '../../shared/error-message/errorMessage'
import { toast_actions, toast_types } from '../../shared/toast/utils/toast'
import { getValueFromCookie } from '../../../utils/cookies'
import { ToastContext } from '../../../context/toastContext'
import useCancellablePromise from '../../../api/cancelRequest'
import { SSE_TIMEOUT } from '../../../constants/sse-waiting-time'
import { postCheckoutCall, getSelectCall } from '../../../api/axios'
import Checkbox from '../../shared/checkbox/checkbox'
import { Select, MenuItem, FormControl } from '@mui/material'

import Subtract from '../../shared/svg/subtract'
import Add from '../../shared/svg/add'
import { RETURN_REASONS } from '../../../constants/cancelation-reasons'
import { Button, Grid, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Input from '../../shared/input/input'
import Loading from '../../shared/loading/loading'
import ProductImage from 'assets/images/no_image_found.png'
import {
  ReturnOrderModalWrapper,
  MainWrapper,
  ProductContainer,
  ReturnWrapper,
  Wrapper,
  ProductImageWrapper,
  ProductDetailWrapper,
  ProductName,
  DetailContainer,
  QuantityWrapper,
  ProductImagesWrapper,
  SupportedImageFormats,
  CountWrapper,
  OptionWrapper,
  ProductPrice,
  ReturnReasonWrapper,
  ReasonTitle,
  // SelectReasonWrapper,
  // PlaceholderText,
  QuantityCountWrapper,
  ProductQuantityCount,
  RefundWrapper,
  RefundText,
  CheckboxWrapper,
  ButtonWrapper,
  MinusWrapper,
  PlusWrapper,
} from 'styles/ReturnOrderModal'

export default function ReturnOrderModal({
  bpp_id,
  transaction_id,
  order_id,
  order_status,
  partailsReturnProductList = [],
  onClose,
  onSuccess,
  quantity,
  bpp_uri,
  // handleFetchUpdatedStatus,
  onUpdateOrder,
  // fulfillments,
}) {
  // STATES
  const [inlineError, setInlineError] = useState({
    selected_id_error: '',
    reason_error: '',
  })
  const [loading, setLoading] = useState(false)
  const [selectedCancelReasonId, setSelectedCancelReasonId] = useState({})
  const [selectedIds, setSelectedIds] = useState([])
  const [orderQty, setOrderQty] = useState([])
  const [reasons, setReasons] = useState([])
  const [errConfirm, setErrConfirm] = useState()
  const [selectedImages, setSelectedImages] = useState({})
  const [correct, setCorrect] = useState(true)
  // const [productCounts, setProductCounts] = useState([])
  const [isChecked, setIsChecked] = useState(true)
  const [disableButton, setDisableButton] = useState(true)

  // REFS
  const cancelPartialEventSourceResponseRef = useRef(null)
  const eventTimeOutRef = useRef([])
  // CONTEXT
  const dispatch = useContext(ToastContext)

  // HOOKS
  const { cancellablePromise } = useCancellablePromise()

  // use this function to check if the list exist or not for partial products
  // to be returned
  function areProductsToBeReturned() {
    return partailsReturnProductList?.length > 0
  }

  useEffect(() => {
    setDisableButton(!(isChecked && checkReason() && handleImageValidation()))
  }, [isChecked, selectedCancelReasonId, selectedImages, selectedIds, inlineError])

  // use this function to dispatch error
  function dispatchToast(message, type) {
    dispatch({
      type: toast_actions.ADD_TOAST,
      payload: {
        id: Math.floor(Math.random() * 100),
        type,
        message,
      },
    })
  }

  // PARTIAL CANCEL APIS
  // use this function to fetch cancel product through events
  // function fetchCancelPartialOrderDataThroughEvents(message_id) {
  //   const token = getValueFromCookie('token')
  //   let header = {
  //     headers: {
  //       ...(token && {
  //         Authorization: `Bearer ${token}`,
  //       }),
  //     },
  //   }
  //   let es = new window.EventSourcePolyfill(
  //     `${process.env.REACT_APP_BASE_URL}clientApis/events/v2?messageId=${message_id}`,
  //     header,
  //   )
  //   es.addEventListener('on_update', (e) => {
  //     const { messageId } = JSON.parse(e?.data)
  //     getPartialCancelOrderDetails(messageId)
  //     onUpdateOrder()
  //   })

  //   const timer = setTimeout(() => {
  //     es.close()
  //     if (cancelPartialEventSourceResponseRef.current?.length <= 0) {
  //       onClose()
  //       dispatchToast(
  //         'The seller is not reachable at the moment. Please try again later. Thank you for your patience.',
  //         toast_types.error,
  //       )
  //       setLoading(false)
  //     }
  //   }, SSE_TIMEOUT)

  //   eventTimeOutRef.current = [
  //     ...eventTimeOutRef.current,
  //     {
  //       eventSource: es,
  //       timer,
  //     },
  //   ]
  // }
  async function fetchCancelPartialOrderDataThroughEvents(message_ids) {
    const token = getValueFromCookie('token')
    let header = {
      headers: {
        ...(token && {
          Authorization: `Bearer ${token}`,
        }),
      },
    }

    setCorrect(false)

    const promises = message_ids.map((message_id) => {
      return new Promise((resolve, reject) => {
        let es = new window.EventSourcePolyfill(
          `${process.env.REACT_APP_BASE_URL}clientApis/events/v2?messageId=${message_id}`,
          header,
        )

        es.addEventListener('on_update', async (e) => {
          try {
            const { messageId } = JSON.parse(e?.data)
            await getPartialCancelOrderDetails(messageId)
            onUpdateOrder()
            es.close()
            resolve() // Resolve the promise when the event is successfully handled
          } catch (error) {
            es.close()
            reject(error) // Reject the promise if there's an error
          }
        })

        es.addEventListener('error', (e) => {
          if (e.readyState === EventSource.CLOSED) {
            es.close()
            dispatchToast(`Connection failed for message ID: ${message_id}. Please try again later.`, toast_types.error)
            reject(new Error(`Connection failed for message ID: ${message_id}`)) // Reject the promise on error
          }
        })

        const timer = setTimeout(() => {
          es.close()
          if (cancelPartialEventSourceResponseRef.current?.length <= 0) {
            onClose()
            dispatchToast('We are facing some technical issue. Please try again', toast_types.warning)
            setLoading(false)
          }
        }, SSE_TIMEOUT)

        eventTimeOutRef.current = [
          ...eventTimeOutRef.current,
          {
            eventSource: es,
            timer,
          },
        ]
      })
    })

    return Promise.all(promises) // Return a promise that resolves when all event promises resolve
  }

  async function handlePartialOrderCancel() {
    const allCheckPassed = [checkReason(), checkIsOrderSelected()].every(Boolean)
    if (!allCheckPassed) return

    cancelPartialEventSourceResponseRef.current = []
    setLoading(true)
    try {
      const payloadDataPromises = selectedIds.map(async (item) => {
        const provider_id = item?.provider_details?.id
        if (Object.keys(selectedImages).length === 0) {
          setInlineError((error) => ({
            ...error,
            image_error: 'Please choose at least one image',
          }))
          return null
        }

        // Prepare imageFiles array from selectedImages
        const imageFiles = Object.values(selectedImages)

        // Fetch signed URLs for the images
        const imageUrls = await Promise.all(
          imageFiles.map(async (fileData) => {
            const url = `/clientApis/v2/getSignUrlForUpload/${order_id}`
            const file_type = fileData?.type?.split('/')[1]

            // Create a FormData object
            const formData = new FormData()
            formData.append('file', fileData)
            formData.append('fileType', file_type)

            try {
              const res = await postCheckoutCall(url, formData)
              return res.publicUrl
            } catch (error) {
              dispatch({
                type: toast_actions.ADD_TOAST,
                payload: {
                  id: Math.floor(Math.random() * 100),
                  type: toast_types.error,
                  message: error?.response?.data?.error?.message,
                },
              })
              return null // Handle error case
            }
          }),
        )

        // Verify if all URLs were fetched successfully
        if (imageUrls.some((url) => !url)) {
          // Handle case where some URLs were not fetched successfully
          return null
        }

        const tags = {
          update_type: 'return',
          reason_code: selectedCancelReasonId?.key,
          ttl_approval: item?.['@ondc/org/return_window'] || '',
          ttl_reverseqc: 'P3D',
          image: imageUrls.join(','),
        }

        const itemsPayload = {
          id: item?.id,
          quantity: {
            count: item.quantity.count,
          },
          tags, // Assign the 'tags' object directly
        }

        return {
          context: {
            bpp_id,
            bpp_uri,
            transaction_id,
          },
          message: {
            update_target: 'item',
            order: {
              id: order_id,
              state: order_status,
              provider: {
                id: provider_id,
              },
              items: [itemsPayload], // Each payload contains only one item
              payment_origin_source: 'razorpay',
              payment_return_destination: 'origin',
            },
          },
        }
      })

      const payloadData = await Promise.all(payloadDataPromises)

      // Filter out any null payloads
      const filteredPayloadData = payloadData.filter((payload) => payload !== null)
      if (correct == true) {
        for (const payload of filteredPayloadData) {
          try {
            const data1 = await new Promise((resolve) => {
              setTimeout(() => {
                resolve(cancellablePromise(postCheckoutCall('clientApis/v2/update', [payload])))
              }, 0)
            })

            // Check if any error occurred
            if (data1.some((txn) => txn.error)) {
              const errorMessage = data1?.[0]?.error
              dispatchToast(errorMessage, toast_types.warning)
              continue // Skip to the next payload
            }

            // Success case
            const messageIds = data1?.map((txn) => txn?.context?.message_id)
            await fetchCancelPartialOrderDataThroughEvents(messageIds) // Call another API here
          } catch (error) {
            setLoading(false)
            dispatchToast(error?.message || 'An error occurred while processing the request', toast_types.error)
          }
        }
      }
    } catch (error) {
      setErrConfirm(true)
      setLoading(false)
      dispatchToast(error?.message || 'An error occurred while processing the request', toast_types.error)
      return error
    }
  }

  // on Update api
  async function getPartialCancelOrderDetails(message_id) {
    try {
      const data = await cancellablePromise(getSelectCall(`/clientApis/v2/on_update?messageId=${message_id}`))
      cancelPartialEventSourceResponseRef.current = [...cancelPartialEventSourceResponseRef.current, data]
      setLoading(false)
      if (data?.message) {
        onSuccess()
        setCorrect(true)
        dispatchToast('Order returned successfully!', toast_types.success)
        return true
      } else {
        // dispatchToast('Something went wrong!, product status cannot be updated', toast_types.error)
      }
    } catch (err) {
      setLoading(false)
      dispatchToast(err?.message, toast_types.error)
      eventTimeOutRef.current.forEach(({ eventSource, timer }) => {
        eventSource.close()
        clearTimeout(timer)
      })
    }
  }

  // use this function to check if any order is selected
  function checkIsOrderSelected() {
    if (selectedIds?.length <= 0) {
      setInlineError((error) => ({
        ...error,
        selected_id_error: 'Please select a product to return',
      }))
      return false
    }

    return true
  }

  // use this function to check if any reason is selected
  function checkReason() {
    if (Object.keys(selectedCancelReasonId)?.length <= 0) {
      setInlineError((error) => ({
        ...error,
        reason_error: 'Please Select Reason',
      }))
      return false
    }

    return true
  }

  // use this function to check if the provider is already selected
  function isProductSelected(id) {
    return selectedIds.filter(({ id: provider_id }) => provider_id === id)?.length > 0
  }

  // use this function to add attribute in filter list
  function addProductToCancel(attribute, qty) {
    let latestAttribute = JSON.parse(JSON.stringify(Object.assign({}, attribute)))
    latestAttribute.quantity.count = qty
    setSelectedIds([...selectedIds, latestAttribute])
  }

  // use this function to remove the selected attribute from filter
  function removeProductToCancel(attribute) {
    setSelectedIds(selectedIds.filter(({ id }) => id !== attribute.id))
  }

  // use this function to update quantity of the selected product
  function updateQtyForSelectedProduct(pId, qty) {
    let data = JSON.parse(JSON.stringify(Object.assign([], selectedIds)))
    data = data.map((item) => {
      if (item.id === pId) {
        item.quantity.count = qty
      }

      return item
    })
    setSelectedIds(data)
  }

  useEffect(() => {
    return () => {
      eventTimeOutRef.current.forEach(({ eventSource, timer }) => {
        eventSource.close()
        clearTimeout(timer)
      })
    }
  }, [])

  useEffect(() => {
    if (selectedIds?.length > 0) {
      const findNonReturnableItem = selectedIds.find((p) => !p?.['@ondc/org/returnable'])
      if (findNonReturnableItem) {
        const data = RETURN_REASONS.filter((r) => r.isApplicableForNonReturnable)
        setReasons(data)
      } else {
        setReasons(RETURN_REASONS)
      }
    }
  }, [selectedIds])

  useEffect(() => {
    if (quantity) {
      setOrderQty(JSON.parse(JSON.stringify(Object.assign(quantity))))
    }
  }, [quantity])

  const onUpdateQty = (qty, idx, pId) => {
    let qtyData = Object.assign([], orderQty)
    qtyData[idx] = qty
    setOrderQty(qtyData)
    updateQtyForSelectedProduct(pId, qty)
  }

  // const refundHandle = (e) => {
  //   e.stopPropagation()
  //   setIsChecked(!isChecked)

  //   if (Object.keys(selectedImages).length === 0) {
  //     setErrConfirm(true)

  //     setInlineError((error) => ({
  //       ...error,
  //       image_error: 'Please choose at least one image',
  //     }))
  //     setIsChecked(false)

  //     return
  //   } else if (Object.keys(selectedImages).length > 0) {
  //     setErrConfirm(false)
  //     setInlineError('')
  //   } else {
  //     setErrConfirm(false)
  //     setInlineError('')
  //   }
  // }
  const handleImageValidation = () => {
    let allImagesSelected = true
    const newInlineError = { ...inlineError }

    selectedIds.forEach((product) => {
      if (!selectedImages[product.id]) {
        allImagesSelected = false
        newInlineError[product.id] = 'Please choose at least one image for the selected product'
      } else {
        delete newInlineError[product.id] // Clear error if image is selected
      }
    })

    if (!allImagesSelected) {
      setErrConfirm(true)
      setInlineError(newInlineError)
      // setIsChecked(false) // Disable button
      return false // Optional: If you need to halt further logic
    } else {
      setErrConfirm(false)
      setInlineError('') // Clear errors
      // setIsChecked(true) // Enable button
      return true
    }
  }

  const refundHandle = (e) => {
    e.stopPropagation()
    setIsChecked(!isChecked)

    // let allImagesSelected = true
    // const newInlineError = { ...inlineError }

    // selectedIds.forEach((product) => {
    //   if (!selectedImages[product.id]) {
    //     allImagesSelected = false
    //     newInlineError[product.id] = 'Please choose at least one image for the selected product'
    //   } else {
    //     delete newInlineError[product.id]
    //   }
    // })

    // if (!allImagesSelected) {
    //   setErrConfirm(true)
    //   setInlineError(newInlineError)
    //   setIsChecked(false)
    //   return
    // } else {
    //   setErrConfirm(false)
    //   setInlineError('')
    // }
  }

  const handleChange = (event) => {
    const reasonValue = event.target.value
    const type = reasons.find(({ value }) => value.toLowerCase() === reasonValue.toLowerCase())
    setSelectedCancelReasonId(type)
    setInlineError((error) => ({
      ...error,
      reason_error: '',
    }))
  }

  const truncateName = (name, wordLimit) => {
    const words = name.split(' ')
    return words.length > wordLimit ? words.slice(0, wordLimit).join(' ') + '...' : name
  }

  return (
    <div className={styles.overlay}>
      <ReturnOrderModalWrapper className={styles.popup_card}>
        <MainWrapper>
          <ProductContainer>
            {areProductsToBeReturned() && (
              <div className="px-1 py-2">
                {partailsReturnProductList?.map((product, idx) => {
                  if (!product['@ondc/org/returnable']) return null

                  if (quantity[idx] < 1) return
                  return (
                    <ReturnWrapper key={idx}>
                      <Wrapper>
                        <ProductImageWrapper>
                          <img
                            src={product?.descriptor?.symbol ? product?.descriptor?.symbol : ProductImage}
                            alt=""
                            onError={(e) => {
                              e.target.src =
                                'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                            }}
                          />
                        </ProductImageWrapper>
                        <ProductDetailWrapper>
                          <ProductName>{product?.name}</ProductName>
                          <DetailContainer>
                            <QuantityWrapper>
                              QTY: {quantity[idx] ?? '0'} X ₹{' '}
                              {Number(product?.price?.value)?.toFixed(2) || 'Price Not Available'}
                            </QuantityWrapper>
                            {Object.keys(product?.customizations || {}).map((key, idx) => {
                              const isLastItem = idx === Object.keys(product.customizations || {})?.length - 1
                              return (
                                <Grid container key={key}>
                                  <Typography variant="subtitle1" color="#686868">
                                    {product.customizations[key].title || 'Customization Title'} (₹
                                    {product.customizations[key].price?.value || '0'}) {isLastItem ? '' : '+'}
                                  </Typography>
                                </Grid>
                              )
                            })}

                            <ProductImagesWrapper>
                              {isProductSelected(product?.id) && (
                                <>
                                  <Input
                                    label_name="Upload Images *"
                                    type="file"
                                    id={`images-${product.id}`}
                                    accept="image/png,image/jpg"
                                    onChange={(event) => {
                                      const file = event.target.files[0] // Get the first selected file

                                      if (file) {
                                        setInlineError((error) => ({ ...error, [product.id]: '' }))
                                        if (file.size / 1024 > 2048) {
                                          dispatchToast('File size cannot exceed more than 2MB', toast_types.error)
                                        } else {
                                          setSelectedImages({
                                            ...selectedImages,
                                            [product.id]: file,
                                          })
                                        }
                                      }
                                    }}
                                    has_error={inlineError[product.id]}
                                  />
                                  <SupportedImageFormats>
                                    Supported Formats: jpg, jpeg, png. You can upload image up to 5 mb.
                                  </SupportedImageFormats>
                                  {inlineError[product.id] && <ErrorMessage>{inlineError[product.id]}</ErrorMessage>}
                                  {selectedImages[product?.id] && (
                                    <p style={{ fontSize: 12, margin: 0 }}>
                                      {truncateName(selectedImages[product?.id]?.name, 3)}
                                    </p>
                                  )}
                                </>
                              )}
                            </ProductImagesWrapper>
                          </DetailContainer>
                        </ProductDetailWrapper>
                      </Wrapper>

                      <CountWrapper>
                        <OptionWrapper>
                          <ProductPrice>₹{Number(product?.price?.value)?.toFixed(2)}</ProductPrice>
                          <Checkbox
                            style={{
                              padding: 0,
                            }}
                            id={product?.id}
                            checked={isProductSelected(product?.id)}
                            disabled={loading}
                            boxBasis="8%"
                            nameBasis="92%"
                            onClick={() => {
                              setInlineError((error) => ({
                                ...error,
                                selected_id_error: '',
                              }))
                              if (isProductSelected(product?.id)) {
                                removeProductToCancel(product)
                                return
                              }

                              addProductToCancel(product, quantity[idx])
                            }}
                          />
                        </OptionWrapper>

                        <div>
                          {isProductSelected(product?.id) && (
                            <div>
                              <QuantityCountWrapper>
                                <MinusWrapper
                                  className={`${
                                    orderQty[idx] > 1 ? productCartStyles.subtract_svg_wrapper : ''
                                  } d-flex align-items-center justify-content-center`}
                                  onClick={() => {
                                    if (!loading) {
                                      if (orderQty[idx] > 1) {
                                        onUpdateQty(orderQty[idx] - 1, idx, product?.id)
                                      }
                                    }
                                  }}
                                >
                                  {orderQty[idx] > 1 && (
                                    <Subtract width="13" classes={productCartStyles.subtract_svg_color} />
                                  )}
                                </MinusWrapper>
                                <ProductQuantityCount className={productCartStyles.quantity_count}>
                                  {orderQty[idx] ?? '0'}
                                </ProductQuantityCount>
                                <PlusWrapper
                                  className={`${
                                    orderQty[idx]?.count < quantity[idx]?.count ? productCartStyles.add_svg_wrapper : ''
                                  } d-flex align-items-center justify-content-center`}
                                  onClick={() => {
                                    if (!loading) {
                                      if (orderQty[idx] < quantity[idx]) {
                                        onUpdateQty(orderQty[idx] + 1, idx, product?.id)
                                      }
                                    }
                                  }}
                                >
                                  {orderQty[idx] < quantity[idx] && (
                                    <Add width="13" height="13" classes={productCartStyles.add_svg_color} />
                                  )}
                                </PlusWrapper>
                              </QuantityCountWrapper>
                              {/* {productCounts[idx] < orderQty[idx]?.count && (
                                    <ErrorMessage>You cannot return selected quantity</ErrorMessage>
                                  )} */}
                            </div>
                          )}
                        </div>
                        {/* {isProductSelected(product?.id) && orderQty[idx]?.count > productCounts[idx] && (
                          <ErrorMessage>You cannot return selected quantity</ErrorMessage>
                        )} */}
                      </CountWrapper>
                    </ReturnWrapper>
                  )
                })}
              </div>
            )}
          </ProductContainer>
          {inlineError.selected_qty_error && <ErrorMessage>{inlineError.selected_qty_error}</ErrorMessage>}

          {selectedIds && selectedIds?.length > 0 && (
            <ReturnReasonWrapper className="px-2">
              <ReasonTitle>Select reason*</ReasonTitle>

              <FormControl fullWidth>
                <Select
                  value={selectedCancelReasonId?.value || ''}
                  onChange={handleChange}
                  IconComponent={() => <ExpandMoreIcon sx={{ color: '#979797' }} />}
                  className="reason-wrapper"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        height: '200px',
                        overflowY: 'scroll',
                        // fontSize: '14px',
                        width: '100% !important',
                      },
                    },
                  }}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return (
                        <Wrapper>
                          <Typography variant="caption1">{'Select reason for return'}</Typography>
                        </Wrapper>
                      )
                    } else {
                      return <Wrapper>{selected}</Wrapper>
                    }
                  }}
                >
                  {reasons?.map(({ value }) => (
                    <MenuItem
                      key={value}
                      value={value}
                      sx={{
                        fontSize: '14px',
                        whiteSpace: 'normal',
                      }}
                    >
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {inlineError.reason_error && <ErrorMessage>{inlineError.reason_error}</ErrorMessage>}
            </ReturnReasonWrapper>
          )}

          <RefundWrapper>
            {selectedIds && selectedIds?.length > 0 && (
              <CheckboxWrapper>
                <Checkbox className="refund-checkbox" checked={isChecked} onClick={(e) => refundHandle(e)} />
                <RefundText>Get refund in original source</RefundText>
              </CheckboxWrapper>
            )}
          </RefundWrapper>
        </MainWrapper>
        <ButtonWrapper>
          <Button
            sx={{ paddingLeft: 4, paddingRight: 4 }}
            disabled={loading}
            variant="outlined"
            onClick={() => {
              onClose()
            }}
          >
            Cancel
          </Button>
          <Button
            sx={{ paddingLeft: 4, paddingRight: 4 }}
            isloading={loading ? 1 : 0}
            disabled={disableButton || loading || errConfirm}
            variant="contained"
            onClick={() => {
              handlePartialOrderCancel()
            }}
          >
            {loading ? <Loading /> : 'Confirm'}
          </Button>
        </ButtonWrapper>
      </ReturnOrderModalWrapper>
    </div>
  )
}
