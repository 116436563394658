import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const WishlistWrapper = styled.div`
  width: 100%;
  .empty-state {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 767px) {
    margin: 16px;
    width: unset;
    display: none;
  }
`
export const WishlistTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
  @media (max-width: 767px) {
    font-size: 20px;
  }
`
export const NoDataWrapper = styled.div``
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 25px;
  border-bottom: 1px solid ${theme.SECONDARYBORDERCOLOR};
  padding: 10px 0;
`
export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${theme.TERTIARYBACKGROUNDCOLOR};
  border-radius: 8px 0px 0px 0px;
  padding: 12px 24px;
`
export const ProductDetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
export const OrderLeftDetails = styled.div``
export const OrderRightDetails = styled.div``
export const OrderNo = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
  max-width: 500px;
`
export const SubOrderDetails = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: ${theme.SECONDARYGREYCOLOR};
  > span {
    color: #bebcbd;
  }
`
export const ProductDetailsSubWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const ProductDetailsLeft = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  max-width: 600px;
  width: 100%;
  cursor: pointer;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  border: 1px solid ${theme.SECONDARYBORDERCOLOR};
  border-radius: 8px;
  padding: 12px;
  background: ${theme.TERTIARYBACKGROUNDCOLOR};
  .product-image {
    width: 100px;
    height: 100px;
    object-fit: contain;
    position: relative;
  }
  @media (max-width: 767px) {
    gap: 80px;
  }
`
export const ProductDetailsRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  button {
    text-transform: none;
  }
  svg {
    cursor: pointer;
  }
  @media (max-width: 767px) {
    width: 100%;
    button {
      width: 100%;
    }
  }
`
export const CartButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  button {
    height: 36px;
    box-shadow: none;
    width: 132px;
  }
  @media (max-width: 767px) {
    flex-direction: unset;
    width: 100%;
  }
`
