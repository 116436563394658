import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const RevokeModalWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const RevokeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

export const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
`
export const ContentWrapper = styled.div`
  font-weight: 400;
  font-size: 20px;
  color: ${theme.PRIMARYCOLOR};
  text-align: center;
  @media (max-width: 767px) {
    font-size: 16px;
  }
`
export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  padding-top: 16px;
  button {
    width: 170px;
    height: 40px;
    text-transform: none;
  }
  @media (max-width: 450px) {
    padding-top: 10px;
    gap: 5px;
  }
`
