import { makeStyles } from '@mui/styles'
import styled from 'styled-components'
import palette from '../../../utils/Theme/palette'
import { theme } from 'styles/Theme/theme'

const useStyles = makeStyles({
  categoriesRootContainer: {
    background: '#F9F9F9',
  },
  categoriesContainer: {
    '& ul': {
      display: 'flex',
      gap: '16px',
      padding: '16px !important',
      justifyContent: 'center',
      '& li:has(.MuiPaginationItem-ellipsis)': {
        background: 'red !important',
        display: 'none',
      },
    },
  },
  categoryItem: {
    textAlign: 'center',
    cursor: 'pointer',
  },
  categoryItemImageContainer: {
    height: '108px',
    width: '108px',
    borderRadius: '8px',
    background: '#fff',
    display: 'flex',
    margin: 'auto',
    border: '1px solid #E7E7E7',
  },
  categoryImage: {
    height: '102px',
    maxWidth: '102px',
    minWidth: '42px',
    margin: 'auto',
    padding: '10px',
    borderRadius: '8px',
  },
  categoryNameTypo: {
    fontWeight: '600 !important',
    marginTop: '5px !important',
    height: '40px !important',
  },
  selectedCategory: {
    // background: '#EBEBEB !important',
    border: `2px solid ${palette.secondary.main}`,
    '& $categoryImage': {
      height: '98px',
      maxWidth: '98px',
    },
  },
  actionButton: {
    border: '1px solid rgba(0, 0, 0, 0.14) !important',
  },
  pageEllipsisContainer: {},
  previousIconContainer: {
    display: 'flex',
    gap: '29px',
  },

  allNameTypo: {
    fontWeight: '600 !important',
  },
  subCatContainer: {
    display: 'flex',
    gap: '25px',
    paddingBottom: '10px',
  },
})

export const AllOptionWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;

  svg {
    path {
      fill: ${palette.secondary.main};
    }
  }
  .css-vkc4v8-MuiTypography-root {
    color: ${palette.secondary.main};
  }
`
export const AllOptionContainer = styled.div`
  padding: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 0fr));
  justify-content: center;
  gap: 10px;

  @media screen and (max-width: 450px) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 0fr));
  }
`

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const ViewAllText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.PRIMARYCOLOR};
  cursor: pointer;
  text-transform: capitalize;
`
export const IconButton = styled.div`
  padding: 10px;
  border-radius: 50%;
  background-color: ${palette.secondary.main};
  cursor: pointer;
  @media (max-width: 767px) {
    display: none;
  }
`
export const CatelogueIconButton = styled.div`
  border-radius: 50%;
  background-color: ${palette.secondary.main};
  padding: 12px;
  border-radius: 50%;
  background-color: #115469;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
`

export default useStyles
