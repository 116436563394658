import React, { useState, useEffect, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import MuiLink from '@mui/material/Link'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Pagination from '@mui/material/Pagination'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import Typography from '@mui/material/Typography'
import { getAllBrandsRequest } from 'api/brand.api'
import { ToastContext } from 'context/toastContext'
import { categoryList } from 'constants/categories'
import useDebounce from 'hooks/useDebounce'
import useStyles from 'components/orders/style'
import Spinner from 'components/Loader'
import { toast_actions, toast_types } from 'components/shared/toast/utils/toast'
import ProvidersCard from 'views/ProvidersCard'
import { ReactComponent as SearchIcon } from 'assets/images/search.svg'
import {
  ProviderWrapper,
  BreadcrumbWrapper,
  ProvidersListingWrapper,
  HeaderSection,
  MainHeading,
  CardContainer,
  PaginationWrapper,
  SearchWrapper,
  SearchContent,
} from 'styles/ProvidersList'

const Stores = () => {
  const classes = useStyles()
  const dispatch = useContext(ToastContext)
  const [providers, setProviders] = useState([])
  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: 18,
  })
  const [totalCount, setTotalCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [searchData, setSearchData] = useState('')
  const debouncedSearch = useDebounce(searchData, 500)
  const locationData = useLocation()
  const useQuery = () => {
    const { search } = locationData
    return React.useMemo(() => new URLSearchParams(search), [search])
  }
  let query = useQuery()

  useEffect(() => {
    const categoryName = query.get('c')

    if (categoryName) {
      getAllBrands(categoryName)
    }
  }, [locationData, paginationModel])

  const getAllBrands = async (categoryName, searchName) => {
    setIsLoading(true)
    try {
      const findCategory = categoryList.find((item) => item.routeName === categoryName)
      const domain = findCategory?.domain

      let paginationData = Object.assign({}, paginationModel)

      let data

      if (searchName && searchName.length) {
        // Add a check for searchName being defined

        data = await getAllBrandsRequest({ page: 1, pageSize: 18 }, domain, searchName)
      } else {
        data = await getAllBrandsRequest(paginationData, domain, '')
      }

      setProviders(data?.data)
      setTotalCount(data?.count)
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err?.response?.data?.error?.message || err.message,
        },
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getAllBrands(null, debouncedSearch)
  }, [paginationModel, debouncedSearch])

  const handleProviderSearch = () => {
    getAllBrands(null, searchData)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleProviderSearch()
    }
  }

  const handlePaginationChange = (event, page) => {
    event
    let paginationData = Object.assign({}, paginationModel)
    paginationData.page = page
    setPaginationModel(paginationData)
    window.scrollTo({ top: 0, behavior: 'smooth' }) // Scroll to top when pagination changes
  }

  return (
    <ProviderWrapper>
      <BreadcrumbWrapper>
        <Breadcrumbs aria-label="breadcrumb">
          <MuiLink component={Link} underline="hover" color="inherit" to="/">
            <Typography color="#115469">Home</Typography>
          </MuiLink>
          <Typography>Stores</Typography>
        </Breadcrumbs>
      </BreadcrumbWrapper>
      <ProvidersListingWrapper>
        <HeaderSection>
          <MainHeading>Stores</MainHeading>
          <div>
            <SearchWrapper>
              <SearchContent>
                <IconButton
                  type="button"
                  aria-label="search"
                  onClick={(event) => {
                    event.stopPropagation(), handleProviderSearch
                  }}
                >
                  <SearchIcon />
                </IconButton>
                <InputBase
                  fullWidth
                  className={classes.inputBase}
                  placeholder="Search..."
                  inputProps={{ 'aria-label': 'Search...' }}
                  name="search"
                  value={searchData || ''}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => setSearchData(e.target.value)}
                />
              </SearchContent>
            </SearchWrapper>
          </div>
        </HeaderSection>
        <CardContainer>{isLoading ? <Spinner /> : <ProvidersCard providers={providers} />}</CardContainer>
      </ProvidersListingWrapper>

      {providers?.length > 0 && totalCount > paginationModel.pageSize && (
        <PaginationWrapper>
          <Pagination
            className={classes.pagination}
            count={Math.ceil(totalCount / paginationModel.pageSize)}
            shape="rounded"
            color="primary"
            page={paginationModel.page}
            onChange={handlePaginationChange}
          />
        </PaginationWrapper>
      )}
    </ProviderWrapper>
  )
}

export default Stores
