import { useEffect, useState } from 'react'
import PlacePickerMap from '../common/PlacePickerMap/PlacePickerMap'
import { MapWrapper } from '../checkout/style'

const MapPicker = (props) => {
  const { address, setAddress } = props
  let locationString = '30.749573591467335, 76.65257267327847'
  locationString = locationString.split(',')
  const gps = {
    lat: locationString[0],
    lng: locationString[1],
  }

  const [location, setLocation] = useState(null)

  useEffect(() => {
    if (address.lat && address.lng) {
      setLocation({
        lat: address.lat,
        lng: address.lng,
        street: address.street,
        city: address.city,
        state: address.state,
        pincode: address.areaCode,
      })
    }
  }, [])

  useEffect(() => {
    if (location) {
      setAddress((address) => ({
        ...address,
        street: location.street,
        city: location.city,
        state: location.state,
        areaCode: location.pincode,
        lat: location.lat,
        lng: location.lng,
      }))
    }
  }, [location])
  return (
    <MapWrapper>
      <PlacePickerMap location={location || gps} setLocation={setLocation} />
    </MapWrapper>
  )
}

export default MapPicker
