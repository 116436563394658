import * as React from 'react'
const MicIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="25px" fill="none" {...props}>
    <path
      fill="#9F9F9F"
      d="M7.577 14.346c1.058 0 1.963-.377 2.716-1.13.753-.752 1.13-1.658 1.13-2.716V4.346c0-1.057-.377-1.963-1.13-2.716C9.54.877 8.635.5 7.577.5 6.519.5 5.614.877 4.86 1.63c-.753.753-1.13 1.659-1.13 2.716V10.5c0 1.058.377 1.963 1.13 2.716.754.753 1.659 1.13 2.717 1.13Z"
    />
    <path
      fill="#9F9F9F"
      d="M14.271 8.42a.738.738 0 0 0-.54-.228.74.74 0 0 0-.541.229.74.74 0 0 0-.229.54V10.5c0 1.482-.527 2.75-1.58 3.804-1.054 1.054-2.322 1.58-3.804 1.58-1.483 0-2.75-.526-3.804-1.58-1.054-1.053-1.58-2.322-1.58-3.804V8.961a.74.74 0 0 0-.23-.54.739.739 0 0 0-.54-.229.74.74 0 0 0-.541.229.739.739 0 0 0-.228.54V10.5c0 1.77.59 3.311 1.772 4.621 1.182 1.31 2.643 2.061 4.381 2.254v1.586H3.731a.74.74 0 0 0-.541.229.74.74 0 0 0-.229.54.74.74 0 0 0 .229.541.74.74 0 0 0 .54.229h7.693a.74.74 0 0 0 .54-.229.739.739 0 0 0 .23-.54.739.739 0 0 0-.23-.541.739.739 0 0 0-.54-.229H8.346v-1.586c1.739-.193 3.199-.944 4.38-2.254 1.183-1.31 1.774-2.85 1.774-4.621V8.961a.739.739 0 0 0-.229-.54Z"
    />
  </svg>
)
export default MicIcon
